import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Stack, TextField,
  PrimaryButton,
  DialogType, Dialog,
  Link, MessageBar,
  MessageBarType
} from '@fluentui/react';
import { ConfirmationDialog } from '@/components/base/ConfirmationDialog';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import { useAuth } from '@/contexts/auth';

type Props = {
  hidden: boolean;
};

export const ForgotPassword = ({ hidden }: Props) => {
  const { loginData } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const [hiddenModal, setHiddenModal] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: loginData?.email || '',
    },
  });

  const { makeRequest: forgotPassword, error } = useFetch({
    endpoint: '/api/users/forgotPassword',
    method: FetchMethodsEnum.POST,
    skip: true,
    onCompleted: () => {
      setHiddenModal(true);
    }
  });

  const onSubmit = async (values) => {
    await forgotPassword({
      queryParams: {
        email: values.email,
      },
    });
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: t('login.forgotPassword'),
  };

  const handleClose = () => {
    history.push('/');
  };

  return (
    <>
      <Dialog
        hidden={hidden}
        minWidth="400px"
        dialogContentProps={dialogContentProps}
        modalProps={{ isBlocking: true }}
        onDismiss={handleClose}
      >
        {error === 'This email already exists.' && (
          <MessageBar
            className="emailErrorMessage"
            messageBarType={MessageBarType.error}
            isMultiline={false}
          >
            {t('validation.errorEmailAlreadyExists')}
          </MessageBar>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack tokens={{ childrenGap: 4 }}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  type="email"
                  label={t('global.email')}
                />
              }
            />
            <PrimaryButton
              className='marginContainer'
              text={t('global.send')}
              type="submit"
            />
            <Link className='marginContainer' href="/login">{t('login.linkLogin')}</Link>

          </Stack>
        </form>
      </Dialog>
      <ConfirmationDialog
        hidden={!hiddenModal}
        onDismiss={() => {history.push('/');}}
        renderContent={t('login.forgotPasswordText')}
        textButton={t('global.ok')}
      />
    </>
  );
};