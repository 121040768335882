/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from '@/contexts/auth';
import axios from 'axios';
import qs from 'query-string';
import { useState, useEffect, useCallback } from 'react';

export enum FetchMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface BaseObject {
  [key: string]: string | number | boolean | BaseObject;
}

export interface ParamsArg {
  endpoint: string;
  method: FetchMethodsEnum;
  tokenURL?: any;
  queryParams?: BaseObject;
  data?: any;
  skip?: boolean;
  isFormData?: boolean;
  makeRequestOn?: any[];
  responseType?: string;

  onCompleted?: (data: any) => void;
  onError?: (error: string) => void;
  onLoadingStart?: () => void;
  onLoadingFinish?: () => void;
  onSuccess?: () => void;
}

export interface RequestParams {
  queryParams?: any;
  data?: any;
  formData?: any;
  endpoint?: string;
  headers?: object;
}

export interface FetchHookResult {
  data: any;
  error: string | null | undefined;
  loading: boolean;
  makeRequest: (requestParams?: any) => any;
  clearError: () => any;
}

export const useFetch = (params: ParamsArg) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  const { method, endpoint, tokenURL } = params;

  const makeRequest = useCallback(async (requestParams?: RequestParams) => {
    try {
      if (loading) {return;}
      setLoading(true);

      if (params.onLoadingStart) {
        params.onLoadingStart();
      }

      const queryParams = {
        ...params?.queryParams,
        ...requestParams?.queryParams,
      };

      Object.keys(queryParams).forEach(key => {
        if (queryParams[key] === null || queryParams[key] === undefined) {
          delete queryParams[key];
        }
      });

      let queryData: any = null;

      let headers = {};

      if (token || tokenURL) {
        headers['Authorization'] = `Bearer ${token || tokenURL}`;
      }

      if (requestParams?.headers) {
        headers = {
          ...headers,
          ...requestParams?.headers,
        };
      }

      if (requestParams?.formData) {
        headers['content-type'] = 'multipart/form-data';
      } else if (Array.isArray(requestParams?.data)) {
        queryData = requestParams?.data;
      } else if (Array.isArray(params?.data)) {
        queryData = [ ...params?.data ];
      } else {
        queryData = { ...params?.data, ...requestParams?.data };
      }

      const response = await axios({
        method,
        headers,
        url:
          (requestParams?.endpoint || endpoint) +
            (Object.values(queryParams).length > 0 ? `?${qs.stringify(queryParams)}` : ''),
        data: queryData || requestParams?.formData,
      } as any);

      if (response?.data?.error) {
        setError(response?.data?.error);
        setLoading(false);
        return;
      }

      setData(response.data);

      if (params.onCompleted) {
        params.onCompleted(response.data);
      }

      if (error) {
        setError(null);
      }

      setLoading(false);

      if (params.onLoadingFinish) {
        params.onLoadingFinish();
      }

      // eslint-disable-next-line consistent-return
      return response.data;
    } catch (err) {
      console.error('Fetch error:', err?.response?.data);

      if (err?.response?.data?.error) {
        setError(err?.response?.data?.error);
      } else {
        setError(err);
      }

      if (params.onError) {
        params.onError(err);
      }

      setLoading(false);

      if (params.onLoadingFinish) {
        params.onLoadingFinish();
      }
    }
  }, [endpoint, error, method, params, params.onCompleted]);

  useEffect(() => {
    if (
      params.method === FetchMethodsEnum.GET &&
      !params.skip &&
      !(params.endpoint.includes('undefined') || params.endpoint.includes('null'))
    ) {
      makeRequest();
    }
  }, [params.skip, params.endpoint]);

  const makeRequestOn = params.makeRequestOn || [];

  useEffect(() => {
    if (
      !params.skip &&
      makeRequestOn.length > 0 &&
      !(params.endpoint.includes('undefined') || params.endpoint.includes('null'))
    ) {
      makeRequest();
    }
  }, [makeRequestOn, params.endpoint, params.skip, params.queryParams]);

  return {
    loading,
    error,
    data,
    makeRequest,
    clearError: () => setError(null)
  } as FetchHookResult;
};