/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useAuth } from '@/contexts/auth';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'office-ui-fabric-react-pagination';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { FilterBar } from '@/components/base/FilterBar';
import { ContactView } from '@/components/base/ContactView';
import { onFormatDate } from '@/utils/calendar';
import {
  Stack, ShimmeredDetailsList,
  SelectionMode, TooltipHost,
  DetailsListLayoutMode, IDetailsHeaderProps,
  PrimaryButton, OverflowSet,
  CommandBarButton
} from '@fluentui/react';

import { SpinnerDefault } from '@/components/base/SpinnerDefault';

import useDebaunce from './useDebaunce';

import './index.scss';

const onRenderItemStyles = {
  root: { padding: '10px' },
};
const onRenderOverflowButtonStyles = {
  root: { padding: '10px', background: 'transparent' },
  menuIcon: { fontSize: '16px' },
};


export default function OverviewDamageDSP(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { userData } = useAuth();

  const [filtering, setFiltering] = useState(false);
  const [сategoryType, setCategoryType] = useState();
  const [skipItem, setSkipItem] = useState(0);
  const [itemId, setItemId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2 * 1000);
  }, []);

  const [orderBy, setOrderBy] = useState('');
  const [isAsc, setIsAsc] = useState(true);

  const [f, setF] = useState([]);
  const { control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      fields: [],
    },
  });
  const { fields } = useFieldArray({ control, name: 'fields' });

  const [filters, setFilters] = useState({
    search: '',
    from: null,
    to: null,
    status: null,
    category: null,
  });

  const debouncedFilters = useDebaunce(filters);

  const { data: dspList, makeRequest: refetch, loading } = useFetch({
    endpoint: `/api/amsDamages/users/${userData?.nameid}`,
    queryParams: {
      searchTerm: debouncedFilters.search,
      skip: skipItem,
      dateFrom: debouncedFilters.from && moment(debouncedFilters.from).format('YYYY-MM-DD'),
      dateTo: debouncedFilters.to && moment(debouncedFilters.to).format('YYYY-MM-DD'),
      status: debouncedFilters.status,
      category: debouncedFilters.category,
      take: 10,
    },
    method: FetchMethodsEnum.GET,
    onCompleted: nextFields => {
      setF(nextFields.result);
      reset({
        fields: nextFields.result,
      });
    },
  });

  const [refetcher, setRefetcher] = useState(0);

  useEffect(() => {
    refetch();
  }, [skipItem, currentPage, refetcher]);

  useEffect(() => {
    setSkipItem(0);
    setCurrentPage(1);
    setRefetcher(Date.now());
  }, [debouncedFilters.search, debouncedFilters.to, debouncedFilters.from, debouncedFilters.status, debouncedFilters.category]);

  const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    return (
      <CommandBarButton
        role="menuitem"
        title="Actions"
        styles={onRenderOverflowButtonStyles}
        menuIconProps={{ iconName: 'CollapseMenu' }}
        menuProps={{ items: overflowItems! }}
      />
    );
  };

  const onRenderItem = (item: any): JSX.Element => {
    return (
      <CommandBarButton
        role="menuitem"
        aria-label={item.name}
        styles={onRenderItemStyles}
        iconProps={{ iconName: item.icon }}
        onClick={item.onClick}
      />
    );
  };


  const getColumns = (summ = true) => [
    {
      key: 'contractNumber',
      isSorted: orderBy === 'contractNumber',
      isSortedDescending: !isAsc,
      name: t('overviewDamage.contractNumber'),
      ariaLabel: t(['overviewDamage.tooltipСontractNumber', '']),
      fieldName: 'contractNumber',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item.contractNumber}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'category',
      name: t('overviewDamage.category'),
      ariaLabel: t(['overviewDamage.tooltipCategory​', '']),
      fieldName: 'сategory',
      isSorted: orderBy === 'category',
      isSortedDescending: !isAsc,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item.category}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'damageClassification',
      name: t('overviewDamage.damageClassification'),
      ariaLabel: t(['overviewDamage.tooltipDamageClassification​', '']),
      fieldName: 'damageClassification',
      isSorted: orderBy === 'damageClassification',
      isSortedDescending: !isAsc,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item.damageClassification}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'risk',
      name: t('overviewDamage.risk'),
      ariaLabel: t(['overviewDamage.tooltipRisk', '']),
      fieldName: 'risk',
      isSorted: orderBy === 'risk',
      isSortedDescending: !isAsc,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item.risk}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'damageDate',
      name: t('overviewDamage.damageDate'),
      ariaLabel: t(['overviewDamage.tooltipDamageDate', '']),
      fieldName: 'damageDate',
      isSorted: orderBy === 'damageDate',
      isSortedDescending: !isAsc,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{onFormatDate(item.damageDate)}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'damageNumber',
      name: t('overviewDamage.damageNumber'),
      ariaLabel: t(['overviewDamage.tooltipDamageNumber', '']),
      fieldName: 'damageNumber',
      isSorted: orderBy === 'damageNumber',
      isSortedDescending: !isAsc,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item.contractNumber}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'status',
      name: t('overviewDamage.status'),
      ariaLabel: t(['overviewDamage.tooltipStatus', '']),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item, index) => {
        return(
          <div>
            {t(`global.${item.status}`)}
          </div>
        );

      },
      isPadded: true,
    },
    {
      key: 'actions',
      name: t('overviewDamage.actions'),
      ariaLabel: t(['overviewDamage.tooltipActions', '']),
      fieldName: 'actions',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        let overflowItems = [];

        overflowItems = [...overflowItems, ...[
          {
            key: 'contactDetails',
            name: t('overviewDamage.contactDetails'),
            onClick: () => {
              setCategoryType(item.category);
              setItemId(item.damageId);
            },
          },
        ] as never[]];

        return (
          <OverflowSet
            aria-label="menu-bar"
            role="menubar"
            vertical
            overflowItems={overflowItems}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        );
      },
      isPadded: true,
    }
  ];

  return (
    <Stack className='tableContainer'>
      <PrimaryButton
        onClick={() => history.push('/overview')}
        className='navigationAdminButtonStyles'
        text={t('overviewDamage.contracts')}
      />
      <FilterBar
        filters={filters}
        setFilters={setFilters}
        onFilterChange={setFilters}
      />
      { load || loading || filtering ? (
        <SpinnerDefault />
      ) : (
        <>
          {fields.length === 0 ? (
            <div className="emptyTable">{t('overviewDamage.emptyTable')}</div>
          ) : (
            <>
              <form>
                <ShimmeredDetailsList
                  viewport={{
                    width: 1150,
                    height: 500,
                  }}
                  styles={{
                    root: {
                      minWidth: '100vw',
                      alignItems: 'center'
                    },
                  }}
                  items={fields}
                  columns={getColumns()}
                  selectionMode={SelectionMode.none}
                  setKey="id"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderDetailsHeader={(props, defaultHeader) => defaultHeader!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                      return (
                        <TooltipHost
                          className="header-tooltip"
                          styles={{
                            root: {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            },
                          }}
                          {...tooltipHostProps}
                        />
                      );
                    },
                  } as IDetailsHeaderProps)}
                />
              </form>
              <div style={{ margin: '20px auto' }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(dspList.count/10)}
                  onChange={(page) => {
                    setCurrentPage(page);
                    setSkipItem(page * 10);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {itemId &&
        <ContactView
          defaultSelectedIndex={0}
          contactID={itemId}
          categoryType={сategoryType === 'KFZW'}
          userID={userData?.nameid}
          hidden={false}
          onDismiss={() => setItemId(undefined)}
        />}
    </Stack>
  );
}
