import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOverflow } from 'use-overflow';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import {
  Stack, Text, Icon,
  TooltipHost, Spinner, SearchBox
} from '@fluentui/react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { useAuth } from '@/contexts/auth';

import { UploadFileDialog } from '@/components/base/UploadFileDialog';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import './index.scss';

const LabelTooltip = ({ label }) => {
  const ref = React.useRef(null);
  const { refXOverflowing } = useOverflow(ref);

  if (!refXOverflowing) {
    return (
      <span ref={ref} className="pdfName">{label}</span>
    );
  }

  return (
    <TooltipHost
      key={label}
      id={label}
      content={label}
      calloutProps={{ gapSpace: 0 }}
      className="tooltip"
    >
      <span ref={ref} className="pdfName">{label}</span>
    </TooltipHost>
  );
};

export const PdfList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userData, token } = useAuth();

  const [isHiddenUploadDialog, setHiddenUploadDialog] = useState(true);

  const [searchTerm, setSearchterm] = useState();

  const nameId = location.search.replace('?', '');

  const notifySucces = () => toast.success(t('toasts.deleteDocumentsSuccess'));
  const notifyError = () => toast.error(t('toasts.deleteDocumentsError'));

  const { data, makeRequest: refetch, loading } = useFetch({
    endpoint:  userData?.role === 'DSP' ? `/api/dspusers/${userData?.nameid}/documents` : `/api/dspusers/${nameId}/documents`,
    method: FetchMethodsEnum.GET,
    onCompleted: nextData => {
      setNextData(nextData)
    }
  });

  const [nextData, setNextData] = useState(data)

  useEffect(() => {
    refetch({
      queryParams: {
        searchTerm,
      }
    });
  }, [searchTerm]);

  const { makeRequest: deleteFile, loading: deleteLoading } = useFetch({
    endpoint: `/api/:filename`,
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      notifySucces();
      refetch();
    },
    onError: () => {
      notifyError();
    }
  });


  const downloadPDF = (name) => async () => {
    const link = document.createElement('a');

    link.setAttribute('download', `${name.split('/').pop()}`);
    link.setAttribute('target', '_blank');

    const response = await axios.get(`/api/${name}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const blob = new Blob([response.data]);
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;

    document.body.appendChild(link);
    link.click();
    link.remove();
  };


  return (
    <Stack className="pdfList">
      <div className="listHeader">
        <Text className="textStylePdfList" style={{ marginRight: 5 }}>{t('overview.pdfListTitle')}</Text>
        <SearchBox
          iconProps={{ iconName: 'Search' }}
          className='searchInputFilterBar'
          value={searchTerm}
          onChange={(e: any) => setSearchterm(e?.target?.value)}
        />
      </div>
      {userData?.role === 'Advisor' && (
        <PrimaryCallToActionButton
          text={t('overview.uploadButton')}
          onClick={() => setHiddenUploadDialog(false)}
          style={{ width: 180, marginLeft: 'auto' }}
        />
      )}
      {deleteLoading ? <Spinner /> : nextData?.map((item) => {
        return(
          <div className="pdfItem" key={item}>
            <div className="pdfItemContent">
              <Icon iconName="TextDocument" style={{ color: '#00a8e1', fontSize: 24 }} />
              <LabelTooltip label={item.split('/').pop()} />
            </div>
            <div>
              <Icon iconName="download" className="pdfDownload" style={{ fontSize: 22, fontWeight: 500 }} onClick={downloadPDF(item)} />
              {(userData?.role === 'Advisor' && !(item.split('/').pop() === 'Vertragsübersicht.pdf')) && !(item.split('/').shift () === 'amsDocuments') && (
                <Icon
                  iconName="delete"
                  className="pdfDownload"
                  style={{ fontSize: 22, fontWeight: 500 }}
                  onClick={() => {
                    deleteFile({
                      endpoint: `/api/${item}`
                    });
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
      {!data?.length && !loading && (
        <Text>{t('overview.pdfListEmpty')}</Text>
      )}
      <UploadFileDialog
        userId={nameId}
        uploadFile
        hidden={isHiddenUploadDialog}
        onDismiss={() => {
          refetch();
          setHiddenUploadDialog(true);
        }}
      />
    </Stack>
  );
};
