import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, IStackProps } from '@fluentui/react';
import { useWindowWidthContext } from '@/hooks';
import { mainTheme, alternateTheme } from '@/theme/themes';
import { useAuth } from '@/contexts/auth';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';

import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';
import SecondaryCallToActionButton from '@/components/base/CallToActionButton/Secondary';
import landingPageImageLarge from '@/assets/landing-page-1200x461.jpg';
import landingPageImageSmall from '@/assets/landing-page-576x221.jpg';
import { RolesEnum } from '@/components/base/CreateUserDialog';

export default function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const { authorized, userData } = useAuth();
  const windowWidth = useWindowWidthContext();



  const image = windowWidth.isSmall
    ? landingPageImageSmall
    : landingPageImageLarge;

  const fontSize = windowWidth.isLarge
    ? alternateTheme.fonts.xxLargePlus.fontSize
    : windowWidth.isMedium
      ? alternateTheme.fonts.xxLarge.fontSize
      : alternateTheme.fonts.xLarge.fontSize;
  const style = {
    color: alternateTheme.palette.themeDark,
    fontSize,
  };

  const stackProps: IStackProps = {
    style: {
      width: '100%',
      flexGrow: 1,
      margin: `${mainTheme.spacing.m} 0`,
    },
  };
  if (windowWidth.isLarge) {
    stackProps.horizontal = true;
    stackProps.verticalAlign = 'center';
    stackProps.horizontalAlign = 'space-around';
  } else {
    stackProps.verticalAlign = 'center';
    stackProps.horizontalAlign = 'center';
    stackProps.tokens = {
      childrenGap: mainTheme.spacing.l1,
    };
  }

  const roles = {
    [RolesEnum.DSP]: { label: t('global.roles.dsp'), textButton: t('landing.overviewButton'), link: '/overview' },
    [RolesEnum.Advisor]: { label: t('global.roles.advisor'), textButton: t('landing.advisorOverviewButton'), link: '/landing-advisor' },
    [RolesEnum.Administrator]: { label: t('global.roles.administrator'), textButton: t('landing.adminOverviewButton'), link: '/landing-admin' }
  };
  const buttonConfig = userData ? roles[userData.role] : {};

  return (
    <Stack
      style={{
        flexGrow: 1,
        backgroundColor: mainTheme.semanticColors.bodyBackground,
      }}
    >
      <img
        src={image}
        alt="Own your success"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />

      <Stack {...stackProps}>
        {windowWidth.isLarge ? (
          <h1 style={style}>{t('landing.titleOne')}<br />{t('landing.titleTwo')}</h1>
        ) : (
          <h1 style={style}>{t('landing.title')}</h1>
        )}
        <div style={{ maxWidth: 360 }}>
          <p style={{ marginBottom: mainTheme.spacing.m }}>{t('landing.description')}</p>
          {authorized ? (
            <PrimaryCallToActionButton
              text={buttonConfig?.textButton}
              to={buttonConfig?.link}
            />
          ) : (
            <>
              <PrimaryCallToActionButton
                text={t('landing.insureButton')}
                to="/wizard"
                style={{ marginBottom: mainTheme.spacing.s1 }}
              />
              <SecondaryCallToActionButton
                text={t('landing.largeInsureButton')}
                to="/contact"
              />
            </>
          )
        }
        </div>
      </Stack>
    </Stack>
  );
}
