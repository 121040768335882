export const hasErrors = (errors) => {
    return Object.keys(errors).some(key => {
      const value = errors[key]
  
      if (value && typeof value === 'object') {
        return hasErrors(value)
      }
  
      return typeof value !== 'undefined'
    })
  }