import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { OverflowSet } from '@fluentui/react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';

export const ActionSet = ({
  t, item, handleMenuItemClicked,
  onRenderOverflowButton, onRenderItem,
  userId, handleContactDetailClicked,
  handleContactDamageClicked, handleContactDocumentsClicked,
}) => {
  const { makeRequest: refetch, data } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${item.vehicleId}/damageReports`,
    skip: !userId || !item.vehicleId,
    method: FetchMethodsEnum.GET,
  });

  let overflowItems = [
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDetails'),
      onClick: () => {
        if(item.licensePlate) {
          handleContactDetailClicked(item.licensePlate);
        } else {
          toast.error(t('toasts.notLicenceNumberError'));
        }
      }
    },
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDamage'),
      onClick: () => {
        if(item.licensePlate) {
          handleContactDamageClicked(item?.licensePlate);
        } else {
          toast.error(t('toasts.notLicenceNumberError'));
        }
      }
    },
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDocuments'),
      onClick: () => {
        if(item.licensePlate) {
          handleContactDocumentsClicked(item?.licensePlate);
        } else {
          toast.error(t('toasts.notLicenceNumberError'));
        }
      }
    }
  ];

  if (item.isActive) {
    overflowItems.push({
      key: 'deregister',
      name: t('overviewDetail.deregisterVehicle'),
      onClick: handleMenuItemClicked(item.vehicleId, null),
    } as never);
  }
  if( item.licensePlate ) {
    overflowItems = [...overflowItems, ...[
      {
        key: 'reportDamage',
        name: t('overviewDetail.reportDamage'),
        onClick: handleMenuItemClicked(item.vehicleId, item.licensePlate, null, () => refetch()),
      },
    ]as never[]];
  } else {
    overflowItems = [...overflowItems, ...[
      {
        key: 'reportDamage',
        name: t('overviewDetail.reportDamage'),
        onClick: () => toast.error(t('toasts.licenceNumberError'))
        ,
      },
    ]as never[]];
  }

  if (data?.length > 0) {
    overflowItems.push({
      key: 'showDamage',
      name: t('overviewDetail.showDamage'),
      subMenuProps: {
        items: [
          ...data.map(element => {
            return {
              key: `showDamage-${element.id}`,
              text: t('overviewDetail.damageFrom') + moment(element?.damageInformation?.date).format('DD.MM.YYYY'),
              title: moment(element.createDate).format('DD.MM.YYYY'),
              onClick: handleMenuItemClicked(item.vehicleId, item.licensePlate, element.id, () => refetch()),
            };
          }),
        ]},
    } as never);
  }

  return (
    <OverflowSet
      aria-label="menu-bar"
      role="menubar"
      vertical
      overflowItems={overflowItems}
      onRenderOverflowButton={onRenderOverflowButton}
      onRenderItem={onRenderItem}
    />
  );
};
