import React from 'react';
import { Stack, TextField, ITextFieldProps } from '@fluentui/react';
import OptionalLabel from './OptionalLabel';

export default function RequiredTextField(props: ITextFieldProps) {
  const labelExcludedProps = { ...props, label: '' };

  return (
    <Stack>
      <OptionalLabel>{props.label}</OptionalLabel>
      <TextField {...labelExcludedProps} />
    </Stack>
  );
}
