import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import {
  DialogFooter, DialogContent,
  PrimaryButton, Dialog, MessageBar,
  TextField, MessageBarType
} from '@fluentui/react';
import {
  getRequiredError,
  getEmailError,
  validateEmail
} from '@/utils/validations';

import './index.scss';

export enum RolesEnum {
  DSP = 'DSP',
  Administrator = 'Administrator',
  Advisor = 'Advisor',
}

export const CreateUserDialog = ({
  hidden,
  onDismiss,
}) => {
  const { t } = useTranslation();

  const notifySucces = () => toast.success(t('toasts.addUserForAdminSuccess'));
  const notifyError = () => toast.error(t('toasts.addUserForAdminError'));

  const { handleSubmit, control, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
  });

  const { makeRequest: createUser, loading, error } = useFetch({
    endpoint: '/api/users',
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      onDismiss(true);
      notifySucces();
    },
    onError: () => {
      notifyError();
    }
  });

  const onSubmit = async (values) => {
    await createUser({ data: {
      ...values,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role.value
    } });
  };

  const roles = [
    { label: t('tableContent.advisor'), value: RolesEnum.Advisor },
    { label: t('tableContent.admin'), value: RolesEnum.Administrator }
  ];

  return (
    <Dialog
      modalProps={{
        layerProps: { eventBubblingEnabled: true },
        onDismiss: () => onDismiss(),
      }}
      hidden={hidden}
      title={t('tableContent.adminModalTitle')}
      onDismiss={() => onDismiss()}
      minWidth="550px"
      dialogContentProps={{ 
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      {error === 'This email already exists.' && (
        <MessageBar
          className="emailErrorMessage"
          messageBarType={MessageBarType.error}
          isMultiline={false}
        >
          {t('validation.errorEmailAlreadyExists')}
        </MessageBar>
      )}
      <DialogContent>
        <form className='formContainer fields' onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="firstName"
            rules={{ required: true }}
            control={control}
            as={
              <TextField
                label={t('tableContent.firstName')}
                errorMessage={getRequiredError(
                  errors,
                  'firstName',
                  t('contact.firstNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="lastName"
            rules={{ required: true }}
            control={control}
            as={
              <TextField
                label={t('tableContent.lastName')}
                errorMessage={getRequiredError(
                  errors,
                  'lastName',
                  t('contact.lastNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="email"
            rules={{
              required: true,
              validate: {
                emailFormat: validateEmail,
              },
            }}
            control={control}
            as={
              <TextField
                label={t('tableContent.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />
          <Controller
            name="role"
            control={control}
            rules={{
              required: true
            }}
            as={
              <ThemedSelect
                label={t('tableContent.role')}
                placeholder={t('global.select')}
                options={roles}
                errorMessage={getRequiredError(errors, 'role', 'Role is required')}
              />
            }
          />
        </form>
      </DialogContent>
      <DialogFooter styles={{
        actionsRight: { margin: 0 }
      }}
      >
        <PrimaryButton
          disabled={loading}
          className='buttonStyles'
          onClick={() => onDismiss()}
          text={t('global.cancel')}
        />
        <PrimaryButton
          disabled={loading || !formState.dirty || !formState.isValid}
          type="submit"
          className='buttonStyles'
          onClick={handleSubmit(onSubmit)}
          text={t('global.create')}
        />
      </DialogFooter>
    </Dialog>
  );
};

