
import { useMediaQuery as useResponsive } from 'react-responsive';

type MediaQueryResult = {
  isDesktopOrLaptop: boolean;
  isBigScreen: boolean;
  isMobile: boolean;
  isTabletOrMobile: boolean;
  isTablet: boolean;
  isTabletOrMobileDevice: boolean;
  isPortrait: boolean;
};

export const useMediaQuery = () => {
  const isDesktopOrLaptop = useResponsive({
    query: '(min-device-width: 1224px)'
  });
  const isBigScreen = useResponsive({ query: '(min-device-width: 1824px)' });
  const isMobile = useResponsive({ query: '(max-width: 600px)' });
  const isTabletOrMobile = useResponsive({ query: '(max-width: 850px)' });
  const isTablet = useResponsive({ query: '(max-width: 1000px)' });
  const isTabletOrMobileDevice = useResponsive({
    query: '(max-device-width: 1224px)'
  });
  const isPortrait = useResponsive({ query: '(orientation: portrait)' });

  return {
    isDesktopOrLaptop,
    isBigScreen,
    isMobile,
    isTabletOrMobile,
    isTablet,
    isTabletOrMobileDevice,
    isPortrait,
  } as MediaQueryResult;
};
