import React from 'react';

import { Stack } from '@fluentui/react';
import { ChangePasswordForm } from '@/components/forms/ChangePassword';

export default () => {
  return (
    <Stack>
      <ChangePasswordForm hidden={false} />
    </Stack>
  );
};

