/* eslint-disable react/require-default-props */
import React, { CSSProperties, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, Label } from '@fluentui/react';
import { useAuth } from '@/contexts/auth';
import { mainTheme } from '@/theme/themes';
import { useWindowWidthContext } from '@/hooks';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import TabControlNav from './WizardNavItem';

export interface WizardItem {
  component: React.ReactNode;
  header: string;
  iconName?: string;
  hasError?: boolean;
  submit?: boolean;
}

interface WizardProperties {
  items: WizardItem[];
  submitText: string;
  isSubmitEnabled: boolean;
  amountOfInsuredVehicles?: number;
  skipSubmit?: boolean;
  onSubmit: () => void;
  error?: string | null | undefined;
  loading?: boolean;
}

export default function Wizard(props: WizardProperties) {
  const { t } = useTranslation();
  const { isStrong } = useAuth();
  const [activeIndex, setActiveIndex] = useState<number>(
    props.items.length > 0 ? 0 : -1,
  );
  const windowWidth = useWindowWidthContext();

  const headerSize = 56;

  useEffect(() => {
    if (
      props.skipSubmit &&
      props.items[activeIndex].submit &&
      props.items[activeIndex + 1]
    ) {
      setActiveIndex(activeIndex + 1);
    }
  }, [props.skipSubmit, activeIndex, props.items]);

  const rootStyle: CSSProperties = {
    margin: mainTheme.spacing.m,
    flexGrow: 1,
  };

  const headerLabelStyle: CSSProperties = {
    fontWeight: 'bold',
    fontSize: mainTheme.fonts.large.fontSize,
  };

  const contentStyle: CSSProperties = {
    flexGrow: 1,
  };

  if (windowWidth.isSmall) {
    contentStyle.marginLeft = 0;
    contentStyle.marginRight = 0;
  }

  function onHeaderClicked(clickedIndex: number) {
    if (activeIndex !== clickedIndex) {
      setActiveIndex(clickedIndex);
    }
  }

  return (
    <>
      { props.loading ? (
        <SpinnerDefault />
      ) : (
        <Stack style={rootStyle}>
          {activeIndex > -1 && (
          <>
            <Stack style={{ marginBottom: mainTheme.spacing.m }}>
              <Label style={headerLabelStyle}>
                {props.items[activeIndex].header}
              </Label>
            </Stack>

            <Stack style={contentStyle}>
              {props.items[activeIndex].component}
            </Stack>

            <Stack horizontal style={{ marginTop: mainTheme.spacing.m }}>
              { activeIndex !== 0 && activeIndex !== props.items.length - 1 && (
                <PrimaryButton
                  text={t('global.back')}
                  iconProps={{ iconName: 'ChromeBack' }}
                  onClick={() => setActiveIndex(activeIndex - 1)}
                />
              )}
              {activeIndex !== props.items.length - 1 && !props.items[activeIndex].submit && (
                <PrimaryCallToActionButton
                  text={t('global.continue')}
                  iconProps={{ iconName: 'ChromeBackMirrored' }}
                  onClick={() => setActiveIndex(activeIndex + 1)}
                  disabled={props.items[activeIndex].hasError || !props?.amountOfInsuredVehicles}
                  style={{ marginLeft: 'auto', maxWidth: '120px' }}
                />
              )}
              {props.items[activeIndex].submit && (
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto' }}>
                  <div style={{ marginLeft: 'auto' }}>
                    <PrimaryCallToActionButton
                      text={props.submitText}
                      to="/wizard"
                      disabled={!props.isSubmitEnabled || !isStrong}
                      onClick={props.onSubmit}
                    />
                  </div>
                  {props.error && (
                    <span style={{ fontSize: 14, color: 'red', fontWeight: 600, marginTop: 5, textAlign: 'center' }}>
                      {t([props.error === 'This email already exists.' ? 'validation.alreadyExist' : 'validation.errorMessage'])}
                    </span>
                  )}
                </div>
              )}
            </Stack>
            { activeIndex !== props.items.length - 1 && (
              <Stack
                horizontal
                horizontalAlign="center"
                tokens={{ childrenGap: 60 }}
                style={{ marginTop: mainTheme.spacing.m }}
              >
                {props.items.map((item, index) => (
                  item.iconName && (
                    <TabControlNav
                      key={item.iconName}
                      size={headerSize}
                      iconName={item.iconName}
                      isActive={activeIndex === index}
                      onClick={() => onHeaderClicked(index)}
                      hasError={item.hasError || false}
                    />
                  )
                ))}
              </Stack>
            )}
          </>
          )}
        </Stack>
      )}
    </>
  );
}
