import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'office-ui-fabric-react';
import { useMediaQuery } from '@/hooks';
import { dayPickerStrings, onFormatDate, dayPickerStringsGerman } from '@/utils/calendar';

import { useAuth } from '@/contexts/auth';

import {
  SearchBox, Dropdown, IconButton
} from '@fluentui/react';

import './index.scss';

export const FilterBar = ({ filters, setFilters, onFilterChange }: any) => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTabletOrMobile } = useMediaQuery();
  const { userData } = useAuth();

  const categories = [
    { key: 'KFZW', text: 'KFZW' },
    { key: 'BHV', text: 'BHV' },
    { key: 'TRANSP', text: 'TRANSP' },
    { key: 'UV', text: 'UV' },
    { key: '', text: t('global.all') }
  ];

  const options = [
    { key: 'OFFEN', text: t('global.open') },
    { key: 'inProgress', text: t('global.inProgress') },
    { key: 'GESCHLOSSEN', text: t('global.done') },
    { key: '', text: t('global.all') }
  ];

  return (
    <div className='filterBar' style={{ flexWrap: isTabletOrMobile ? 'wrap' : 'nowrap' }}>
      <div className="filterDateContainer" style={{ flexWrap: isTabletOrMobile ? 'wrap' : 'nowrap' }}>
        <span className="textContainer">{t('overviewDamage.from')}</span>
        <DatePicker
          style={{ maxWidth: 150, width: '100%', minWidth: 20, marginBottom: 10 }}
          strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
          formatDate={onFormatDate}
          maxDate={moment().add(1, 'day').toDate()}
          value={filters.from}
          onSelectDate={(date) => {
            onFilterChange({
              ...filters,
              from: date,
            });
          }}
        />
        { filters.from && (
          <IconButton
            allowDisabledFocus
            checked={false}
            className="datePickerIcon"
            onClick={() => {
              setFilters({
                from: null,
                to: filters.to,
                status: filters.status,
                search: filters.search
              });
            }}
            iconProps={{ iconName: 'ErrorBadge' }}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="textContainer">{t('overviewDamage.to')}</span>
          <DatePicker
            strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
            style={{ maxWidth: 150, width: '100%', minWidth: 20, marginBottom: 10 }}
            formatDate={onFormatDate}
            maxDate={moment().add(1, 'day').toDate()}
            value={filters.to}
            onSelectDate={(date) => {
              onFilterChange({
                ...filters,
                to: date,
              });
            }}
          />
        </div>
        { filters.to && (
          <IconButton
            style={{ marginBottom: 10 }}
            allowDisabledFocus
            checked={false}
            className="datePickerIcon"
            onClick={() => {
              setFilters({
                to: null,
                from: filters.from,
                status: filters.status,
                search: filters.search
              });
            }}
            iconProps={{ iconName: 'ErrorBadge' }}
          />
        )}
        <div className="rowContainerDropdown" style={{ marginLeft: isMobile ? 0 : 10 }}>
          <span className="textContainer">{t('overviewDamage.status')}</span>
          <Dropdown
            defaultSelectedKey={filters.status}
            style={{ marginBottom: 10 }}
            options={options}
            className="dropdownContainer"
            onChange={(e, option) => {
              onFilterChange({
                ...filters,
                status: option?.key,
              });
            }}
          />
        </div>
        {userData?.role === 'Advisor' && (
          <div className="rowContainerDropdown" style={{ marginLeft: isMobile ? 0 : 10 }}>
            <span className="textContainer">{t('overviewDamage.category')}</span>
            <Dropdown
              style={{ marginBottom: 10 }}
              defaultSelectedKey={filters.category}
              options={categories}
              className="dropdownContainer"
              onChange={(e, option) => {
                onFilterChange({
                  ...filters,
                  category: option?.key,
                });
              }}
            />
          </div>
        )}
      </div>

      <div className="rowContainer">
        <span className="textContainer">{t('overviewDamage.filter')}</span>
        <form>
          <SearchBox
            iconProps={{ iconName: 'Search' }}
            className='searchInputFilterBar'
            value={filters.search}
            onChange={(e: any) => {
              onFilterChange({
                ...filters,
                search: e?.target?.value,
              });
            }}
          />
        </form>

      </div>
    </div>
  );
};
