export const checkComplete = (values) => {
  if (
    values.firstName && values.lastName && values.isDrivingLicenceAvailable 
    && values.reason && values.place && values.recordedByPolice
    && (values.recordedByPolice?.value === 'true' ? values.receptionPoliceStation : true)
    && (values.isGapInsurance || values.isFullInsurance || values.isLiabilityInsurance)
    && (values.recordedByPolice?.value === 'true' ? values.policeFileNumber : true)
    &&  (values.isLiabilityInsurance ? values.otherLastName : true)
  ) {
    return true;
  }
  return false;
}