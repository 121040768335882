import React, {
  useState,
  useContext,
  createContext,
} from 'react';

type MethodValue = {
  value: string;
  label: string;
};

export type ContextProps = {
  paymentMethod: {
    set: (value: MethodValue) => void;
    value: MethodValue;
  };
  mountly: boolean;
  reset: () => void;
};

const DEFAULT_STATE: ContextProps = {
  paymentMethod: {
    set: () => {},
    value: {
      value: 'monthlyMethod',
      label: 'paymentMethods.monthlyMethod',
    }
  },
  mountly: true,
  reset: () => {},
};

export const CalculationContext = createContext(DEFAULT_STATE);
CalculationContext.displayName = 'CalculationContext';

export const CalculationProvider = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState({
    value: 'monthlyMethod',
    label: 'paymentMethods.monthlyMethod',
  });

  const value: ContextProps = {
    paymentMethod: {
      value: paymentMethod,
      set: setPaymentMethod,
    },
    mountly: paymentMethod.value === 'monthlyMethod',
    reset: () => {
      setPaymentMethod(DEFAULT_STATE.paymentMethod.value);
    }
  };

  return (
    <CalculationContext.Provider value={value}>
      {children}
    </CalculationContext.Provider>
  );
};

export const useCalculation = () => useContext(CalculationContext);
