import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import {
  Stack,
  ShimmeredDetailsList,
  SelectionMode,
  PrimaryButton,
  DetailsListLayoutMode,
  IDetailsHeaderProps,
  OverflowSet,
  IOverflowSetItemProps,
  CommandBarButton, TooltipHost,
  ActionButton
} from '@fluentui/react';

import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';
import { ChangeInformationDialog, RolesEnum } from '@/components/base/ChangeInformationDialog';

import { ConfirmDialog } from '@/components/base/ConfirmDialog';
import { CreateUserDialog } from '@/components/base/CreateUserDialog';
import { useAuth } from '@/contexts/auth';

import './index.scss';

const onRenderItemStyles = {
  root: { padding: '10px' },
};
const onRenderOverflowButtonStyles = {
  root: { padding: '10px', background: 'transparent' },
  menuIcon: { fontSize: '16px' },
};

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      aria-label={item.name}
      styles={onRenderItemStyles}
      iconProps={{ iconName: item.icon }}
      onClick={item.onClick}
    />
  );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      title="Actions"
      styles={onRenderOverflowButtonStyles}
      menuIconProps={{ iconName: 'CollapseMenu' }}
      menuProps={{ items: overflowItems! }}
    />
  );
};

export default function AdminPage(): JSX.Element {
  const { t } = useTranslation();
  const [dsp, setDsp] = useState();
  const [hiddenChangeInformation, setHiddenChangeInformation] = useState(true);
  const [hiddenConfirmation, setHiddenConfirmation] = useState(true);
  const [isNeedSync, setNeedSync] = useState(true);
  const [isPendingSync, setPendingSync] = useState(true);
  const [isCreateUser, setCreateUser] = useState(true);
  const [users, setUsers] = useState([]);
  const { userData } = useAuth();
  const history = useHistory();

  const notifySucces = () => toast.success(t('toasts.deleteAdminAndAdvisorSuccess'));
  const notifyError = () => toast.error(t('toasts.deleteAdminAndAdvisorError'));

  const notifySyncSucces = () => toast.success(t('toasts.syncSuccess'));
  const notifySyncError = () => toast.error(t('toasts.syncError'));

  const updateNotifySucces = () => toast.success(t('toasts.changeAccountStatusSuccess'));
  const updateNotifyError = () => toast.error(t('toasts.changeAccountStatusError'));

  const { loading, makeRequest: refetch } = useFetch({
    endpoint: '/api/users',
    queryParams: {
      take: 1000,
    },
    method: FetchMethodsEnum.GET,
    onCompleted: nextData => {
      setUsers(nextData);
    }
  });

  const { makeRequest: updateUser } = useFetch({
    endpoint: '/api/users/',
    method: FetchMethodsEnum.PUT,
    onCompleted: () => {
      refetch();
      updateNotifySucces();
    },
    onError: () => {
      updateNotifyError();
    }
  });

  const { makeRequest: sincFileWithSFTP, loading: syncRequestLoading } = useFetch({
    endpoint: '/api/sftp/sync/start',
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      syncRequest();
      notifySyncSucces();
    },
    onError: () => {
      notifySyncError();
    }
  });
  
  const { loading: needSyncLoading } = useFetch({
    endpoint: '/api/sftp/sync/isNeed',
    method: FetchMethodsEnum.GET,
    onCompleted: (nextData) => {
      setNeedSync(nextData);
    },
  });

  const { makeRequest: syncRequest, loading: syncLoading } = useFetch({
    endpoint: '/api/sftp/sync/isWork',
    method: FetchMethodsEnum.GET,
    onCompleted: (nextData) => {
      setPendingSync(nextData);
    },
  });

  const { makeRequest: deleteUser } = useFetch({
    endpoint: '/api/users/',
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      refetch();
      notifySucces();
    },
    onError: () => {
      notifyError();
    }
  });

  const handleMenuItemClicked = (nextData) => () => {
    setDsp(nextData);
    setHiddenChangeInformation(false);
  };

  const handleMenuItem = (nextData) => () => {
    setDsp(nextData);
    setHiddenConfirmation(false);
  };

  const columns = [
    {
      key: 'firstName',
      name: t('tableContent.firstName'),
      ariaLabel: t(['tableContent.tooltipFirstName', '']),
      fieldName: 'firstName',
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        return (
          <span>{item.firstName}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'lastName',
      name: t('tableContent.lastName'),
      ariaLabel: t(['tableContent.tooltipLastName', '']),
      fieldName: 'lastName',
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        return (
          <span>{item.lastName}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'email',
      name: t('tableContent.email'),
      ariaLabel: t(['tableContent.tooltipEmail', '']),
      fieldName: 'email',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        return (
          <span>{item.email}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'role',
      name: t('tableContent.role'),
      ariaLabel: t(['tableContent.tooltipRole', '']),
      fieldName: 'role',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        if(item.role === 'Advisor') {
          return (
            <span>{t('tableContent.advisor')}</span>
          );
        } else {
          return (
            <span>{t('tableContent.admin')}</span>
          );
        }
      },
      isPadded: true,
    },
    {
      key: 'isActive',
      name: t('tableContent.active'),
      ariaLabel: t(['tableContent.tooltipActive', '']),
      fieldName: 'isActive',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <ActionButton iconProps={item.isActive ? { iconName: 'Accept', style: {
            color: 'green'
          } } : {
            iconName: 'ChromeClose', style: {
              color: 'red'
            }
          }}
          />
        );
      },
      isPadded: true,
    },
    {
      key: 'actions',
      name: t('tableContent.actions'),
      ariaLabel: t(['tableContent.tooltipActions', '']),
      fieldName: 'actions',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        let overflowItems = [];

        if (item.isActive) {
          overflowItems.push({
            key: 'editData',
            name: t('tableContent.editData'),
            onClick: handleMenuItemClicked(item),
          } as never);
        }

        overflowItems = [...overflowItems, ...[
          item.isActive ? ({
            key: 'deactivateAccount',
            name: t('tableContent.deactiveAccount'),
            onClick: () => {
              updateUser({
                endpoint: `/api/users/${item?.id}`,
                data: {
                  ...item,
                  isActive: false,
                }
              });
            },
          }) : ({
            key: 'activateAccount',
            name: t('tableContent.activateAccount'),
            onClick: () => {
              updateUser({
                endpoint: `/api/users/${item?.id}`,
                data: {
                  ...item,
                  isActive: true,
                }
              });
            },
          }),
          {
            key: 'deleteAccount',
            name: t('tableContent.deleteAccount'),
            onClick: handleMenuItem(item),
          },
        ] as never[]];

        return (
          <OverflowSet
            aria-label="menu-bar"
            role="menubar"
            vertical
            overflowItems={overflowItems}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        );
      },
      isPadded: true,
    }
  ];

  return (
    <Stack className='tableContainer'>
      <PrimaryButton
        onClick={() => {
          setCreateUser(false);
        }}
        className='adminButtonStyles'
        text={t('tableContent.addUserButton')}
      />

      <div className='buttonRowContainer'>
        { !needSyncLoading && !syncLoading && (
          <PrimaryCallToActionButton
            text='Dateitransfer starten'
            style={{ maxWidth: 200 }}
            className='adminButtonStyles'
            disabled={(!isNeedSync || isPendingSync) || syncRequestLoading}
            onClick={() => sincFileWithSFTP()}
          />
        )}
        <PrimaryButton
          onClick={() => history.push('/view-settings')}
          className='adminButtonStyles'
          text={t('tableContent.settingsButton')}
        />
        <PrimaryButton
          onClick={() => history.push('/editor')}
          className='adminButtonStyles'
          text={t('tableContent.faqEditor')}
        />
      </div>
      <ShimmeredDetailsList
        viewport={{
          width: 1150,
          height: 500,
        }}
        styles={{
          root: {
            minWidth: '100vw',
          }
        }}
        enableShimmer={loading}
        items={users}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderDetailsHeader={(props, defaultHeader) => defaultHeader!({
          ...props,
          onRenderColumnHeaderTooltip: (tooltipHostProps) => {
            return <TooltipHost
              styles={{
                root: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }}
              {...tooltipHostProps}
            />;
          },
        } as IDetailsHeaderProps)}
        
      />
      {!hiddenConfirmation && (
        <ConfirmDialog
          confirmSave={false}
          contentText={t('tableContent.deleteText')}
          user={dsp}
          unsavedChanges={false}
          hidden={hiddenConfirmation}
          onDismiss={() => {
            setHiddenConfirmation(true);
          }}
          onDelete={() => {
            const { id } = dsp || { id: '' };
            deleteUser({
              endpoint: `api/users/${id}`,
            });
            setHiddenConfirmation(true);
          }}
        />
      )}
      {!hiddenChangeInformation && (
        <ChangeInformationDialog
          isAdmin={userData?.role === RolesEnum.Administrator}
          users={dsp}
          hidden={hiddenChangeInformation}
          onDismiss={(needUpdate) => {
            if (needUpdate) {
              refetch();
            }
            setHiddenChangeInformation(true);
          }}
        />
      )}
      {!isCreateUser && (
        <CreateUserDialog
          hidden={isCreateUser}
          onDismiss={(needUpdate) => {
            if (needUpdate) {
              refetch();
            }
            setCreateUser(true);
          }}
        />
      )}
    </Stack>
  );
}
