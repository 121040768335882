/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Select, { Props, OptionTypeBase, components } from 'react-select';
import { Stack, Label } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';

import { useThemedSelectStyle } from '@/hooks/useThemedSelectStyle';
import ThemedSelectError from './ThemedSelectError';

import './styles.scss'

const Option = (props) => {
  const { t } = useTranslation();

  return (
    <components.Option {...props}>
      {t(props.children)}
    </components.Option>
  );
};

const SingleValue = (props) => {
  const { t } = useTranslation();

  return (
    <components.SingleValue {...props}>
      {props.children && t(props.children)}
    </components.SingleValue>
  );
};

const Menu = (props) => {
  return (
    <components.Menu className="drop-down-menu" {...props} />
  );
};

export interface ThemedSelectProperties<
  OptionType extends OptionTypeBase = { label: string; value: string }
> extends Props<OptionType> {
  label?: string;
  selectDisabled?: boolean,
  errorMessage?: string | JSX.Element;
  translate?: boolean;
}

export default function ThemedSelect(
  props: ThemedSelectProperties
): JSX.Element {
  const { t } = useTranslation();
  const themedSelectStyles = useThemedSelectStyle();

  return (
    <Stack style={{
      position: 'relative'
    }}>
      {props.label && (
        <Label htmlFor={props.id} disabled={props.isDisabled}>
          {t(props.label)}
        </Label>
      )}
      <Select
        {...props}
        isDisabled={props.selectDisabled}
        styles={themedSelectStyles}
        components={{
          Option,
          SingleValue,
          Menu,
        }}
      />
      {props.errorMessage && (
        <ThemedSelectError errorMessage={props.errorMessage} />
      )}
    </Stack>
  );
}
