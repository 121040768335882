/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import jwtDecode from 'jwt-decode';


import { useFetch, FetchMethodsEnum, FetchHookResult } from '@/hooks/useFetch';
import { AUTH_TOKEN_COOCKIE_PATH } from '@/constants/cookie';

const persistedToken = localStorage.getItem(AUTH_TOKEN_COOCKIE_PATH);
const defaultAuthorized = Boolean(persistedToken && persistedToken !== 'null');
const defaultLoginData = localStorage.getItem('loginData');

export type ContextProps = {
  authorized: boolean;
  login?: FetchHookResult;
  registration?: FetchHookResult;
  logout: () => void;
  token?: string | null;
  userData?: {
    [key: string]: string;
  };
  loginData?: {
    [key: string]: string;
  };
  isStrong: boolean;
  setIsStrong: (strong: boolean) => void;
};

const DEFAULT_STATE: ContextProps = {
  authorized: false,
  logout: () => {},
  isStrong: false,
  setIsStrong: () => {},
};

export const AuthContext = createContext(DEFAULT_STATE);
AuthContext.displayName = 'AuthContext';


export const AuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(defaultAuthorized);
  const [token, setToken] = useState(defaultAuthorized ? persistedToken : null);
  const [userData, setUserData] = useState();
  const [loginData, setLoginData] = useState(defaultLoginData ? JSON.parse(defaultLoginData) : null);
  const [isStrong, setIsStrong] = useState(false);

  useEffect(() => {
    if (token && authorized) {
      setUserData(
        jwtDecode(token)
      );
    } else {
      setUserData(undefined);
    }
  }, [token, authorized]);

  const logout = useCallback(() => {
    setAuthorized(false);
    setToken(null);

    localStorage.removeItem('loginData');
    localStorage.removeItem(AUTH_TOKEN_COOCKIE_PATH);
  }, []);

  const handleSetToken = useCallback((data) => {
    const nextToken = data?.token;
    localStorage.setItem(AUTH_TOKEN_COOCKIE_PATH, data?.token);

    localStorage.setItem('loginData', JSON.stringify(data));

    setToken(nextToken);
    setAuthorized(true);
    setLoginData(data);
  }, []);

  const login = useFetch({
    endpoint: '/api/users/login',
    method: FetchMethodsEnum.POST,
    onCompleted: handleSetToken,
  });

  const registration = useFetch({
    endpoint: '/api/users/registration',
    method: FetchMethodsEnum.POST,
    onCompleted: handleSetToken,
  });

  const contextProps: ContextProps = {
    authorized,
    login,
    logout,
    registration,
    token,
    userData,
    loginData,
    isStrong,
    setIsStrong,
  };

  return (
    <AuthContext.Provider value={contextProps}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export const withAuth = () => Component => props => (
  <AuthContext.Consumer>
    {(values => (
      <Component auth={values} {...props} />
    ))}
  </AuthContext.Consumer>
);
