import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

import {
  DialogFooter,
  DialogContent,
  PrimaryButton,
  Dialog, TextField,
  Label, getTheme, ActionButton
} from '@fluentui/react';
import { DatePicker, Spinner } from 'office-ui-fabric-react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { dayPickerStrings, dayPickerStringsGerman, onFormatDate } from '@/utils/calendar';
import moment from 'moment';

type ValuesType = {
  date: null | Date;
  file: null | any[];
  comment: string;
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: getTheme().semanticColors.inputBorder,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  color: getTheme().semanticColors.primaryButtonBackground,
  borderColor: getTheme().semanticColors.primaryButtonBackground,
  borderStyle: 'solid',
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export const DeregistrationVehicleDialog = ({
  hidden,
  onDismiss,
  vehicleId,
  userId,
}) => {
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    defaultValues: {
      date: null,
      comment: '',
      file: null,
    } as unknown as ValuesType,
  });
  const { t, i18n } = useTranslation();
  const { file, date } = watch();
  const [fileNames, setFileNames] = useState([] as string[]);

  const notifySucces = () => toast.success(t('toasts.deregisterVehicleSuccess'));
  const notifyError = () => toast.error(t('toasts.deregisterVehicleSuccess'));

  useEffect(() => {
    reset({
      date: null,
      comment: '',
      file: null,
    });
    setFileNames([]);
  }, [hidden]);

  const onDrop = useCallback(acceptedFiles => {
    setValue('file', acceptedFiles[0]);
    setFileNames([...acceptedFiles].map(file => file.name));
  }, [setValue]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: 'image/*', multiple: false });

  const [loading, setLoading] = useState(false);
  const { makeRequest: send } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/deregistrate`,
    skip: userId || vehicleId,
    method: FetchMethodsEnum.PUT,
    onLoadingStart: () => setLoading(true),
    onCompleted: () => {
      onDismiss(true);
      notifySucces();
    },
    onError: () => {
      notifyError();
      onDismiss(true);
    }
  });

  const onSubmit = async (values) => {
    const formData = new FormData();

    formData.append('DeregistrationCertificate', values.file);
    formData.append('DeregistrationDate', `${moment(values?.date).format('YYYY-MM-DDT00:00:00.000')  }Z`);
    formData.append('Comment', values.comment);

    await send({ formData });
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]) as CSSProperties;

  return (
    <Dialog
      modalProps={{
        layerProps: { eventBubblingEnabled: true },
      }}
      hidden={hidden}
      title={t('overviewDetail.deregisterVehicleDialog.title')}
      onDismiss={onDismiss}
      minWidth="650px"
      dialogContentProps={{ 
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      <DialogContent>
        {!loading ? (
          <form style={{ marginTop: '-30px' }} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="date"
              control={control}
              as={
                <DatePicker
                  disableAutoFocus
                  strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
                  placeholder={t('overviewDetail.deregister.placeholder.date')}
                  label={t('overviewDetail.deregister.fields.date')}
                  formatDate={onFormatDate}
                  maxDate={new Date()}
                  onSelectDate={(date) => {
                    setValue('date', date as Date);
                  }}
                />
              }
            />
            <Controller
              name="file"
              control={control}
              as={
                <div>
                  <Label>{t('overviewDetail.deregister.fields.upload')}</Label>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />  
                    <p>{t('overviewDetail.deregister.placeholder.upload')}</p>
                  </div>
                  {fileNames.map(fileName => (
                    <div key={fileName} style={{ display: 'flex', justifyContent: 'space-between', maxHeight: 25 }}>
                      <li key={fileName} className="fileListDefaultStyle">
                        {fileName}
                      </li>
                      <ActionButton
                        onClick={() => {
                          if (fileNames.length > 0) {
                            setFileNames(fileNames.filter((name) => name !== fileName));
                          }
                        }}
                        iconProps={{ iconName: 'ChromeClose', styles: {
                          root: {
                            fontSize: '10px',
                            height: 25,
                            cursor: 'poiner'
                          }
                        } }}
                      />
                    </div>
                  ))}
                </div>
              }
            />
            <Controller
              name="comment"
              control={control}
              as={
                <TextField
                  multiline
                  label={t('overviewDetail.deregisterVehicleDialog.comment')}
                />
              }
            />
          </form>
        ) : (
          <Spinner />
        )}
      </DialogContent>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        }
      }}
      >
        <PrimaryButton disabled={loading} style={{ margin: 10 }} onClick={onDismiss} text={t('global.cancel')} />
        <PrimaryButton
          disabled={loading || !file || !date || fileNames.length === 0}
          type="submit"
          style={{ margin: 10 }}
          onClick={handleSubmit(onSubmit)}
          text={t('global.send')}
        />
      </DialogFooter>
    </Dialog>
  );
};
