import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';

import uniqBy from 'lodash.uniqby';

type Props = {
  insurances: VehicleInsuranceDetails[];
  createdDSPAccount: {
    id: string;
    [key: string]: string;
  };
};

export const Thanks = (props: Props) => {
  const { t } = useTranslation();

  const handleDownload = (url, vehicleType) => async () => {
    const link = document.createElement('a');
    
    link.setAttribute('download', `eVB Bestätigung (${vehicleType}).pdf`);
    link.setAttribute('target', '_blank');
    
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;
    
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const formattedData = uniqBy(props.insurances, 'vehicleType.id').map(elem => ({
    id: elem.vehicleType.id,
    label: t(`vehicles.${elem.vehicleType.id}`),
    value: elem.vehicleType.id,
    downloadUrl: `/api/dspusers/${props.createdDSPAccount.id}/insuranceConfirmation/${elem.vehicleType.id}/pdf`
  }));

  return (
    <Stack>
      <Text>{t('wizardStepFour.hint')}</Text>

      <table style={{ marginTop: 16 }}>
        <tbody>
          {formattedData.map(item => (
            <tr key={item.id}>
              <td style={{ paddingRight: 40 }}>
                <Text>{item.label}</Text>
              </td>
              <td style={{ paddingRight: 40 }}>
                <Text style={{ fontWeight: 'bold' }}>A1BC25Z</Text>
              </td>
              <td style={{ paddingRight: 40 }}>
                <PrimaryButton onClick={handleDownload(item.downloadUrl, item.id)} text={t('wizardStepFour.downloadPdf')} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Text style={{ marginTop: 16 }}>{t('wizardStepFour.hint2')}</Text>
      <Text style={{ marginTop: 16 }}>{t('wizardStepFour.hint3')}</Text>
    </Stack>
  );
};
