import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import useDebounce from '@/components/pages/OverviewDamageAdvisor/useDebaunce';
import PasswordStrengthBar from '@/components/base/PasswordStrengthBar';
import { Stack, TextField, PrimaryButton, DialogType, Dialog, Link } from '@fluentui/react';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import { ConfirmationDialog } from '@/components/base/ConfirmationDialog';
import {
  getEmailError,
  validateEmail,
  getConfirmPasswordError,
  validatePassword,
  getPasswordError
} from '@/utils/validations';
import { useAuth } from '@/contexts/auth';

import './index.scss';
import { hasErrors } from '@/utils/hasErrors';


export const SetPasswordForm = ({ hidden }) => {
  const location = useLocation();
  const { logout, authorized } = useAuth();
  const [hiddenModal, setHiddenModal] = useState(false);
  const [isStrong, setIsStrong] = useState(false);

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    if(authorized) {
      logout();
    }
  }, [logout, authorized]);

  const history = useHistory();
  const { t } = useTranslation();
  const { control, errors, handleSubmit, getValues, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: urlParams.get('email') || '',
      password: '',
      confirmPassword: ''
    },
  });

  const { login } = useAuth();

  const { makeRequest: setNewPassword } = useFetch({
    endpoint: '/api/users/newPassword',
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      setHiddenModal(true);
    }
  });

  const onSubmit = async (values) => {
    setNewPassword({
      headers: {
        'Authorization': `Bearer ${urlParams.get('token')}`
      },
      data: {
        ...values
      },
    });
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: t('login.confirmRegistration'),
  };

  const handleClose = () => {
    history.push('/');
  };

  const validateSameAsPassword = (value: string | undefined) => {
    const pass = getValues('password');
    return !value || value === pass;
  };

  const isPassword = watch('password');
  const debouncedPassword = useDebounce(isPassword, 200);


  return (
    <>
      {!hiddenModal && (
        <Dialog
          hidden={hidden}
          minWidth="400px"
          dialogContentProps={dialogContentProps}
          modalProps={{ isBlocking: true }}
          onDismiss={handleClose}
        >
          <form onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
            <Stack tokens={{ childrenGap: 4 }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    emailFormat: validateEmail,
                  },
                }}
                as={
                  <TextField
                    label={t('global.email')}
                    errorMessage={getEmailError(errors, 'email', t)}
                  />
                }
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    password: validatePassword,
                  }
                }}
                as={
                  <TextField
                    type="password"
                    canRevealPassword={true}
                    label={t('global.password')}
                    errorMessage={getPasswordError(
                      errors,
                      'password',
                      t
                    )}
                  />
                }
              />
              <PasswordStrengthBar onStrong={setIsStrong} password={debouncedPassword} />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    sameAsPassword: validateSameAsPassword,
                  }
                }}
                as={
                  <TextField
                    type="password"
                    label={t('login.confirmPassword')}
                    errorMessage={getConfirmPasswordError(
                      errors,
                      'confirmPassword',
                      t
                    )}
                  />
                }
              />
              <PrimaryButton
                disabled={login?.loading || !isStrong || !formState.dirty || hasErrors(errors)}
                className='marginContainer'
                text={t('login.registrationButton')}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              />
              <Link className='marginContainer' href="/forgot-password">{t('login.forgotPassword')}</Link>
            </Stack>
          </form>
        </Dialog>
      )}
      <ConfirmationDialog
        hidden={!hiddenModal}
        onDismiss={() => {history.push('/login');}}
        renderContent={t('login.completeSetPassword')}
        textButton={t('global.ok')}
      />
    </>
  );
};

SetPasswordForm.defaultProps = {
  hidden: false,
};
