import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import {
  Stack, TextField,
  PrimaryButton, DialogType,
  Dialog, MessageBar,
  Link, MessageBarType
} from '@fluentui/react';

import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import {
  getRequiredError,
  getEmailError,
  validateEmail,
} from '@/utils/validations';
import { useAuth } from '@/contexts/auth';

import './index.scss';

type Props = {
  hidden: boolean;
};

export const LoginForm = ({ hidden }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { login, userData, authorized } = useAuth();
  const [isShowMessage, setShowMessage] = useState(false);
  const urlParams = new URLSearchParams(location.search);

  const { control, errors, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: urlParams.get('email') || '',
      password: '',
    },
  });

  const { makeRequest: setActivate } = useFetch({
    endpoint: `/api/dspusers/${urlParams.get('email')}/activate`,
    method: FetchMethodsEnum.POST,
    tokenURL: urlParams.get('token'),
    onCompleted: () => {
      setShowMessage(true);
    }
  });

  useEffect(() => {
    !authorized && setActivate();
    reset({
      email: urlParams.get('email') || '',
      password: '',
    });
    login?.clearError();
  }, [isShowMessage]);

  const { data: User } = useFetch({
    endpoint: `/api/dspusers/${userData?.nameid}`,
    method: FetchMethodsEnum.GET,
    skip: userData?.role === 'Advisor' || userData?.role === 'Administrator',
  });

  const onSubmit = values => login?.makeRequest({
    data: values,
  });

  const dialogContentProps = {
    type: DialogType.normal,
    title: t('login.pleaseLogin'),
  };

  const handleClose = () => {
    reset({
      email: urlParams.get('email') || '',
      password: '',
    });
    login?.clearError();
    history.push('/');
  };

  return (
    <>
      {userData?.role === 'DSP' && User?.isCompleted && <Redirect to="/overview" /> }
      {userData?.role === 'DSP' && !User?.isCompleted && <Redirect to="/overview-detail" />}
      {userData?.role === 'Advisor' && <Redirect to="/landing-advisor" />}
      {userData?.role === 'Administrator' && <Redirect to="/landing-admin" />}
      <Dialog
        hidden={hidden}
        minWidth="400px"
        dialogContentProps={dialogContentProps}
        modalProps={{
          isBlocking: true,
        }}
        onDismiss={handleClose}
      >
        {login?.error && !(login?.error === 'User is not activated.') && !(login?.error === 'User is deactivated.') && (
          <MessageBar
            className="errorMessage"
            messageBarType={MessageBarType.error}
            isMultiline={true}
          >
            {t('login.errorMessage')}
          </MessageBar>
          )}
        {login?.error === 'User is not activated.' && (
          <MessageBar
            className="errorMessage"
            messageBarType={MessageBarType.error}
            isMultiline={true}
          >
            {t('login.inactiveAccountErrorMessage')}
          </MessageBar>
        )}
        {login?.error === 'User is deactivated.' && (
          <MessageBar
            className="errorMessage"
            messageBarType={MessageBarType.error}
            isMultiline={true}
          >
            {t('login.deactiveAccountErrorMessage')}
          </MessageBar>
        )}
        {isShowMessage && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
          >
            {t('login.activateUser')}
          </MessageBar>
        )}
        <form onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
          <Stack tokens={{ childrenGap: 4 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                validate: {
                  emailFormat: validateEmail,
                },
              }}
              as={
                <TextField
                  label={t('global.email')}
                  errorMessage={getEmailError(errors, 'email', t)}
                />
          }
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  type="password"
                  canRevealPassword={true}
                  label={t('global.password')}
                  errorMessage={getRequiredError(
                    errors,
                    'password',
                    t('validation.getPasswordRequiredError')
                  )}
                />
            }
            />
            <PrimaryButton
              disabled={login?.loading}
              className='marginContainer'
              text={t('login.loginButton')}
              onClick={handleSubmit(onSubmit)}
            />
            <Link className='marginContainer' href="/forgot-password">{t('login.forgotPassword')}</Link>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};