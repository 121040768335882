/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useState, useEffect } from 'react';
import axios from 'axios';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import AdditionalServices from '@/utils/services/AdditionalServices';
import { mainTheme } from '@/theme/themes';
import { AdditionalServicesDetails } from '@/utils/services/AdditionalServices/additionalServicesDetails';
import { sortArrayAsc, sortArrayDesc } from '@/utils/sortArray';
import { useWindowWidthContext } from '@/hooks';
import { Stack, Spinner, SpinnerSize } from '@fluentui/react';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';

import { useCalculation } from '@/contexts/calculation';
import Wizard, { WizardItem } from './Wizard';
import InsuredVehicles from './InsureVehicles/InsuredVehicles';
import SubmitInsurances from './SubmitInsurances';
import { VehicleInsuranceDetails } from '../../../utils/services/vehicleInsurancedetails';
import { InsuranceMasterDataDto } from './insuranceMasterDataDto';
import { createDefaultInsurance } from './InsureVehicles/createDefaultInsurance';
import { ClientDetails } from './SubmitInsurances/ClientDetails';

import { Thanks } from './Thanks';

const maxVehiclesAmount = 25;

export default function WizardPage(): JSX.Element {
  const { t } = useTranslation();

  const { paymentMethod, mountly, reset } = useCalculation();
  const [masterData, setMasterData] = useState<InsuranceMasterDataDto>();

  const [insurances, setInsurances] = useState<VehicleInsuranceDetails[]>();
  const [amountOfInsuredVehicles, setAmountOfInsuredVehicles] = useState<
  number
  >(0);

  const [additionalServices, setAdditionalServices] = useState<
  AdditionalServicesDetails
  >();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (additionalServices && masterData) {
      setAdditionalServices(
        new AdditionalServicesDetails(
          v4(),
          additionalServices.amountOfEmployees,
          additionalServices.participationAmount,
          additionalServices.amountOfVehicles,
          additionalServices.businessLiabilityInsurance.isUsed,
          additionalServices.trafficLegalProtection.isUsed,
          additionalServices.driverLegalProtection.isUsed,
          additionalServices.groupAccidentInsurance.isUsed,
          additionalServices.trafficLiabilityInsurance.isUsed,
          masterData,
          mountly,
        ));

      const nextInsurances: any = insurances?.map(insurance => {
        return new VehicleInsuranceDetails(
          insurance.id,
          insurance.vehicleType,
          insurance.insuranceTypes,
          insurance.amount,
          masterData?.vatPercentage as any,
          masterData?.claimRedemptionsPerCar as any,
          mountly,
        );
      });
      onInsurancesChanged(nextInsurances);
    }
  }, [mountly, JSON.stringify(insurances)]);
  const [clientDetails, setClientDetails] = useState<ClientDetails>();
  const [areClientDetailsValid, setAreClientDetailsValid] = useState<boolean>(
    false
  );

  const windowWidth = useWindowWidthContext();

  const { makeRequest: registerDSP, data: createdDSPAccount, error, loading } = useFetch({
    endpoint: '/api/dspusers/register',
    method: FetchMethodsEnum.POST,
    data: {
      ...clientDetails?.getUserData(),
      insuranceOffer: {
        ...clientDetails?.getData(),
        paymentMethod: !mountly ? 'annual' : 'monthly',
        vehicleInsurances: insurances?.map(insurance => ({
          'vehicleType': insurance.vehicleType.id,
          'numberOfVehicles': insurance.amount,
          'isComprehensiveCoverSelected': insurance.insuranceTypes.some(
            (usedType) => usedType.id === 'comprehensiveCover',
          ),
          'isLiabilitySelected': true,
          'isGapCoverageSelected': insurance.insuranceTypes.some(
            (usedType) => usedType.id === 'gapCoverage',
          ),
          'thirdPartyLiability': insurance.insuranceCosts['thirdPartyLiability'],
          'comprehensiveCover': insurance.insuranceCosts['comprehensiveCover'],
          'gapCoverage': insurance.insuranceCosts['gapCoverage'],
          'netTotal': insurance.grossAmount,
          'vat': insurance.vatPercentage / 100,
        })),
        additionalProducts: {
          'businessLiabilityInsurance': additionalServices?.businessLiabilityInsurance?.price ? ({
            'numberOfEmployers': additionalServices?.amountOfEmployees,
            'pricePerEmployer': (additionalServices ? (
              additionalServices.businessLiabilityInsurance.price / additionalServices.amountOfEmployees
            ) : 0) || 0,
            'netTotal': (additionalServices ? (
              Number((additionalServices.businessLiabilityInsurance.price).toFixed(2))
            ) : 0) || 0,
            'netTotalWithTaxes': (additionalServices ? (
              Number((additionalServices.businessLiabilityInsurance.price * 1.19).toFixed(2))
            ) : 0) || 0,
          }) : null,
          'driverLegalProtection': additionalServices?.driverLegalProtection?.price ? ({
            'numberOfVehicles': additionalServices?.amountOfVehicles,
            'pricePerVehicle': (additionalServices ? (
              additionalServices.driverLegalProtection.price / additionalServices.amountOfVehicles
            ) : 0) || 0,
            'deductible': additionalServices?.participationAmount.driver,
            'netTotal': Number((additionalServices?.driverLegalProtection.price).toFixed(2)),
            'netTotalWithTaxes': Number((additionalServices?.driverLegalProtection.price * 1.19).toFixed(2))
          }) : null,
          'groupAccidentInsurance': additionalServices?.groupAccidentInsurance?.price ? ({
            'numberOfEmployers': additionalServices?.amountOfEmployees,
            'pricePerEmployer': (additionalServices ? (
              additionalServices.groupAccidentInsurance.price / additionalServices.amountOfEmployees
            ) : 0) || 0,
            'netTotal': Number((additionalServices?.groupAccidentInsurance.price).toFixed(2)),
            'netTotalWithTaxes': Number((additionalServices?.groupAccidentInsurance.price * 1.19).toFixed(2))
          }) : null,
          'trafficLegalProtection': additionalServices?.trafficLegalProtection?.price ? ({
            'numberOfVehicles': additionalServices?.amountOfVehicles,
            'deductible': additionalServices?.participationAmount.traffic,
            'pricePerVehicle': (additionalServices ? (
              additionalServices.trafficLegalProtection.price / additionalServices.amountOfVehicles
            ) : 0) || 0,
            'netTotal': Number((additionalServices?.trafficLegalProtection.price).toFixed(2)),
            'netTotalWithTaxes': Number((additionalServices?.trafficLegalProtection.price * 1.19).toFixed(2))
          }) : null,
          'trafficLiablilityInsurance': additionalServices?.trafficLiabilityInsurance?.price ? ({
            'numberOfVehicles': additionalServices?.amountOfVehicles,
            'pricePerVehicle': (additionalServices ? (
              additionalServices.trafficLiabilityInsurance.price / additionalServices.amountOfVehicles
            ) : 0) || 0,
            'netTotal': Number((additionalServices?.trafficLiabilityInsurance?.price).toFixed(2)),
            'netTotalWithTaxes': Number((additionalServices?.trafficLiabilityInsurance?.price * 1.19).toFixed(2))
          }) : null,
          'netTotalWithTaxes': additionalServices?.costsOfServices ? (Number((additionalServices?.costsOfServices * 1.19).toFixed(2))) : (0),
          'netTotal': additionalServices?.costsOfServices,
        }
      }
    },
  });

  const pageStyle: CSSProperties = {
    flexGrow: 1,
    backgroundColor: mainTheme.palette.neutralTertiary,
  };

  const containerStyle: CSSProperties = {
    flexGrow: 1,
    backgroundColor: mainTheme.palette.neutralLight
  };

  if (!windowWidth.isSmall) {
    pageStyle.padding = `${mainTheme.spacing.l1} ${mainTheme.spacing.l2}`;
    containerStyle.boxShadow = mainTheme.effects.elevation16;
  }

  function onInsurancesChanged(newInsurances: VehicleInsuranceDetails[]) {
    setInsurances(newInsurances);

    const newAmountOfVehicles = newInsurances.reduce(
      (prevValue, currentValue) => {
        return prevValue + currentValue.amount;
      },
      0
    );

    if (newAmountOfVehicles !== amountOfInsuredVehicles) {
      setAmountOfInsuredVehicles(newAmountOfVehicles);
      if (additionalServices && masterData) {
        setAdditionalServices(
          new AdditionalServicesDetails(
            v4(),
            additionalServices.amountOfEmployees,
            additionalServices.participationAmount,
            newAmountOfVehicles,
            additionalServices.businessLiabilityInsurance.isUsed,
            additionalServices.trafficLegalProtection.isUsed,
            additionalServices.driverLegalProtection.isUsed,
            additionalServices.groupAccidentInsurance.isUsed,
            additionalServices.trafficLiabilityInsurance.isUsed,
            masterData,
            mountly,
          )
        );
      }
    }
  }

  function onClientDetailsChanged(newClientDetails: ClientDetails) {
    setClientDetails(newClientDetails);
    setAreClientDetailsValid(newClientDetails.validate());
  }

  useEffect(() => {
    let shouldCancel = false;

    async function refreshMasterData() {
      const response = await axios.get<InsuranceMasterDataDto>(
        '/api/insuranceMasterData'
      );
      if (!shouldCancel) {
        const newMasterData: InsuranceMasterDataDto = {
          vehicleTypes: response.data.vehicleTypes,
          insuranceTypes: response.data.insuranceTypes,
          paymentMethods: response.data.paymentMethods.map((data) => {
            return ({
              value: `${data.id}`,
              label: `paymentMethods.${data.id}`,
            });
          }),
          businessLiabilityInsurancePrices: sortArrayAsc(
            response.data.businessLiabilityInsurancePrices,
            'amount'
          ),
          trafficLiabilityInsurancePrices: sortArrayAsc(
            response.data.trafficLiabilityInsurancePrices,
            'amount'
          ),
          trafficLegalProtectionPrices: sortArrayAsc(
            response.data.trafficLegalProtectionPrices,
            'amount'
          ),
          driverLegalProtectionPrices: sortArrayAsc(
            response.data.driverLegalProtectionPrices,
            'amount'
          ),
          groupAccidentInsurancePrices: sortArrayDesc(
            response.data.groupAccidentInsurancePrices,
            'amount'
          ),
          claimRedemptionsPerCar: response.data.claimRedemptionsPerCar,
          vatPercentage: response.data.vatPercentage,
        };
        setMasterData(newMasterData);
        onInsurancesChanged([createDefaultInsurance(newMasterData)]);
        setAdditionalServices(
          new AdditionalServicesDetails(
            v4(),
            1,
            {
              traffic: newMasterData.trafficLegalProtectionPrices[0].amount,
              driver: newMasterData.driverLegalProtectionPrices[0].amount,
            },
            0,
            false,
            false,
            false,
            false,
            false,
            newMasterData,
            mountly,
          )
        );
        setClientDetails(new ClientDetails('', '', '', '', '', '', '', '', '', undefined, '', {}, '', false, false));
      }
    }

    refreshMasterData();
    return () => {
      shouldCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!masterData || !insurances || !additionalServices || !clientDetails) {
    return (
      <Stack style={pageStyle}>
        <Stack
          style={containerStyle}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Spinner size={SpinnerSize.large} />
        </Stack>
      </Stack>
    );
  }

  const items: WizardItem[] = [
    {
      iconName: 'Car',
      hasError: amountOfInsuredVehicles > maxVehiclesAmount,
      header: t('wizardStepOne.stepTitle'),
      component: (
        <InsuredVehicles
          insurances={insurances}
          onChange={onInsurancesChanged}
          maxVehiclesAmount={maxVehiclesAmount}
          masterData={masterData}
        />
      ),
    },
    {
      iconName: 'CheckList',
      header: t('wizardStepTwo.stepTitle'),
      component: (
        <AdditionalServices
          services={additionalServices}
          onChange={(value) => setAdditionalServices(value)}
          masterData={masterData}
          amountOfVehicles={amountOfInsuredVehicles}
        />
      ),
    },
    {
      iconName: 'Contact',
      header: t('wizardStepThree.stepTitle'),
      submit: true,
      component: (
        <SubmitInsurances
          masterData={masterData}
          insurances={insurances}
          services={additionalServices}
          clientDetails={clientDetails}
          onChange={onClientDetailsChanged}
          vatPercentage={masterData.vatPercentage}
        />
      ),
    },
    {
      header: t('wizardStepFour.title'),
      component: (
        <Thanks createdDSPAccount={createdDSPAccount} insurances={insurances} />
      ),
    },
  ];

  return (
    <Stack style={pageStyle}>
      <Stack style={containerStyle}>
        <Wizard
          items={items}
          submitText={t('wizardStepOne.requestButton')}
          onSubmit={registerDSP}
          error={error}
          loading={loading}
          skipSubmit={Boolean(createdDSPAccount)}
          amountOfInsuredVehicles={amountOfInsuredVehicles}
          isSubmitEnabled={
            areClientDetailsValid && amountOfInsuredVehicles < maxVehiclesAmount
          }
        />
      </Stack>
    </Stack>
  );
}
