import React, { ReactElement, CSSProperties } from 'react';
import { Stack, Icon } from '@fluentui/react';
import { mainTheme } from '@/theme/themes';

interface Types {
  iconName: string;
  size: number;
  onClick: () => void;
  isActive: boolean;
  hasError: boolean;
}

export default function WizardNavItem(props: Types): ReactElement {
  const headerStyle: CSSProperties = {
    cursor: 'pointer',
    height: props.size,
    width: props.size,
    color: mainTheme.palette.black,
    backgroundColor: props.isActive
      ? mainTheme.palette.themePrimary
      : 'transparent',
    border: `3px solid ${mainTheme.palette.neutralTertiary}`,
    borderRadius: '50%',
  };

  if (props.isActive) {
    headerStyle.color = mainTheme.palette.white;
    headerStyle.backgroundColor = mainTheme.palette.themePrimary;
  } else if (props.hasError) {
    headerStyle.color = mainTheme.semanticColors.errorText;
    headerStyle.background = mainTheme.semanticColors.errorBackground;
  }

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      style={headerStyle}
      onClick={props.onClick}
    >
      <Icon iconName={props.iconName} style={{ fontSize: '18px' }} />
    </Stack>
  );
}
