import {
  validateEmail,
  validateNumericOnly,
  validateIban,
} from '@/utils/validations';

export class ClientDetails {
  constructor(
    readonly companyName: string,
    readonly amazonDspNumber: string,
    readonly phoneNumber: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly password: string,
    readonly confirmPassword: string,
    readonly street: string,
    readonly zipCode: number | undefined,
    readonly city: string,
    readonly paymentMethod: {
      label?: string;
      value?: string;
    },
    readonly iban: string,
    readonly agreed: boolean,
    readonly confirm: boolean,
  ) {}

  validate(): boolean {
    
    return (
      Boolean(
        this.companyName &&
        this.amazonDspNumber &&
        this.phoneNumber &&
        this.firstName &&
        this.lastName &&
        this.email && validateEmail(this.email) &&
        this.password === this.confirmPassword &&
        this.confirmPassword &&
        this.street &&
        this.zipCode && validateNumericOnly(this.zipCode?.toString()) &&
        this.city &&
        this.paymentMethod?.value &&
        (this.paymentMethod.value === 'true' ? this.iban && validateIban(this.iban) : true) &&
        this.agreed &&
        this.confirm
      )
    );
  }

  getUserData() {
    return {
      'email': this?.email,
      'password': this?.password,
    };
  }

  getData() {
    return {
      'companyInformation': {
        'companyName': this?.companyName,
        'amazonDSPNumber': this?.amazonDspNumber,
        'contactFirstName': this?.firstName,
        'contactLastName': this?.lastName,
        'street': this?.street,
        'zipCode': this?.zipCode,
        'city': this?.city,
        'paymentMethod': this?.paymentMethod,
        'iban': this?.iban,
      }
    };
  }
}
