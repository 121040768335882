import additionalService from './additionalService.json';
import wizardSteps from './wizardSteps.json';
import contact from './contact.json';
import contactView from './contactView.json';
import faq from './faq.json';
import footer from './footer.json';
import global from './global.json';
import header from './header.json';
import landing from './landing.json';
import login from './login.json';
import overview from './overview.json';
import overviewDamage from './overviewDamage.json';
import overviewDetail from './overviewDetail.json';
import password from './password.json';
import reportDamage from './reportDamage.json';
import reportDamageAdditionalProducts from './reportDamageAdditionalProducts.json';
import tableContent from './tableContent.json';
import toasts from './toasts.json';
import validation from './validation.json';
import viewSettingsPage from './viewSettingsPage.json';


export default {
  additionalService,
  contact,
  contactView,
  faq,
  footer,
  global,
  header,
  landing,
  login,
  overview,
  overviewDamage,
  overviewDetail,
  password,
  reportDamage,
  reportDamageAdditionalProducts,
  tableContent,
  validation,
  toasts,
  viewSettingsPage,
  ...wizardSteps,
};
