import React, { CSSProperties } from 'react';

import RoutedContent from '@/routes';
import Header from '@/components/base/Header';
import Footer from '@/components/base/Footer';
import { WindowWidthContext } from '@/contexts';
import { useWindowWidth } from '@/hooks';
import { mainTheme } from '@/theme/themes';
import WindowWidthInfo from '@/utils/services/windowWidthInfo';
import { Stack } from '@fluentui/react';

export default function AppShell(): JSX.Element {
  const currentWindowWidth = useWindowWidth();

  const containerStyle: CSSProperties = {
    width: currentWindowWidth.isLarge
      ? WindowWidthInfo.LargeBreakpoint
      : '100%',
    backgroundColor: mainTheme.palette.neutralTertiary,
  };

  return (
    <WindowWidthContext.Provider value={currentWindowWidth}>
      <Stack style={containerStyle}>
        <Header />
        <RoutedContent />
        <Footer />
      </Stack>
    </WindowWidthContext.Provider>
  );
}
