import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import { useMediaQuery } from '@/hooks';
import { formatCurrency } from '@/utils/formating';
import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { useAuth } from '@/contexts/auth';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import { UploadFileDialog } from '@/components/base/UploadFileDialog';
import { Warning } from '@/components/base/Warning';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import { Card } from './Card';
import { PdfList } from './PdfList';

import './index.scss';

export default function OverviewPage(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { authorized, userData, token } = useAuth();
  const history = useHistory();
  const { isMobile, isTablet } = useMediaQuery();
  const [isHiddenUploadDialog, setHiddenUploadDialog] = useState(true);

  const nameId = location.search.replace('?', '');

  const { loading, data, makeRequest } = useFetch({
    endpoint: userData?.role === 'DSP' ? `/api/dspusers/${userData?.nameid}` : `/api/dspusers/${nameId}`,
    skip: !userData?.nameid || !authorized,
    method: FetchMethodsEnum.GET,
  });

  const downloadPDF = (name, filename) => async () => {
    const link = document.createElement('a');

    link.setAttribute('download', `${filename} Schadenanzeige.pdf`);
    link.setAttribute('target', '_blank');

    const response = await axios.get(userData?.role === 'DSP' ? `/api/dspusers/${userData?.nameid}/additionalProductDamageReport/${name}` : `/api/dspusers/${nameId}/additionalProductDamageReport/${name}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const blob = new Blob([response.data]);
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;

    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  if (!authorized) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Stack
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          marginBottom: 30
        }}
      >
        {loading ? (
          <SpinnerDefault />
        ) : (
          <>
            <div className='overviewTitleContainer'>
              <Text className="overviewPageTitle" style={{ fontSize: isMobile ? 16 : 24 }}>
                {t(data?.insuranceOffer?.paymentMethod === 'monthly' ? 'overview.title' : 'overview.titleYear')}
              </Text>
              {userData?.role === 'DSP' && (
                <PrimaryButton
                  className="overviewDetailButtonStyle"
                  style={{ marginRight: 20 }}
                  text={t('overview.overviewDamage')}
                  onClick={() => history.push('/overview-damage-dsp')}
                />
              )}
              {userData?.role === 'Advisor' && (
                <PrimaryButton
                  className="overviewDetailButtonStyle"
                  style={{ marginRight: 20 }}
                  text={t('overview.allDSPs')}
                  onClick={() => history.push('/landing-advisor')}
                />
              )}
            </div>
            {userData?.role === 'Advisor' && (
              <Text className="subTitleOverview">
                {t('overview.companyNameSubTitle')}
                {data?.insuranceOffer?.companyInformation?.companyName}
              </Text>
            )}
            {!data?.isSepaUploaded && data?.insuranceOffer?.companyInformation?.iban && (
              <div className="overviewWarning">
                <Warning deffault={false}>{t('overview.warning')}</Warning>
                <PrimaryCallToActionButton
                  className="overviewWarningButton"
                  text={t('global.upload')}
                  onClick={() => setHiddenUploadDialog(false)}
                />
              </div>
            )}
            <div style={{ margin: '20px auto 20px 15px', width: '100%' }}>
              <PrimaryButton
                disabled={
                  data?.insuranceOffer?.additionalProducts?.trafficLegalProtection &&
                  data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance &&
                  data?.insuranceOffer?.additionalProducts?.groupAccidentInsurance &&
                  data?.insuranceOffer?.additionalProducts?.trafficLiablilityInsurance
                }
                text={t('overview.addAdditionalInsurance')}
                onClick={() => history.push(`/additional-insurance?${nameId}`)}
              />
            </div>
            <div className="overviewWrapper">
              <div className="overviewPageContainer" style={{ maxWidth: isMobile ? 350 : '60%', maxHeight: isMobile || isTablet ? '100%' : '75%' }}>
                <Card
                  title={t('overview.motorVehicleInsurances')}
                  actionLabel={t('overview.detailsButton')}
                  data={data}
                  showButton={true}
                  onAction={() => {
                    if (userData?.role === 'DSP') {
                      history.push('/overview-detail');
                    } else {
                      history.push(`/overview-detail/${nameId}`);
                    }
                  }}
                  options={[
                    {
                      key: t('overview.numberOfVehicles'),
                      value: data?.insuranceOffer?.vehicleInsurances?.reduce(
                        (acc, veh) => acc + veh.numberOfVehicles, 0
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: data?.insuranceOffer?.paymentMethod === 'monthly' ? (
                        formatCurrency(
                          data?.insuranceOffer?.vehicleInsurances?.reduce(
                            (acc, veh) => acc + veh.netTotal, 0),
                        )) : (
                        formatCurrency(
                          data?.insuranceOffer?.vehicleInsurances?.reduce(
                            (acc, veh) => acc + veh.netTotal, 0
                          ),
                        ))
                    }
                  ]}
                />
                {/* <Card
                  hide={!data?.insuranceOffer?.additionalProducts?.driverLegalProtection?.netTotal}
                  title={t('overview.driversLegalExpensesInsurance')}
                  actionLabel={t('overview.reportDamageButton')}
                  type='driverLegalProtection'
                  onAction={downloadPDF('DriverLegalProtection', 'Fahrerechtsschutzversicherung')}
                  options={[
                    {
                      key: t('overview.deductible'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              driverLegalProtection?.deductible
                      ),
                    },
                    {
                      key: t('overview.numberOfVehicles'),
                      value:
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              driverLegalProtection?.numberOfVehicles
                    },
                    {
                      key: t('overview.premiumPerVehicle'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              driverLegalProtection?.pricePerVehicle
                              * 1.19
                              * data?.
                                  insuranceOffer?.
                                    additionalProducts?.
                                      driverLegalProtection?.numberOfVehicles
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.driverLegalProtection?.netTotal * 1.19
                      ),
                    }
                  ]}
                /> */}
                <Card
                  hide={!data?.insuranceOffer?.additionalProducts?.trafficLegalProtection?.netTotal}
                  id={data?.insuranceOffer?.additionalProducts?.trafficLegalProtection?.insuranceId}
                  userId={userData?.role === 'DSP' ? userData?.nameid : nameId}
                  title={t('overview.trafficLegalProtectionInsurance')}
                  type='trafficLegalProtectionInsurance'
                  actionLabel={t('overview.reportDamageButton')}
                  onAction={downloadPDF('TrafficLegalProtection', 'Verkehrsrechtsschutzversicherung')}
                  data={data}
                  options={[
                    {
                      key: t('overview.deductible'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLegalProtection?.deductible
                      ),
                    },
                    {
                      key: t('overview.numberOfVehicles'),
                      value:
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLegalProtection?.numberOfVehicles
                    },
                    {
                      key: t('overview.premiumPerVehicle'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLegalProtection?.pricePerVehicle * 1.19
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLegalProtection?.numberOfVehicles
                        * data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLegalProtection?.pricePerVehicle
                        * 1.19
                      ),
                    }
                  ]}
                />
                <Card
                  hide={!data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance?.netTotal}
                  id={data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance?.insuranceId}
                  userId={userData?.role === 'DSP' ? userData?.nameid : nameId}
                  title={t('overview.businessLiabilityInsurance​')}
                  type='businessLiabilityInsurance'
                  actionLabel={t('overview.reportDamageButton')}
                  onAction={downloadPDF('BusinessLiabilityInsurance', 'Betriebshaftpflichtversicherung')}
                  data={data}
                  options={[
                    {
                      key: t('overview.numberOfEmployees'),
                      value:
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              businessLiabilityInsurance?.numberOfEmployers
                    },
                    {
                      key: t('overview.mitarbeiterPremiumPerVehicle'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              businessLiabilityInsurance?.pricePerEmployer * 1.19
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              businessLiabilityInsurance?.numberOfEmployers
                        * data?.
                          insuranceOffer?.
                            additionalProducts?.
                              businessLiabilityInsurance?.pricePerEmployer
                        * 1.19
                      ),
                    }
                  ]}
                />
                <Card
                  hide={!data?.
                    insuranceOffer?.
                      additionalProducts?.groupAccidentInsurance?.netTotal}
                  id={data?.insuranceOffer?.additionalProducts?.groupAccidentInsurance?.insuranceId}
                  userId={userData?.role === 'DSP' ? userData?.nameid : nameId}
                  title={t('overview.accidentInsurance​')}
                  type='groupAccidentInsurance'
                  actionLabel={t('overview.reportDamageButton')}
                  onAction={downloadPDF('GroupAccidentInsurance', 'Unfallversicherung')}
                  data={data}
                  options={[
                    {
                      key: t('overview.numberOfEmployees'),
                      value:
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              groupAccidentInsurance?.numberOfEmployers,
                    },
                    {
                      key: t('overview.mitarbeiterPremiumPerVehicle'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              groupAccidentInsurance?.pricePerEmployer * 1.19
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              groupAccidentInsurance?.numberOfEmployers
                              * data?.
                                insuranceOffer?.
                                  additionalProducts?.
                                    groupAccidentInsurance?.pricePerEmployer
                              * 1.19
                      ),
                    }
                  ]}
                />
                <Card
                  hide={!data?.
                    insuranceOffer?.
                      additionalProducts?.
                        trafficLiablilityInsurance?.netTotal}
                  id={data?.insuranceOffer?.additionalProducts?.trafficLiablilityInsurance?.insuranceId}
                  userId={userData?.role === 'DSP' ? userData?.nameid : nameId}
                  title={t('overview.trafficLiabilityInsurance')}
                  actionLabel={t('overview.reportDamageButton')}
                  type='trafficLiablilityInsurance'
                  onAction={downloadPDF('TrafficLiablilityInsurance', 'Verkehrshaftpflichtversicherung')}
                  data={data}
                  options={[
                    {
                      key: t('overview.deductible'),
                      value:
                        formatCurrency(150) || data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLiablilityInsurance?.deductible,
                    },
                    {
                      key: t('overview.numberOfVehicles'),
                      value:
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLiablilityInsurance?.numberOfVehicles,
                    },
                    {
                      key: t('overview.premiumPerVehicle'),
                      value: formatCurrency(
                        data?.
                          insuranceOffer?.
                            additionalProducts?.
                              trafficLiablilityInsurance?.pricePerVehicle * 1.19
                      ),
                    },
                    {
                      key: t('overview.totalPremium'),
                      value: formatCurrency(
                        data?.
                        insuranceOffer?.
                          additionalProducts?.
                            trafficLiablilityInsurance?.numberOfVehicles
                            * data?.
                              insuranceOffer?.
                                additionalProducts?.
                                  trafficLiablilityInsurance?.pricePerVehicle
                            * 1.19
                      ),
                    }
                  ]}
                />
              </div>
              <>
                <PdfList />
              </>
            </div>
          </>
        )}
        <UploadFileDialog
          userId={userData?.role === 'DSP' ? userData?.nameid : nameId}
          hidden={isHiddenUploadDialog}
          uploadFile={false}
          onDismiss={() => {
            setHiddenUploadDialog(true);
            makeRequest();
          }}
        />
      </Stack>
    </>
  );
}
