/* eslint-disable radix */
import React, { CSSProperties, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,IStackProps,
  ChoiceGroup,SpinButton,
  IChoiceGroupOption,Text,
} from '@fluentui/react';
import { mainTheme } from '@/theme/themes';
import { InsuranceMasterDataDto } from '@/components/pages/Wizard/insuranceMasterDataDto';
import ServiceOption from '@/utils/services/AdditionalServices/serviceOption';
import { useWindowWidthContext } from '@/hooks';
import { v4 } from 'uuid';
import { formatCurrency } from '@/utils/formating';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import { AdditionalServicesDetails } from './additionalServicesDetails';
import { useCalculation } from '@/contexts/calculation';
import { Label } from 'office-ui-fabric-react';

interface Types {
  services: AdditionalServicesDetails;
  onChange: (details: AdditionalServicesDetails) => void;
  masterData: InsuranceMasterDataDto;
  amountOfVehicles: number;
}

export default function AdditionalServices(props: Types) {
  const {
    services,
    onChange,
    masterData,
    amountOfVehicles,
  } = props;

  const { t } = useTranslation();
  const { mountly, paymentMethod } = useCalculation();

  const windowWidth = useWindowWidthContext();

  const employeeAmountInputContainer: CSSProperties = {
    width: '200px',
  };

  const rootStack: IStackProps = {
    verticalAlign: 'center',
  };

  const contentWrapperStack: IStackProps = {
    horizontal: true,
    verticalAlign: 'center',
    style: {
      backgroundColor: mainTheme.palette.neutralLight,
    },
  };

  const servicesGridContainer: CSSProperties = {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
  };

  const serviceItemStyle: CSSProperties = {
    margin: '15px 15px 0 0',
    padding: mainTheme.spacing.m,
    backgroundColor: mainTheme.palette.white,
    boxShadow: mainTheme.effects.elevation8,
    width: '350px'
  };

  const paymentItemStyle: CSSProperties = {
    margin: '0 15px 0 0',
    width: '350px',
    textAlign: 'center'
  };

  const headerStyle: CSSProperties = {
    ...paymentItemStyle,
    marginBottom: 8,
  }

  const summaryStack: IStackProps = {
    horizontal: true,
    horizontalAlign: 'center',
    style: {
      flexGrow: 1,
    },
  };

  const costLabelStyle: CSSProperties = {
    paddingRight: mainTheme.spacing.l1,
  };

  const priceStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  if (windowWidth.windowWidth < 1100) {
    contentWrapperStack.horizontal = false;
    contentWrapperStack.horizontalAlign = 'center';

    servicesGridContainer.gridTemplateColumns = 'auto';
    serviceItemStyle.margin = mainTheme.spacing.m;
    paymentItemStyle.margin = mainTheme.spacing.m;

    summaryStack.style = {
      ...summaryStack.style,
      paddingTop: mainTheme.spacing.l2,
    };
  }

  useEffect(() => {
    const newAdditionalServices = new AdditionalServicesDetails(
      v4(),
      services.amountOfEmployees,
      services.participationAmount,
      services.amountOfVehicles,
      services.businessLiabilityInsurance.isUsed,
      services.trafficLegalProtection.isUsed,
      services.driverLegalProtection.isUsed,
      services.groupAccidentInsurance.isUsed,
      services.trafficLiabilityInsurance.isUsed,
      masterData,
      mountly,
    );

    onChange(newAdditionalServices);
  }, [mountly])

  function onServiceChanged(servicePropertyName: string, checked: boolean) {
    const newAdditionalServices = new AdditionalServicesDetails(
      v4(),
      services.amountOfEmployees,
      services.participationAmount,
      amountOfVehicles,
      servicePropertyName === 'useBusinessLiabilityInsurance'
        ? checked
        : services.businessLiabilityInsurance.isUsed,
      servicePropertyName === 'useTrafficLegalProtection'
        ? checked
        : services.trafficLegalProtection.isUsed,
      servicePropertyName === 'useDriverLegalProtection'
        ? checked
        : services.driverLegalProtection.isUsed,
      servicePropertyName === 'useGroupAccidentInsurance'
        ? checked
        : services.groupAccidentInsurance.isUsed,
      servicePropertyName === 'useTrafficLiabilityInsurance'
        ? checked
        : services.trafficLiabilityInsurance.isUsed,
      masterData,
      mountly,
    );

    onChange(newAdditionalServices);
  }

  const onValidateEmployeeAmount = (key: string) => (value: string): string => {
    const parsedNumber: number = Number.parseInt(value);
    const newAmount =
      Number.isNaN(parsedNumber) || parsedNumber < 1
        ? 1
        : parsedNumber > 75
          ? 75
          : parsedNumber;

    const newAdditionalServices = new AdditionalServicesDetails(
      v4(),
      newAmount,
      services.participationAmount,
      amountOfVehicles,
      services.businessLiabilityInsurance.isUsed,
      services.trafficLegalProtection.isUsed,
      services.driverLegalProtection.isUsed,
      services.groupAccidentInsurance.isUsed,
      services.trafficLiabilityInsurance.isUsed,
      masterData,
      mountly,
    );

    onChange(newAdditionalServices);
    return newAmount.toString();
  };

  function onTrafficLegalAmountParticipationChanged(
    option: IChoiceGroupOption
  ): void {
    const parsedNewParticipation: number = Number.parseInt(option.key);
    const newAdditionalServices = new AdditionalServicesDetails(
      v4(),
      services.amountOfEmployees,
      {
        traffic: parsedNewParticipation,
        driver: services.participationAmount.driver,
      },
      amountOfVehicles,
      services.businessLiabilityInsurance.isUsed,
      services.trafficLegalProtection.isUsed,
      services.driverLegalProtection.isUsed,
      services.groupAccidentInsurance.isUsed,
      services.trafficLiabilityInsurance.isUsed,
      masterData,
      mountly,
    );

    onChange(newAdditionalServices);
  }

  function onDriverLegalProtectionChanged(
    option: IChoiceGroupOption
  ): void {
    const parsedNewParticipation: number = Number.parseInt(option.key);
    const newAdditionalServices = new AdditionalServicesDetails(
      v4(),
      services.amountOfEmployees,
      {
        traffic: services.participationAmount.traffic,
        driver: parsedNewParticipation,
      },
      amountOfVehicles,
      services.businessLiabilityInsurance.isUsed,
      services.trafficLegalProtection.isUsed,
      services.driverLegalProtection.isUsed,
      services.groupAccidentInsurance.isUsed,
      services.trafficLiabilityInsurance.isUsed,
      masterData,
      mountly,
    );

    onChange(newAdditionalServices);

  }

  const trafficLegalAmountParticipationOptions: IChoiceGroupOption[] = 
    masterData.trafficLegalProtectionPrices.map(
      (priceTier) => {
        const option: IChoiceGroupOption = {
          key: priceTier.amount.toString(),
          text: `${priceTier.amount.toString()} € Selbstbeteiligung`,
        };
        return option;
      }
    );

  const driverLegalProtectionOp: any[] = 
    masterData.trafficLegalProtectionPrices.map(
      (priceTier) => {
        const option: any = {
          key: priceTier.amount.toString(),
          text: `${priceTier.amount.toString()} € Selbstbeteiligung`,
        };
        return option;
      }
    );

  const paymentMethods = props.masterData?.paymentMethods;


  return (
    <Stack {...rootStack}>
      <Stack {...contentWrapperStack}>
        <div style={servicesGridContainer}>
          <Stack style={headerStyle}>
            <ThemedSelect
              label={t('wizardStepOne.paymentMethod')}
              options={paymentMethods}
              value={paymentMethod.value}
              onChange={(data: any) => paymentMethod.set(data)}
            />
          </Stack>
          <Stack style={paymentItemStyle} />

          <div> 
            <h3 style={{ textAlign: 'center', paddingRight: 8 }}>{t('wizardStepTwo.tab1.label')}</h3>
            <Stack style={serviceItemStyle}>
              <ServiceOption
                label={t('additionalService.publicLiabilityInsurance.label')}
                isChecked={services.businessLiabilityInsurance.isUsed}
                onChange={(isChecked: boolean) =>
                  onServiceChanged('useBusinessLiabilityInsurance', isChecked)
                }
                priceAmountOptions={{
                  options: masterData.businessLiabilityInsurancePrices,
                  optionLabelTemplate: 'additionalService.publicLiabilityInsurance.template',
                }}
              />
              {services.businessLiabilityInsurance.isUsed && (
                <>
                  <div style={employeeAmountInputContainer}>
                    <SpinButton
                      label={t('additionalService.numberOfEmployees')}
                      value={
                        services.amountOfEmployees.toString()
                      }
                      onValidate={onValidateEmployeeAmount('publicLiabilityInsurance')}
                      onIncrement={() =>
                        onValidateEmployeeAmount('publicLiabilityInsurance')(
                          (
                            services.amountOfEmployees + 1
                          ).toString()
                        )
                      }
                      onDecrement={() =>
                        onValidateEmployeeAmount('publicLiabilityInsurance')(
                          (
                            services.amountOfEmployees - 1
                          ).toString()
                        )
                      }
                    />
                  </div>
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.businessLiabilityInsurance.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.businessLiabilityInsurance.price / 100 * services.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                        {t(`additionalService.annualNetContribution${!mountly ? 'Annual' : 'Monthly'}`)}
                      </td>
                        <td style={{ ...priceStyle , fontWeight: 'bold'}}>
                          {
                            formatCurrency(
                              services.businessLiabilityInsurance.price + (services.businessLiabilityInsurance.price / 100 * services.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
                </>
              )}
            </Stack>
            <Stack style={{ ...serviceItemStyle, minHeight: 112 }}>
              <ServiceOption
                label={t('additionalService.trafficLiabilityInsurance.label')}
                isChecked={services.trafficLiabilityInsurance.isUsed}
                onChange={(isChecked: boolean) =>
                  onServiceChanged('useTrafficLiabilityInsurance', isChecked)
                }
                priceAmountOptions={{
                  options: masterData.trafficLiabilityInsurancePrices,
                  optionLabelTemplate: 'additionalService.trafficLiabilityInsurance.template',
                }}
                subHeaderRows={[
                  t('additionalService.current', {
                    count: amountOfVehicles,
                    name: t('global.vehicle', {
                      count: amountOfVehicles,
                    }),
                  }),
                ]}
              />
              {services.trafficLiabilityInsurance.isUsed && (
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.trafficLiabilityInsurance.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.trafficLiabilityInsurance.price / 100 * services.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                      {t(`additionalService.annualNetContribution${
                        !mountly ? 'Annual' : 'Monthly'
                      }`)}
                      </td>
                        <td style={{ ...priceStyle , fontWeight: 'bold'}}>
                          {
                            formatCurrency(
                              services.trafficLiabilityInsurance.price + (services.trafficLiabilityInsurance.price / 100 * services.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
              )}
            </Stack>          
          </div>

          <div>
            <h3  style={{ textAlign: 'center' }}>{t('wizardStepTwo.tab2.label')}</h3>
            <Stack style={serviceItemStyle}>
              <ServiceOption
                label={t('additionalService.trafficLegalProtection.label')}
                isChecked={services.trafficLegalProtection.isUsed}
                onChange={(isChecked: boolean) =>
                  onServiceChanged('useTrafficLegalProtection', isChecked)
                }
                priceAmountOptions={{
                  options: masterData.trafficLegalProtectionPrices,
                  optionLabelTemplate: 'additionalService.trafficLegalProtection.template',
                }}
                subHeaderRows={[
                  t('additionalService.current', {
                    count: amountOfVehicles,
                    name: t('global.vehicle', {
                      count: amountOfVehicles,
                    }),
                  }),
                ]}
              />
              {services.trafficLegalProtection.isUsed && (
                <>
                  <ChoiceGroup
                    options={trafficLegalAmountParticipationOptions}
                    value={services.participationAmount.traffic.toString()}
                    defaultSelectedKey={
                      services.participationAmount.traffic.toString()
                    }
                    onChange={(_: any, option: IChoiceGroupOption | undefined) =>
                      onTrafficLegalAmountParticipationChanged(option!)
                    }
                  />
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.trafficLegalProtection.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(services.trafficLegalProtection.price / 100 * services.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                      {t(`additionalService.annualNetContribution${
                        !mountly ? 'Annual' : 'Monthly'
                      }`)}
                      </td>
                        <td style={{ ...priceStyle , fontWeight: 'bold'}}>
                          {
                            formatCurrency(
                              services.trafficLegalProtection.price + (services.trafficLegalProtection.price / 100 * services.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
                </>
              )}
            </Stack>                                  
            <Stack style={serviceItemStyle}>
                <ServiceOption
                  label={t('additionalService.groupAccidentInsurance.label')}
                  isChecked={services.groupAccidentInsurance.isUsed}
                  onChange={(isChecked: boolean) =>
                    onServiceChanged('useGroupAccidentInsurance', isChecked)
                  }
                  priceAmountOptions={{
                    options: masterData.groupAccidentInsurancePrices.map(
                      (item, position) => ({
                        onlyLabel: position !== masterData.groupAccidentInsurancePrices.length - 1,
                        ...item,
                      })),
                    optionLabelTemplate: 'additionalService.groupAccidentInsurance.template',
                  }}
                />
                {services.groupAccidentInsurance.isUsed && (
                  <>
                    <div style={employeeAmountInputContainer}>
                      <SpinButton
                        label={t('additionalService.numberOfEmployees')}
                        value={
                          services.amountOfEmployees.toString()
                        }
                        onValidate={onValidateEmployeeAmount('accidentInsurance')}
                        onIncrement={() =>
                          onValidateEmployeeAmount('accidentInsurance')(
                            (
                              services.amountOfEmployees + 1
                            ).toString(),
                          )
                        }
                        onDecrement={() =>
                          onValidateEmployeeAmount('accidentInsurance')(
                            (
                              services.amountOfEmployees - 1
                            ).toString(),
                          )
                        }
                      />
                    </div>
                    <>
                      <table style={{ marginTop: 10 }}>
                        <tr>
                          <td style={{ ...costLabelStyle }}>
                            {t('wizardStepOne.totalPremium')}
                          </td>
                          <td style={{ ...priceStyle }}>
                            {formatCurrency(services.groupAccidentInsurance.price)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ ...costLabelStyle }}>
                            {t('wizardStepTwo.taxes')}
                          </td>
                          <td style={{ ...priceStyle }}>
                            {formatCurrency(services.groupAccidentInsurance.price / 100 * services.vatPercentage)}
                          </td>
                        </tr>
                        <tr>
                        <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                          {t(`additionalService.annualNetContribution${!mountly ? 'Annual' : 'Monthly'}`)}
                        </td>
                          <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                            {
                              formatCurrency(
                                services.groupAccidentInsurance.price + (services.groupAccidentInsurance.price / 100 * services.vatPercentage)
                              )
                            }
                          </td>
                        </tr>
                      </table>
                    </>
                  </>
                )}
              </Stack>
          </div>
 
        </div>

        {services.costsOfServices > 0 && (
          <Stack {...summaryStack}>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ paddingBottom: '16px' }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{t('global.summary')}</Text>
                  </td>
                </tr>
                {services.businessLiabilityInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>
                      {t('additionalService.publicLiabilityInsurance.label')}
                    </td>
                    <td style={priceStyle}>
                      {formatCurrency(
                        services.businessLiabilityInsurance.price
                      )}
                    </td>
                  </tr>
                )}
                {services.driverLegalProtection.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.driverLegalProtection.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(services.driverLegalProtection.price)}
                    </td>
                  </tr>
                )}
                {services.trafficLegalProtection.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.trafficLegalProtection.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(services.trafficLegalProtection.price)}
                    </td>
                  </tr>
                )}
                {services.trafficLiabilityInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.trafficLiabilityInsurance.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(services.trafficLiabilityInsurance.price)}
                    </td>
                  </tr>
                )}
                {services.groupAccidentInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.groupAccidentInsurance.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(services.groupAccidentInsurance.price)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ borderTop: '1px solid black' }} />
                  <td style={{ borderTop: '1px solid black' }} />
                </tr>
                  <tr>
                    <td style={{ ...costLabelStyle }}>
                      {t('wizardStepOne.totalPremium')}
                    </td>
                    <td style={{ ...priceStyle }}>
                      {formatCurrency(services.costsOfServices)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...costLabelStyle }}>
                      {t('wizardStepTwo.taxes')}
                    </td>
                    <td style={{ ...priceStyle }}>
                      {formatCurrency(services.costsOfServices / 100 * services.vatPercentage)}
                    </td>
                  </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }} />
                  <td style={{ borderTop: '1px solid black' }} />
                </tr>
                {!mountly ? (
                  <tr>
                    <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>{t('additionalService.grossPremium')}</td>
                    <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                      {formatCurrency(services.costsOfServices + (services.costsOfServices / 100 * services.vatPercentage))}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                      {t('additionalService.grossPremiumMonthly')}
                    </td>
                    <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                      {formatCurrency(services.costsOfServices + (services.costsOfServices / 100 * services.vatPercentage))}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingTop: 20 }}>
                    {t('additionalService.informationText')}
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: 7 }}>
                    {t('additionalService.taxesInformationText')}
                  </td>
                </tr>
              </tbody>
            </table>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
