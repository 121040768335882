import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Stack, PrimaryButton, ActionButton } from '@fluentui/react';
import { mainTheme, alternateTheme } from '@/theme/themes';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { ExpandPanel } from '@/components/base/ExpandPanel';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';

import './index.scss';

export default function FAQPage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  
  
  const { data, loading } = useFetch({
    endpoint: '/api/faqs',
    method: FetchMethodsEnum.GET,
  });
  
  const [questions, setQuestions] = useState<any>([]);

  useEffect(() => {
    setQuestions(data);
  }, [data]);

  return (
    <Stack className='faqPage' style={{ backgroundColor: mainTheme.semanticColors.bodyBackground }}>
      <DndProvider backend={HTML5Backend}>
        {loading ? <SpinnerDefault /> : (
          <>
            <div className="faqButtonsContainer">
              <div className="partOne">
                <PrimaryButton
                  className='backButtonContainer'
                  onClick={() => history.goBack()}
                >
                  <ActionButton iconProps={{ iconName: 'ChromeBack', style: {
                    fontSize: '15px',
                    color: 'white'
                  } }}
                  />
                  <div className='textButton'>{t('faq.backButton')}</div>
                </PrimaryButton>
              </div>
            </div>
            <div className="faqButtonsContainer">
              <h2 
                className='title'
                style={{ color: alternateTheme.palette.themeDark }}
              >
                {t('faq.title')}
              </h2>
            </div>
            {(questions || []).map((question) => (
              <div key={question.id}>
                <ExpandPanel id={question.id} {...question} />
              </div>
            ))}
          </>
        )}
      </DndProvider>
    </Stack>
  );
}
