import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogFooter,
  DialogContent,
  PrimaryButton,
  Dialog
} from '@fluentui/react';
import { useAuth } from '@/contexts/auth';

import './index.scss';

export function ConfirmDialog({
  hidden,
  onDismiss,
  onDelete,
  user,
  contentText,
  confirmSave,
  unsavedChanges,
}: {
  hidden: boolean;
  onDismiss: (isOk: boolean) => void;
  onDelete?: (needUPD: boolean) => void;
  contentText?: string;
  confirmSave?: boolean;
  user?: any;
  unsavedChanges?: boolean;
}) {

  const { t } = useTranslation();
  const { userData } = useAuth();

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => onDismiss(false)}
      minWidth="450px"
      dialogContentProps={{
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      {!confirmSave && (
        <div className="accountHeaderContainer">
          <div className="accountTitle">{userData?.role === 'Advisor' ? t('tableContent.vn') : t('tableContent.account')}</div>
          <div className="accountEmail">{userData?.role === 'Advisor' ? user?.insuranceOffer?.companyInformation?.companyName : user?.email}</div>
        </div>
      )}
      <DialogContent className='contentText'>{contentText}</DialogContent>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        }
      }}
      >
        {unsavedChanges ? (
          <>
            <PrimaryButton style={{ margin: 10 }} onClick={() => onDismiss(true)} text={t('global.yes')} />
            <PrimaryButton style={{ margin: 10 }} onClick={() => onDismiss(false)} text={t('global.no')} />
          </>
        ) : (
          <>
            <PrimaryButton style={{ margin: 10 }} onClick={() => onDismiss(false)} text={t('global.cancel')} />
            <PrimaryButton style={{ margin: 10 }} onClick={() => onDelete!(true)} text={t('global.delete')} />
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
}
