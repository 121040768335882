/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { mainTheme, alternateTheme } from '@/theme/themes';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';

import { Stack, PrimaryButton, TextField, ActionButton } from '@fluentui/react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { validateEmails, getEmailError } from '@/utils/validations';

import './index.scss';
import useDebounce from '../OverviewDamageAdvisor/useDebaunce';

export default function ViewSettingPage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [emailsData, setEmailsData] = useState({} as any);

  const notifySucces = () => toast.success(t('toasts.saveEmailSettingsSuccess'));
  const notifyError = () => toast.error(t('toasts.saveEmailSettingsError'));

  const { control, errors, reset, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      completeWizardEmails: emailsData?.completeWizardEmails?.value || [],
      contactFormEmails: emailsData?.contactFormEmails || [],
      deregistrationVehicleEmails: emailsData?.deregistrationVehicleEmails || [],
      completeInsuranceDataEmails: emailsData?.completeInsuranceDataEmails || [],
      escalationEmails: emailsData?.escalationEmails || [],
      sepaMandatEscalationEmails: emailsData?.sepaMandatEscalationEmails || [],
      sepaMandatUploadedEmails: emailsData?.sepaMandatUploadedEmails || [],
      completeInsuranceDataExternEmails: emailsData?.completeInsuranceDataExternEmails || [],
      completeWizardExternEmails: emailsData?.completeWizardExternEmails || [],
      additionalProductReportRecipientEmails: emailsData?.additionalProductReportRecipientEmails || [],
      reportRecipientEmails: emailsData?.reportRecipientEmails || [],
      senderSettingsEmail: emailsData?.senderSettings?.email || '',
      senderSettingsName: emailsData?.senderSettings?.name || '',
      replyToEmail: emailsData?.replyToEmail || '',
      timestamp: 0,
    }
  } as any);

  const {
    fields: escalationEmails,
    append: appendEscalationEmails,
    remove: removeEscalationEmails,
  } = useFieldArray({
    control,
    name: 'escalationEmails',
  });

  const {
    fields: contactFormEmails,
    append: appendContactFormEmails,
    remove: removeContactFormEmails
  } = useFieldArray({
    control,
    name: 'contactFormEmails'
  });

  const {
    fields: deregistrationVehicleEmails,
    append: appendDeregistrationVehicleEmails,
    remove: removeDeregistrationVehicleEmails,
  } = useFieldArray({
    control,
    name: 'deregistrationVehicleEmails'
  });

  const {
    fields: completeWizardEmails,
    append: appendCompleteWizardEmails,
    remove: removeCompleteWizardEmails
  } = useFieldArray({
    control,
    name: 'completeWizardEmails'
  });

  const {
    fields: completeInsuranceDataEmails,
    append: appendCompleteInsuranceDataEmails,
    remove: removeCompleteInsuranceDataEmails
  } = useFieldArray({
    control,
    name: 'completeInsuranceDataEmails'
  });

  const {
    fields: sepaMandatEscalationEmails,
    append: appendSepaMandatEscalationEmails,
    remove: removeSepaMandatEscalationEmails
  } = useFieldArray({
    control,
    name: 'sepaMandatEscalationEmails'
  });

  const {
    fields: sepaMandatUploadedEmails,
    append: appendSepaMandatUploadedEmails,
    remove: removeSepaMandatUploadedEmails
  } = useFieldArray({
    control,
    name: 'sepaMandatUploadedEmails'
  });

  const {
    fields: completeInsuranceDataExternEmails,
    append: appendCompleteInsuranceDataExternEmails,
    remove: removeCompleteInsuranceDataExternEmails
  } = useFieldArray({
    control,
    name: 'completeInsuranceDataExternEmails'
  });

  const {
    fields: completeWizardExternEmails,
    append: appendCompleteWizardExternEmails,
    remove: removeCompleteWizardExternEmails
  } = useFieldArray({
    control,
    name: 'completeWizardExternEmails'
  });

  const {
    fields: reportRecipientEmails,
    append: appendReportRecipientEmails,
    remove: removeReportRecipientEmails
  } = useFieldArray({
    control,
    name: 'reportRecipientEmails'
  });
  
  const {
    fields: additionalProductReportRecipientEmails,
    append: appendAdditionalProductReportRecipientEmails,
    remove: removeAdditionalProductReportRecipientEmails
  } = useFieldArray({
    control,
    name: 'additionalProductReportRecipientEmails'
  });

  const { data: strenghtPasswordData, makeRequest: refetchPassSettings } = useFetch({
    endpoint: '/api/passwordSettings/default',
    method: FetchMethodsEnum.GET
  });

  const { makeRequest: refetch, loading: Loading } = useFetch({
    endpoint: '/api/emailSettings',
    method: FetchMethodsEnum.GET,
    onCompleted: (data) => {
      refetchPassSettings();
  
      const [ emails ] = data;
      setEmailsData(emails);

      const transform = (array) => (array || []).map(value => ({ value }));
      reset({
        completeWizardEmails: transform(emails.completeWizardEmails),
        contactFormEmails: transform(emails.contactFormEmails),
        deregistrationVehicleEmails: transform(emails.deregistrationVehicleEmails),
        escalationEmails: transform(emails.escalationEmails),
        completeInsuranceDataEmails: transform(emails.completeInsuranceDataEmails),
        sepaMandatEscalationEmails: transform(emails.sepaMandatEscalationEmails),
        sepaMandatUploadedEmails: transform(emails.sepaMandatUploadedEmails),
        completeInsuranceDataExternEmails: transform(emails.completeInsuranceDataExternEmails),
        completeWizardExternEmails: transform(emails.completeWizardExternEmails),
        additionalProductReportRecipientEmails: transform(emails.additionalProductReportRecipientEmails),
        reportRecipientEmails: transform(emails.reportRecipientEmails),
        senderSettingsEmail: emails.senderSettings.email,
        senderSettingsName: emails.senderSettings.name,
        replyToEmail: emails.replyToEmail
      });
    },
    onError: () => {
      notifyError();
    }
  });


  const { makeRequest: updateEmails, loading } = useFetch({
    endpoint: '/api/emailSettings/EmailSettingsData',
    method: FetchMethodsEnum.PUT,
    onCompleted: () => {
      refetch();
      notifySucces();
    },
  });

  const { makeRequest: setStrenghtPassword } = useFetch({
    endpoint: '/api/passwordSettings/PasswordSettingsData',
    method: FetchMethodsEnum.PUT,
    onCompleted: () => {
      refetch();
    },
  });

  const strenghtPassword = [
    { label: '1', value: 0 },
    { label: '2', value: 1 },
    { label: '3', value: 2 },
    { label: '4', value: 3 },
    { label: '5', value: 4 },
  ];

  const onSubmit = async (values) => {
    const transform = (array, oldArray?) => (array || oldArray || []).map((ems) => ems?.value);
    await updateEmails({
      data: {
        ...emailsData,
        completeWizardEmails: (values.completeWizardEmails || emailsData?.completeWizardEmails) && transform(values.completeWizardEmails, emailsData?.completeWizardEmails),
        completeInsuranceDataEmails: (values.completeInsuranceDataEmails || emailsData?.completeInsuranceDataEmails) && transform(values.completeInsuranceDataEmails, emailsData?.completeInsuranceDataEmails),
        contactFormEmails: (values.contactFormEmails || values.contactFormEmails) && transform(values.contactFormEmails, values.contactFormEmails),
        deregistrationVehicleEmails: (values.deregistrationVehicleEmails || emailsData?.deregistrationVehicleEmails) && transform(values.deregistrationVehicleEmails, emailsData?.deregistrationVehicleEmails),
        escalationEmails: (values.escalationEmails || emailsData?.escalationEmails) && transform(values.escalationEmails, emailsData?.escalationEmails),
        sepaMandatEscalationEmails: (values.sepaMandatEscalationEmails || emailsData?.sepaMandatEscalationEmails) && transform(values.sepaMandatEscalationEmails, emailsData?.sepaMandatEscalationEmails),
        sepaMandatUploadedEmails: (values.sepaMandatUploadedEmails || emailsData?.sepaMandatUploadedEmails) && transform(values.sepaMandatUploadedEmails, emailsData?.sepaMandatUploadedEmails),
        completeInsuranceDataExternEmails: (values.completeInsuranceDataExternEmails || emailsData?.completeInsuranceDataExternEmails) && transform(values.completeInsuranceDataExternEmails, emailsData?.completeInsuranceDataExternEmails),
        completeWizardExternEmails: (values.completeWizardExternEmails || emailsData?.completeWizardExternEmails) && transform(values.completeWizardExternEmails, emailsData?.completeWizardExternEmails),
        additionalProductReportRecipientEmails: (values.additionalProductReportRecipientEmails || emailsData?.additionalProductReportRecipientEmails) && transform(values.additionalProductReportRecipientEmails, emailsData?.additionalProductReportRecipientEmails),
        reportRecipientEmails: (values.reportRecipientEmails || emailsData?.reportRecipientEmails) && transform(values.reportRecipientEmails, emailsData?.reportRecipientEmails),
        senderSettings: {
          email: values?.senderSettingsEmail,
          name: values?.senderSettingsName,
        },
        replyToEmail: values?.replyToEmail
      }
    });
    await setStrenghtPassword({
      data: {
        requiredScore: Number.parseInt(values.strenghtPassword?.value),
      }
    });
  };

  const debouncedLoading = useDebounce(loading || Loading);

  return (
    <Stack className="settingsPage">
      <div className="settingsButtonContainer">
        <PrimaryButton
          className="settingsButtonStyle"
          onClick={() => history.push('/editor')}
          text={t('viewSettingsPage.faqEditor')}
        />
        <PrimaryButton
          className="settingsButtonStyle"
          onClick={() => history.push('/landing-admin')}
          text={t('viewSettingsPage.userManager')}
        />
      </div>
      <Stack
        className='contentContainer'
        style={{ backgroundColor: mainTheme.semanticColors.bodyBackground }}
      >
        {(debouncedLoading) ? (
          <SpinnerDefault />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='container'>
              <h2 
                style={{ 
                  color: alternateTheme.palette.themeDark
                }}
                className="title"
              >
                {t('viewSettingsPage.title')}
              </h2>
              <PrimaryButton
                disabled={loading || !formState.dirty}
                type="submit"
                className='buttonStyles'
                text={t('global.save')}
              />
            </div>
            <div className="emailFieldsContainer">
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.completeWizardEmails')}</span>
                {completeWizardEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`completeWizardEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      defaultValue={field.value}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `completeWizardEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeCompleteWizardEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))} 
                <ActionButton
                  onClick={() => appendCompleteWizardEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.contactFormEmails')}</span>
                {contactFormEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`contactFormEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `contactFormEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeContactFormEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))} 
                <ActionButton
                  onClick={() => appendContactFormEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.deregistrationVehicleEmails')}</span>
                {deregistrationVehicleEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`deregistrationVehicleEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `deregistrationVehicleEmails[${position}].value`, t)} 
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeDeregistrationVehicleEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendDeregistrationVehicleEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.escalationEmails')}</span>
                {escalationEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`escalationEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `escalationEmails[${position}].value`, t)} 
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeEscalationEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendEscalationEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.completeInsuranceDataEmails')}</span>
                {completeInsuranceDataEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`completeInsuranceDataEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `completeInsuranceDataEmails[${position}].value`, t)} 
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeCompleteInsuranceDataEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendCompleteInsuranceDataEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.sepaMandatEscalationEmails')}</span>
                {sepaMandatEscalationEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`sepaMandatEscalationEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `sepaMandatEscalationEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeSepaMandatEscalationEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendSepaMandatEscalationEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.sepaMandatUploadedEmails')}</span>
                {sepaMandatUploadedEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`sepaMandatUploadedEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `sepaMandatUploadedEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeSepaMandatUploadedEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendSepaMandatUploadedEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.completeInsuranceDataExternEmails')}</span>
                {completeInsuranceDataExternEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`completeInsuranceDataExternEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `completeInsuranceDataExternEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeCompleteInsuranceDataExternEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendCompleteInsuranceDataExternEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.completeWizardExternEmails')}</span>
                {completeWizardExternEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`completeWizardExternEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `completeWizardExternEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeCompleteWizardExternEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendCompleteWizardExternEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.reportRecipientEmails')}</span>
                {reportRecipientEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`reportRecipientEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `reportRecipientEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeReportRecipientEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendReportRecipientEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
              <div className="rowEmailsContainer">
                <span className="text">{t('viewSettingsPage.additionalProductReportRecipientEmails')}</span>
                {additionalProductReportRecipientEmails.map((field, position) => (
                  <div key={field.id} className='fieldContainer'>
                    <Controller
                      name={`additionalProductReportRecipientEmails[${position}].value`}
                      rules={{
                        validate: {
                          emailFormat: validateEmails,
                        },
                      }}
                      control={control}
                      as={
                        <TextField
                          errorMessage={getEmailError(errors, `additionalProductReportRecipientEmails[${position}].value`, t)}
                        />
                      }
                    />
                    <ActionButton
                      onClick={() => removeAdditionalProductReportRecipientEmails(position)}
                      iconProps={{ iconName: 'Delete', style: { fontWeight: 'bold' } }} 
                    />
                  </div>
                ))}
                <ActionButton
                  onClick={() => appendAdditionalProductReportRecipientEmails('' as any)}
                  iconProps={{ iconName: 'Add', style: { fontWeight: 'bold' } }} 
                />
              </div>
            </div>
            <h2
              style={{ 
                color: alternateTheme.palette.themeDarker,
                marginTop: 20
              }}
              className="title"
            >
              {t('viewSettingsPage.configurationTitle')}
            </h2>
            <div className="rowEmailsContainer">
              <span className="text">{t('viewSettingsPage.strengthPassword')}</span>
              <div className='fieldContainerDefaultSelect'>
                {strenghtPasswordData?.requiredScore && (
                  <Controller
                    name="strenghtPassword"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={{
                      value: strenghtPasswordData?.requiredScore,
                      label: t(`${strenghtPasswordData?.requiredScore + 1}`),
                    }}
                    as={
                      <ThemedSelect
                        options={strenghtPassword as any}
                      />
                    }
                  />
                )}
              </div>
            </div>
            <div className="rowEmailsContainer">
              <span className="text">{t('viewSettingsPage.senderSettingsEmail')}</span>
              <div className='fieldContainerDefault'>
                <Controller
                  name='senderSettingsEmail'
                  rules={{
                    validate: {
                      emailFormat: validateEmails,
                    },
                  }}
                  control={control}
                  as={
                    <TextField
                      errorMessage={getEmailError(errors, 'senderSettingsEmail', t)}
                    />
                  }
                />
              </div>
            </div>
            <div className="rowEmailsContainer">
              <span className="text">{t('viewSettingsPage.senderSettingsName')}</span>
              <div className='fieldContainerDefault'>
                <Controller
                  name='senderSettingsName'
                  control={control}
                  as={
                    <TextField />
                    }
                />
              </div>
            </div>
            <div className="rowEmailsContainer" style={{ marginBottom: 40 }}>
              <span className="text">{t('viewSettingsPage.replyToEmail')}</span>
              <div className='fieldContainerDefault'>
                <Controller
                  name='replyToEmail'
                  control={control}
                  as={
                    <TextField />
                  }
                />
              </div>
            </div>
          </form>
        )}
      </Stack>
    </Stack>
  );
}
