import React, { useEffect } from 'react';
import moment from 'moment';
import { OverflowSet } from '@fluentui/react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';

export const ActionSet = ({
  t, handleMenuItemClicked,
  onRenderOverflowButton, onRenderItem,
  className, userId, id,
  key, handleContactDetailClicked,
  handleContactDamageClicked, handleContactDocumentsClicked
}) => {
  const { makeRequest: refetch, data } = useFetch({
    endpoint: `/api/dspusers/${userId}/insurance/${id}/damageReports`,
    method: FetchMethodsEnum.GET,
  });

  useEffect(() => {
    refetch();
  }, [key]);

  let overflowItems = [];

  overflowItems = [...overflowItems, ...[
    {
      key: 'reportDamage',
      name: t('overviewDetail.reportDamage'),
      onClick: () => {
        handleMenuItemClicked();
        refetch();
      }
    },
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDetails'),
      onClick: () => {
        handleContactDetailClicked(id);
      }
    },
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDamage'),
      onClick: () => {
        handleContactDamageClicked(id);
      }
    },
    {
      key: 'contactDetails',
      name: t('overviewDamage.contactDocuments'),
      onClick: () => {
        handleContactDocumentsClicked(id);
      }
    },
  ]as never[]];

  if (data?.length > 0) {
    overflowItems.push({
      key: 'showDamage',
      name: t('overviewDetail.showDamage'),
      subMenuProps: {
        items: [
          ...data.map(element => {
            return {
              key: `showDamage-${element.id}`,
              text: t('overviewDetail.damageFrom') + moment(element?.damageInformation?.date).format('DD.MM.YYYY'),
              title: moment(element.createDate).format('DD.MM.YYYY'),
              onClick: () => {
                handleMenuItemClicked(element.id);
              },
            };
          }),
        ]},
    } as never);
  }

  return (
    <OverflowSet
      className={className}
      aria-label="menu-bar"
      role="menubar"
      vertical
      overflowItems={overflowItems}
      onRenderOverflowButton={onRenderOverflowButton}
      onRenderItem={onRenderItem}
    />
  );
};
