import React, {
  CSSProperties,
  useEffect, useState,
  useCallback, useMemo
} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'office-ui-fabric-react';
import { useDropzone } from 'react-dropzone';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import OptionalTextField from '@/components/base/Optional/OptionalTextField';
import { CheckboxControl } from '@/components/base/CheckboxControl';
import OptionalLabel from '@/components/base/Optional/OptionalLabel';
import { dayPickerStrings, onFormatDate, dayPickerStringsGerman } from '@/utils/calendar';
import { useAuth } from '@/contexts/auth';
import { hasErrors } from '@/utils/hasErrors';

import {
  Dialog, PrimaryButton, ActionButton,
  TextField, Spinner, getTheme
} from '@fluentui/react';

import {
  getRequiredError,
  validateEmail,
  getEmailError,
  getError,
  validateLicNum,
} from '@/utils/validations';

import { checkComplete } from './complete';

import './index.scss';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: getTheme().semanticColors.inputBorder,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  color: getTheme().semanticColors.primaryButtonBackground,
  borderColor: getTheme().semanticColors.primaryButtonBackground,
  borderStyle: 'solid',
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const values2data = (values) => ({
  driverInformation: {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    telephone: values?.telephone,
    isDrivingLicenceAvailable: values?.isDrivingLicenceAvailable?.value === 'true'
  },
  file: values?.attachmentUrls,
  comments: values?.comments,
  damageInformation: {
    date: values?.date,
    damageKind: {
      isLiabilityInsurance: values?.damageKind?.isLiabilityInsurance,
      isFullInsurance: values?.damageKind?.isFullInsurance,
      isGapInsurance: values?.damageKind?.isGapInsurance,
    },
    reason: values?.reason?.value,
    place: values?.place,
    recordedByPolice: values?.recordedByPolice?.value === 'true',
    receptionPoliceStation: values?.receptionPoliceStation,
    policeFileNumber: values?.policeFileNumber,
  },
  otherPartyData: {
    licensePlate: values?.licensePlate,
    firstName: values?.otherFirstName,
    lastName: values?.otherLastName,
    telephone: values?.phoneNumber,
    email: values?.otherEmail,
  },
});

const defaultValues = {
  file: [],
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  comments: '',
  isDrivingLicenceAvailable: null,
  date: new Date(),
  reason: null,
  place: '',
  recordedByPolice: null,
  isGapInsurance: false,
  isFullInsurance: false,
  isLiabilityInsurance: false,
  receptionPoliceStation: '',
  policeFileNumber: '',
  licensePlate: '',
  otherFirstName: '',
  otherLastName: '',
  phoneNumber: '',
  otherEmail: ''
};

export const ReportDamage = ({
  hidden,
  onDismiss,
  vehicleId,
  userId,
  companyName,
  dspNumber,
  licenceNumber,
  reportId,
  skipSend
}) => {
  const { handleSubmit, control, errors, watch, reset, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const fv = watch();

  const notifySucces = () => toast.success(t('toasts.submitReportDamageSuccess'));
  const notifyError = () => toast.error(t('toasts.submitReportDamageError'));

  const { t, i18n } = useTranslation();
  const [fileNames, setFileNames] = useState([] as string[]);
  const [fileLinks, setFileLinks] = useState([] as string[]);

  useEffect(() => {
    reset(defaultValues);
    setFileNames([]);
  }, [hidden]);

  const reason = [
    { label: t('reportDamage.rearEndCollision'), value: t('reportDamage.rearEndCollision') },
    { label: t('reportDamage.turnOff'), value: t('reportDamage.turnOff') },
    { label: t('reportDamage.parking'), value: t('reportDamage.parking') },
    { label: t('reportDamage.glassBreakage'), value: t('reportDamage.glassBreakage') },
    { label: t('reportDamage.theft'), value: t('reportDamage.theft') },
    { label: t('reportDamage.hail'), value: t('reportDamage.hail') },
    { label: t('reportDamage.theft'), value: t('reportDamage.theft') },
    { label: t('reportDamage.hail'), value: t('reportDamage.hail') },
    { label: t('reportDamage.personalInjury'), value: t('reportDamage.personalInjury') },
    { label: t('reportDamage.miscellaneous'), value: t('reportDamage.miscellaneous') },
  ];

  const choise = [
    { label: t('global.yes'), value: 'true' },
    { label: t('global.no'), value: 'false' }
  ];

  const onDrop = useCallback(acceptedFiles => {
    setValue('file', [ ...fv.file, ...acceptedFiles]);
    setFileNames([...fv.file, ...acceptedFiles].map(file => file.name));
  }, [setValue, fv.file]);

  const { data } = useFetch({
    endpoint: '/api/dspusers/documents/formats',
    method: FetchMethodsEnum.GET,
  });
  
  const acceptFormats = `.${data?.join(', .')}`;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptFormats, onDrop, multiple: true });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]) as CSSProperties;

  const [damageReportId, setDamageReportId] = useState(reportId);

  const values: any = watch();
  const [r, setR] = useState({});
  const [isSent, setSent] = useState();

  const { makeRequest: refetch, loading: Loader } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports`,
    method: FetchMethodsEnum.GET,
    onCompleted: data => {
      const r = (data || [])?.find(({ id }) => id === reportId);

      if (!r?.id) {
        setDamageReportId(undefined);
        reset(defaultValues);
        setR({});
      } else {
        setSent(r?.isSent);
        setFileLinks(r?.attachmentUrls);
        setDamageReportId(r?.id);

        const fv = {
          file: '',
          firstName: r?.driverInformation?.firstName || '',
          lastName: r?.driverInformation?.lastName || '',
          email: r?.driverInformation?.email || '',
          telephone: r?.driverInformation?.telephone || '',
          comments: r?.comments || '',
          isDrivingLicenceAvailable: {
            label: r?.driverInformation?.isDrivingLicenceAvailable ? t('global.yes') : t('global.no'),
            value: r?.driverInformation?.isDrivingLicenceAvailable ? 'true' : 'false',
          },
          date: moment(r?.damageInformation?.date).toDate() || moment().toISOString(),
          reason: {
            label: r?.damageInformation?.reason || '',
            value: r?.damageInformation?.reason || '',
          },
          place: r?.damageInformation?.place || '',
          recordedByPolice: {
            label: r?.damageInformation?.recordedByPolice ? t('global.yes') : t('global.no'),
            value: r?.damageInformation?.recordedByPolice ? 'true' : 'false',
          },
          isLiabilityInsurance: r?.damageInformation?.damageKind?.isLiabilityInsurance,
          isFullInsurance: r?.damageInformation?.damageKind?.isFullInsurance,
          isGapInsurance: r?.damageInformation?.damageKind?.isGapInsurance,
          receptionPoliceStation: r?.damageInformation?.receptionPoliceStation || '',
          policeFileNumber: r?.damageInformation?.policeFileNumber || '',
          licensePlate: r?.otherPartyData?.licensePlate || '',
          otherFirstName: r?.otherPartyData?.firstName || '',
          otherLastName: r?.otherPartyData?.lastName || '',
          phoneNumber: r?.otherPartyData?.telephone || '',
          otherEmail: r?.otherPartyData?.email || '',
        };
        reset(fv as any);
        setR(fv);

      }
    }
  });

  useEffect(() => {
    setDamageReportId(reportId);
  }, [reportId]);

  useEffect(() => {
    if (damageReportId) {
      refetch();
    }
  }, [damageReportId]);

  const { makeRequest: saveReportDamage, loading } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports${damageReportId ? `/${damageReportId}` : ''}`,
    method: reportId ? FetchMethodsEnum.PUT : FetchMethodsEnum.POST,
    data: values2data(values)
  });

  const { makeRequest: sendReportDamage, loading: sending } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports/${damageReportId}/sendData`,
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      notifySucces();
    },
    onError: () => {
      notifyError();
    }
  });

  const { makeRequest: deleteReportDamage, loading: deliting } = useFetch({
    endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports/${damageReportId}`,
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      reset(defaultValues);
      setDamageReportId(undefined);
    }
  });

  const onSubmit = async (formValues) => {
    const formData = new FormData();

    if (formValues.file) {
      formValues.file.forEach((attachment) => {
        formData.append('attachments', attachment);
      });
    }

    formData.append('damageReportDto.comments', formValues.comments || '');

    formData.append('damageReportDto.driverInformation.firstName', formValues.firstName || '');
    formData.append('damageReportDto.driverInformation.lastName', formValues.lastName || '');
    formData.append('damageReportDto.driverInformation.telephone', formValues.telephone || '');
    formData.append('damageReportDto.driverInformation.email', formValues.email || '');
    formData.append('damageReportDto.driverInformation.isDrivingLicenceAvailable', formValues?.isDrivingLicenceAvailable?.value || false);
    formData.append('damageReportDto.driverInformation.isDrivingLicenceAvailable', formValues?.isDrivingLicenceAvailable?.value || false);

    formData.append('damageReportDto.damageInformation.date', `${moment(formValues?.date).format('YYYY-MM-DDT00:00:00.000')  }Z`);
    formData.append('damageReportDto.damageInformation.damageKind.isLiabilityInsurance', Boolean(values.isLiabilityInsurance).toString() || '');
    formData.append('damageReportDto.damageInformation.damageKind.isFullInsurance', Boolean(values.isFullInsurance).toString() || '');
    formData.append('damageReportDto.damageInformation.damageKind.isGapInsurance', Boolean(values.isGapInsurance).toString() || '');

    formData.append('damageReportDto.damageInformation.reason', formValues?.reason?.value || '');
    formData.append('damageReportDto.damageInformation.place', formValues?.place || '');
    formData.append('damageReportDto.damageInformation.recordedByPolice', formValues?.recordedByPolice?.value || false);
    formData.append('damageReportDto.damageInformation.receptionPoliceStation', formValues?.receptionPoliceStation || '');
    formData.append('damageReportDto.damageInformation.policeFileNumber', formValues?.policeFileNumber || '');

    formData.append('damageReportDto.otherPartyData.licensePlate', formValues?.licensePlate || '');
    formData.append('damageReportDto.otherPartyData.firstName', formValues?.otherFirstName || '');
    formData.append('damageReportDto.otherPartyData.lastName', formValues?.otherLastName || '');
    formData.append('damageReportDto.otherPartyData.telephone', formValues?.phoneNumber || '');
    formData.append('damageReportDto.otherPartyData.email', formValues?.otherEmail || '');

    const data = await saveReportDamage({
      endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports${damageReportId ? `/${damageReportId}` : ''}`,
      formData,
    });

    onDismiss();
  };

  const handleSend = async () => {
    const formData = new FormData();
    if (values.file) {
      values.file.forEach((attachment) => {
        formData.append('attachments', attachment);
      });
    }

    formData.append('damageReportDto.comments', values.comments || '');

    formData.append('damageReportDto.driverInformation.firstName', values.firstName || '');
    formData.append('damageReportDto.driverInformation.lastName', values.lastName || '');
    formData.append('damageReportDto.driverInformation.telephone', values.telephone || '');
    formData.append('damageReportDto.driverInformation.email', values.email || '');
    formData.append('damageReportDto.driverInformation.isDrivingLicenceAvailable', values?.isDrivingLicenceAvailable?.value || false);
    formData.append('damageReportDto.driverInformation.isDrivingLicenceAvailable', values?.isDrivingLicenceAvailable?.value || false);

    formData.append('damageReportDto.damageInformation.date', `${moment(values?.date).format('YYYY-MM-DDT00:00:00.000')  }Z` );
    formData.append('damageReportDto.damageInformation.damageKind.isLiabilityInsurance', Boolean(values.isLiabilityInsurance).toString() || '');
    formData.append('damageReportDto.damageInformation.damageKind.isFullInsurance', Boolean(values.isFullInsurance).toString() || '');
    formData.append('damageReportDto.damageInformation.damageKind.isGapInsurance', Boolean(values.isGapInsurance).toString() || '');

    formData.append('damageReportDto.damageInformation.reason', values?.reason?.value || '');
    formData.append('damageReportDto.damageInformation.place', values?.place || '');
    formData.append('damageReportDto.damageInformation.recordedByPolice', values?.recordedByPolice?.value || false);
    formData.append('damageReportDto.damageInformation.receptionPoliceStation', values?.receptionPoliceStation || '');
    formData.append('damageReportDto.damageInformation.policeFileNumber', values?.policeFileNumber || '');

    formData.append('damageReportDto.otherPartyData.licensePlate', values?.licensePlate || '');
    formData.append('damageReportDto.otherPartyData.firstName', values?.otherFirstName || '');
    formData.append('damageReportDto.otherPartyData.lastName', values?.otherLastName || '');
    formData.append('damageReportDto.otherPartyData.telephone', values?.phoneNumber || '');
    formData.append('damageReportDto.otherPartyData.email', values?.otherEmail || '');

    const data = await saveReportDamage({
      endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports${damageReportId ? `/${damageReportId}` : ''}`,
      formData,
    });

    await sendReportDamage({
      formData,
      endpoint: `/api/dspusers/${userId}/vehicle/${vehicleId}/damageReports/${data?.id}/sendData`,
    });

    onDismiss();
  };

  const complete = checkComplete(values);

  const { token } = useAuth();
  const downloadFile = (url) => async () => {
    const fileName = url.split('attachments/')[1];
    const link = document.createElement('a');

    link.setAttribute('download', `${fileName}`);
    link.setAttribute('target', '_blank');

    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const mime = response.headers['content-type'];
    const blob = new Blob([response.data], { type: mime });
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;

    document.body.appendChild(link);
    link.click();
    link.remove();

    window.open(objectUrl, '_blank');
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => {
        onDismiss();
        reset(defaultValues);
      }}
      minWidth="350px"
      maxWidth="900px"
      modalProps={{
        isBlocking: true,
        className: 'reportDamageContainer',
        layerProps: { eventBubblingEnabled: true },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='reportDamageForm'>
        <div className="rowTitle">{t('reportDamage.damageReportTitle')}</div>
        <div className='rowContainer'>
          <TextField
            disabled
            label={t('reportDamage.DSPNumber')}
            className='fieldDisabled'
            value={dspNumber}
          />
          <TextField
            disabled
            label={t('reportDamage.companyName')}
            className='fieldDisabled'
            value={companyName}
          />
          <TextField
            disabled
            label={t('reportDamage.licenceNumber')}
            className='fieldDisabled'
            value={licenceNumber}
          />
        </div>


        <div className="rowTitle">{t('reportDamage.driversInformationTitle')}</div>
        <div className='blockContainer'>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamage.firstName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'firstName',
                  t('reportDamage.firstNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamage.lastName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'lastName',
                  t('reportDamage.lastNameErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="email"
            control={control}
            rules={{
              validate: {
                emailFormat: validateEmail,
              },
            }}
            as={
              <OptionalTextField
                disabled={isSent}
                label={t('reportDamage.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />

          <Controller
            name="telephone"
            control={control}
            rules={{}}
            as={
              <OptionalTextField
                label={t('reportDamage.phoneNumber')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'telephone',
                  t('reportDamage.telephoneErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="isDrivingLicenceAvailable"
            rules={{ required: true }}
            control={control}
            as={
              <ThemedSelect
                label={t('reportDamage.drivingLicence')}
                placeholder={t('reportDamage.selectPlaceholder')}
                options={choise}
                selectDisabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'isDrivingLicenceAvailable',
                  'isDrivingLicenceAvailable'
                )}
              />
            }
          />
        </div>

        <div className="rowTitle">{t('reportDamage.informationAboutDamadeTitle')}</div>
        <div className='blockContainer'>
          <Controller
            name="date"
            control={control}
            as={
              <DatePicker
                disableAutoFocus
                strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
                placeholder={t('overviewDetail.deregister.placeholder.date')}
                label={t('reportDamage.date')}
                formatDate={onFormatDate}
                disabled={isSent}
                maxDate={new Date()}
                onSelectDate={(val) => {
                  setValue('date', val as any);
                }}
              />
                }
          />

          <div className='titleCheckbox'>{t('reportDamage.kindOfDamade')}</div>
          <div className="checkboxRowContainer">
            <Controller
              name="isLiabilityInsurance"
              control={control}
              as={
                <CheckboxControl disabled={isSent} label={t('reportDamage.liabilityInsurance')} />
              }
            />
            <Controller
              name="isFullInsurance"
              control={control}
              as={
                <CheckboxControl disabled={isSent} label={t('reportDamage.fullInsurance')} />
              }
            />
            <Controller
              name="isGapInsurance"
              control={control}
              as={
                <CheckboxControl disabled={isSent} label={t('reportDamage.gap')} />
              }
            />
          </div>
          <Controller
            name="reason"
            rules={{ required: true }}
            control={control}
            as={
              <ThemedSelect
                label={t('reportDamage.reasonOfDamage')}
                placeholder={t('reportDamage.selectPlaceholder')}
                options={reason}
                selectDisabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'reason',
                  'reasonErrorMessage'
                )}
              />
              }
          />
          <Controller
            name="place"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamage.place')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'place',
                  t('reportDamage.placeErrorMessage')
                )}
              />
              }
          />
          <Controller
            name="recordedByPolice"
            rules={{ required: true }}
            control={control}
            as={
              <ThemedSelect
                label={t('reportDamage.questionIncident')}
                placeholder={t('reportDamage.selectPlaceholder')}
                options={choise}
                selectDisabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'recordedByPolice',
                  'recordedByPolice'
                )}
              />
              }
          />

          {values.recordedByPolice?.value === 'true' &&
          <>
            <Controller
              name="receptionPoliceStation"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('reportDamage.receivingPolice')}
                  disabled={isSent}
                  errorMessage={getRequiredError(
                    errors,
                    'receptionPoliceStation',
                    t('reportDamage.receptionPoliceStationErrorMessage')
                  )}
                />
                  }
            />
            <Controller
              name="policeFileNumber"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('reportDamage.policeFileNumber')}
                  disabled={isSent}
                  errorMessage={getRequiredError(
                    errors,
                    'policeFileNumber',
                    t('reportDamage.policeFileNumberErrorMessage')
                  )}
                />
                  }
            />
          </>
            }
        </div>

        {values.isLiabilityInsurance && (
          <>
            <div className="rowTitle">{t('reportDamage.dataOnTheParty')}</div>
            <div className='blockContainer'>
              <Controller
                name="licensePlate"
                control={control}
                rules={values.isLiabilityInsurance ? {
                  required: true,
                  validate: {
                    licNum: validateLicNum,
                  }
                } : {}}
                as={
                  <OptionalTextField
                    className="upper"
                    label={t('reportDamage.licenceNumber')}
                    disabled={isSent}
                    errorMessage={getError(errors, 'licensePlate', t)}
                  />
                }
              />
              <Controller
                name="otherFirstName"
                control={control}
                as={
                  <OptionalTextField
                    label={t('reportDamage.otherFirstName')}
                    disabled={isSent}
                    errorMessage={getRequiredError(
                      errors,
                      'firstName',
                      t('reportDamage.firstNameErrorMessage')
                    )}
                  />
                }
              />
              <Controller
                name="otherLastName"
                control={control}
                rules={{ required: true }}
                as={
                  <TextField
                    label={t('reportDamage.lastName')}
                    disabled={isSent}
                    errorMessage={getRequiredError(
                      errors,
                      'lastName',
                      t('reportDamage.lastNameErrorMessage')
                    )}
                  />
                }
              />
              <Controller
                name="phoneNumber"
                control={control}
                as={
                  <OptionalTextField
                    label={t('reportDamage.phoneNumber')}
                    disabled={isSent}
                    errorMessage={getRequiredError(
                      errors,
                      'phoneNumber',
                      t('reportDamage.phoneNumberErrorMessage')
                    )}
                  />
                }
              />
              <Controller
                name="otherEmail"
                control={control}
                as={
                  <OptionalTextField
                    label={t('reportDamage.email')}
                    disabled={isSent}
                    errorMessage={getRequiredError(
                      errors,
                      'email',
                      t('reportDamage.emailErrorMessage')
                    )}
                  />
                }
              />
            </div>
          </>
        )}
        <div className="rowTitle">{t('reportDamage.appendicesAndNotes')}</div>
        <div className='blockContainer'>
          <Controller
            name="file"
            control={control}
            as={
              <div>
                <OptionalLabel>{t('reportDamage.appendices')}</OptionalLabel>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} disabled={isSent} />
                  <p>{t('overviewDetail.deregister.placeholder.upload')}</p>
                </div>
                {fileNames.map(fileName => (
                  <div key={fileName} style={{ display: 'flex', justifyContent: 'space-between', maxHeight: 25 }}>
                    <li key={fileName} className="fileListDefaultStyle">
                      {fileName}
                    </li>
                    <ActionButton
                      onClick={() => {
                        if (fileNames.length > 0) {
                          setFileNames(fileNames.filter((name) => name !== fileName));
                          setValue('file', values.file.filter(({ name }) => name !== fileName));
                        }
                      }}
                      iconProps={{ iconName: 'ChromeClose', styles: {
                        root: {
                          fontSize: '10px',
                          height: 25,
                          cursor: 'poiner'
                        }
                      } }}
                    />
                  </div>
                ))}
                {fileLinks.map((link, index) => (
                  <li
                    className="fileListStyle"
                    key={link}
                    onClick={downloadFile(`${axios.defaults.baseURL}/api/dspusers/${userId}/vehicle/${vehicleId}/${link}`)}
                  >
                    {link.split('/').pop()}
                  </li>
                ))}
              </div>
              }
          />

          <Controller
            name="comments"
            control={control}
            as={
              <OptionalTextField
                multiline
                disabled={isSent}
                label={t('reportDamage.notes')}
              />
              }
          />
        </div>
        <div className='buttonBlock'>
          <div>
            <PrimaryButton
              className='actionButtonStyle'
              text={t('global.cancel')}
              disabled={isSent}
              onClick={() => {
                deleteReportDamage();
                onDismiss();
              }}
            />
          </div>
          <div className="funcionButtonContainer">
            {(Loader || loading || sending || deliting) && (
              <Spinner />
            )}
            <PrimaryButton
              className='actionButtonStyle'
              disabled={!complete || hasErrors(errors) || isSent}
              onClick={handleSend}
              text={t('global.send')}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};
