import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultButton } from '@fluentui/react';

import { LANG_COOCKIE_PATH } from '@/constants/cookie';

import './index.scss';

export const Menu = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();


  const onChange = (event, item): void => {
    i18n.changeLanguage(item.key);
  };

  useEffect(() => {
    if (i18n.language) {
      localStorage.setItem(LANG_COOCKIE_PATH, i18n.language);
    }
  }, [i18n.language]);


  const menuItems = [
    {
      key: 'changePassword',
      text: t('header.changePassword'),
      onClick: () => history.push('/change-password'),
    },
    {
      key: 'language',
      iconProps: {
        iconName: 'language',
      },
      subMenuProps: {
        items: [
          {
            key: 'en',
            text: t('header.english'),
            onClick: onChange,
            ...(i18n.language === 'en' ? {
              iconProps: {
                iconName: 'CheckMark',
              },
            } : {})
          },
          {
            key: 'de',
            text: t('header.german'),
            onClick: onChange,
            ...(i18n.language === 'de' ? {
              iconProps: {
                iconName: 'CheckMark',
              },
            } : {})
          },
        ],
      },
      text: t('header.changeLanguage'),
    },
    {
      key: 'logOut',
      text: t('header.logOut'),
      onClick: () => history.push('/logout'),
    },
  ];  

  return (
    <DefaultButton
      className='menuContainer'
      styles={{
        root: {
          background: '#232f3e',
          color: '#a3aaaf',
          border: 'none'
        }
      }}
      iconProps={{ iconName: 'Contact' }}
      menuProps={{
        shouldFocusOnMount: true,
        items: menuItems,
      }} 
    />
  );
};