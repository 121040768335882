import React from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';

import CallToActionButtonProperties from './Properties';

export default function CallToActionButton(
  props: CallToActionButtonProperties
): JSX.Element {

  const history = useHistory();
  const navigate = () => {
    if(props.to) {
      history.push(props.to);
    }
  };

  return (
    <PrimaryButton
      styles={{
        root: {
          borderRadius: 50,
          width: '100%'
        },
        flexContainer: {
          flexDirection: 'row-reverse'
        }
      }}
      onClick={navigate}
      {...props}
    >
      {props?.text}
    </PrimaryButton>
  );
}
