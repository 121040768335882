import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANG_COOCKIE_PATH } from '@/constants/cookie';

import enMessages from './English';
import deMessages from './German';

const currentLang = localStorage.getItem(LANG_COOCKIE_PATH) || 'de';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enMessages,
      },
      de: {
        translation: deMessages,
      },
    },
    lng: currentLang,
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false,
    }
  });
