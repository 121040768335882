import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@fluentui/react';
import { LinkProperties } from '@/types/LinkProperties';

export interface NavigationLinkProperties extends LinkProperties {
  to: string;
}

export default function NavigationLink(
  properties: NavigationLinkProperties
): JSX.Element {
  const history = useHistory();
  return (
    <Link {...properties} onClick={() => history.push(properties.to)}>
      {properties.text}
    </Link>
  );
}
