import React, {
  useEffect,
  useState
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';

import {
  DialogFooter, PrimaryButton,
  Dialog, TextField
} from '@fluentui/react';

import {
  getRequiredError
} from '@/utils/validations';

import './index.scss';

const defaultValues = {
  question: '',
  answer: ''
};
  
export const AddNewQuestion = ({
  hidden,
  id,
  onDismiss,
  initialValues,
  refetch,
}: any) => {
  const { t } = useTranslation();

  const [data, setData] = useState(initialValues?.answer || '');
  const [isDirtyEditor, setDirtyEditor] = useState(false);

  const notifyCreateSucces = () => toast.success(t('toasts.createQuestionSuccess'));
  const notifyCreateError = () => toast.error(t('toasts.createQuestionError'));

  const notifyUpdateSucces = () => toast.success(t('toasts.updateQuestionSuccess'));
  const notifyUpdateError = () => toast.error(t('toasts.updateQuestionError'));

  const { handleSubmit, control, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || defaultValues,
  });

  const { makeRequest: createQuestion, loading } = useFetch({
    endpoint: '/api/faqs',
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      notifyCreateSucces();
      onDismiss(true);
    },
    onError: () => notifyCreateError()
  });

  const { makeRequest: updateQuestion, loading: updateLoading } = useFetch({
    endpoint: `/api/faqs/${initialValues?.id}`,
    method: FetchMethodsEnum.PUT,
    onCompleted: () => {
      notifyUpdateSucces();
      onDismiss(true);
    },
    onError: (e) => {
      notifyUpdateError();
    }
  });


  const onSubmit = async (values) => {
    if(initialValues?.id) {
      updateQuestion({
        data: {
          ...values,
          answer: data,
        },
      });
    } else {
      createQuestion({
        data: {
          ...values,
          answer: data,
        },
      });
    }
  };
  
  return (
    <Dialog
      modalProps={{
        layerProps: { eventBubblingEnabled: false },
        forceFocusInsideTrap: false,
        overlay: {
          styles: {
            root: {
              opacity: '0.5'
            }
          }
        }
      }}
      hidden={hidden}
      title={initialValues?.id ? t('faq.editQuestion') : t('faq.addNewQuestionTitle')}
      onDismiss={onDismiss}
      minWidth="70%"
      dialogContentProps={{ 
        styles: {
          inner: {
            padding: '0 40px',
          },
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='reportDamageForm'>
        <div style={{ width: '100%' }}>
          <Controller
            name="question"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('faq.question')}
                styles={{ root: {
                  marginBottom: 30 } }}
                errorMessage={getRequiredError(
                  errors,
                  'question',
                  t('reportDamageAdditionalProducts.question')
                )}
              />
            }
          />
          <CKEditor
            editor={ClassicEditor}
            data={data}
            config={{
              ckfinder: {
                uploadUrl: '/api/faqs/upload'
              },
            }}
            onChange={( _event, editor ) => {
              setDirtyEditor(true);
              const nextData = editor.getData();
              setData(nextData);
            }}
          />
        </div>
      </form>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        } }}
      >
        <PrimaryButton style={{ margin: 10 }} onClick={onDismiss} text={t('global.cancel')} />
        {initialValues?.id ? (
          <PrimaryButton
            disabled={!isDirtyEditor && !formState.dirty}
            type="submit"
            style={{ margin: 10 }}
            onClick={handleSubmit(onSubmit)}
            text={t('global.send')}
          />
        ) : (
          <PrimaryButton
            disabled={!formState.dirty || loading || updateLoading || !data}
            type="submit"
            style={{ margin: 10 }}
            onClick={handleSubmit(onSubmit)}
            text={t('global.send')}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
};
  