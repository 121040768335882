import { createTheme } from '@fluentui/react';

// Default theme with darker blue
export const mainTheme = createTheme({
  palette: {
    themePrimary: '#232f3e',
    themeLighterAlt: '#dce1e8',
    themeLighter: '#bdc7d2',
    themeLight: '#a1aebd',
    themeTertiary: '#8796a8',
    themeSecondary: '#6e7f92',
    themeDarkAlt: '#58697d',
    themeDark: '#445468',
    themeDarker: '#324153',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
});

// Alternate theme with brighter blue
export const alternateTheme = createTheme({
  palette: {
    themePrimary: '#00a8e1',
    themeLighterAlt: '#f4fbfe',
    themeLighter: '#d2f0fa',
    themeLight: '#ace3f6',
    themeTertiary: '#5fc9ed',
    themeSecondary: '#1bb2e4',
    themeDarkAlt: '#0097ca',
    themeDark: '#0080ab',
    themeDarker: '#005e7e',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
});


let currentTheme = 'light'

export const switchTheme = () => {
    const root = document.documentElement;
    if (currentTheme === 'light') {
        currentTheme = 'dark'
        root.style.setProperty('--theme-primary', '#00a8e1')
        root.style.setProperty('--theme-lighter-alt', '#f4fbfe')
        root.style.setProperty('--theme-lighter', '#d2f0fa')
        root.style.setProperty('--theme-light', '#ace3f6')
        root.style.setProperty('--theme-tertiary', '#5fc9ed')
        root.style.setProperty('--theme-secondary', '#1bb2e4')
        root.style.setProperty('--theme-dark-alt', '#0097ca')
        root.style.setProperty('--theme-dark', '#0080ab')
        root.style.setProperty('--theme-darker', '#005e7e')
        root.style.setProperty('--neutral-lighter-alt', '#f8f8f8')
        root.style.setProperty('--neutral-lighter', '#f4f4f4')
        root.style.setProperty(' --neutral-light', '#eaeaea')
        root.style.setProperty('--neutral-quaternary-alt', '#dadada')
        root.style.setProperty('--neutral-quaternary', '#d0d0d0')
        root.style.setProperty('--neutral-tertiary-alt', '#c8c8c8')
        root.style.setProperty('--neutral-tertiary', '#a19f9d')
        root.style.setProperty('--neutral-secondary', '#605e5c')
        root.style.setProperty('--neutral-primary-alt', '#3b3a39')
        root.style.setProperty('--neutral-primary', '#323130')
        root.style.setProperty('--neutral-dark', '#201f1e')
        root.style.setProperty('--black', '#000000')
        root.style.setProperty('--white', '#ffffff')
    } else {
        currentTheme = 'light'
        root.style.setProperty('--theme-primary', '#232f3e')
        root.style.setProperty('--theme-lighter-alt', '#dce1e8')
        root.style.setProperty('--theme-lighter', '#bdc7d2')
        root.style.setProperty('--theme-light', '#a1aebd')
        root.style.setProperty('--theme-tertiary', '#8796a8')
        root.style.setProperty('--theme-secondary', '#6e7f92')
        root.style.setProperty('--theme-dark-alt', '#58697d')
        root.style.setProperty('--theme-dark', '#445468')
        root.style.setProperty('--theme-darker', '#324153')
        root.style.setProperty('--neutral-lighter-alt', '#f8f8f8')
        root.style.setProperty('--neutral-lighter', '#f4f4f4')
        root.style.setProperty(' --neutral-light', '#eaeaea')
        root.style.setProperty('--neutral-quaternary-alt', '#dadada')
        root.style.setProperty('--neutral-quaternary', '#d0d0d0')
        root.style.setProperty('--neutral-tertiary-alt', '#c8c8c8')
        root.style.setProperty('--neutral-tertiary', '#a19f9d')
        root.style.setProperty('--neutral-secondary', '#605e5c')
        root.style.setProperty('--neutral-primary-alt', '#3b3a39')
        root.style.setProperty('--neutral-primary', '#323130')
        root.style.setProperty('--neutral-dark', '#201f1e')
        root.style.setProperty('--black', '#000000')
        root.style.setProperty('--white', '#ffffff')
    }
}