import React from 'react';
import { Icon } from '@fluentui/react';

import './index.scss';

export const Warning = ({ deffault, children }) => {
  return (
    <div className="warningContainer" style={deffault ? { fontSize: 14 } : { fontSize: 16 }}>
      <Icon iconName="WarningSolid" className="warningIcon" />
      {children}
    </div>
  );
};
