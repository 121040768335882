import React from 'react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';

export const CLP = ({ children, licNum }: any) => {
  const { data, makeRequest: refetch } = useFetch({
    endpoint: `api/dspusers/licensePlates/${licNum}/checkExistence`,
    // skip: !licNum,
    skip: true,
    method: FetchMethodsEnum.GET,
    onCompleted: async (nextData) => {
      console.log('PUB', data);
    }
  });

  return (
    <>
      {children()}
    </>);
};
