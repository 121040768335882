import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@fluentui/react';
import { mainTheme } from '@/theme/themes';

import './index.scss';

export default function ErrorPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack
      style={{
        flexGrow: 1,
        backgroundColor: mainTheme.semanticColors.bodyBackground,
      }}
      className='pageContainer'
    >
      <span className='errorCode'>404</span>
      <span className='erroeMessage'>{t('global.errorPage')}</span>
    </Stack>
  );
}
