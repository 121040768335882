import React, { useState, useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import renderHtml from 'html-react-parser';

import { Stack, CommandButton, ActionButton } from '@fluentui/react';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { ConfirmDialog } from '@/components/base/ConfirmDialog';
import { AddNewQuestion } from '@/components/base/AddNewQuestion';
import { useMediaQuery } from '@/hooks';

import './index.scss';

type Props = {
  moveCard: any;
  question: any;
  answer: any;
  index: number;
  id: number;
  attachmentUrls: string[];
  onDelete: () => void;
};

export const ExpandPanel = ({ moveCard, id, index, question, answer, attachmentUrls, onDelete }: Props) => {
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const { isMobile, isTabletOrMobile } = useMediaQuery();

  const [open, setOpen] = useState(false);
  const [isHiddenModal, setHiddenModal] = useState(true);
  const [isHiddenAddQuestionModal, setHiddenAddQuestionModal] = useState(true);

  const notifySucces = () => toast.success(t('toasts.deleteQuestionSuccess'));
  const notifyError = () => toast.error(t('toasts.deleteQuestionError'));

  const style = {
    borderBottom: '1px solid #0272AC',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: (index || index === 0) ? 'move' : 'default',
  };

  const { makeRequest: deleteQuestion } = useFetch({
    endpoint: `/api/faqs/${id}`,
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      notifySucces();
      onDelete();
    },
    onError: () => notifyError()
  });

  const [, drop] = useDrop({
    accept: 'card',
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset() || { y: 0 };

      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop(item: any, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      moveCard(dragIndex, hoverIndex, true);
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id: question?.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  if (index || index === 0) {
    drag(drop(ref));
  } else drag(drop(null));

  const togglePanel = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <Stack className="expand-panel" style={isMobile ? { marginBottom: 30, position: 'relative' } : { marginBottom: 15, position: 'relative' }}>
        <CommandButton
          className='commandButton'
          checked
          iconProps={{ iconName: open ? 'ChevronUpSmall' : 'ChevronDownSmall', styles: {
            root: {
              fontSize: '18px',
              margin: '0 10px',
              fontWeight: 'bold',
              ...(isTabletOrMobile ? {
                marginBottom: 'auto',
                paddingTop: '7px',
              } : {}),
            },
          } }}
          style={{ marginRight: 100 }}
          text={question}
          onClick={togglePanel}
        />
        {((index || index === 0) && !isMobile) && (
          <div style={{ position: 'absolute', right: '0', top: 7 }}>
            <ActionButton
              onClick={() => setHiddenAddQuestionModal(false)}
              iconProps={{ iconName: 'Edit', styles: {
                root: {
                  fontSize: '18px',
                  fontWeight: 'bold',
                }
              } }}
            />
            <ActionButton
              onClick={() => setHiddenModal(false)}
              iconProps={{ iconName: 'Delete', styles: {
                root: {
                  fontSize: '18px',
                  fontWeight: 'bold',
                }
              } }}
            />
          </div>
        )}
        {open && (
          <Stack>
            <span className='answer'>
              {
                renderHtml(answer)
              }
            </span>
            { attachmentUrls && attachmentUrls.map((url) => (
              <img
                key={url}
                src={`/api/${url}`}
                alt="answer-image"
                className="attachmentImage"
              />
            ))}
          </Stack>
        )}
      </Stack>
      <ConfirmDialog
        confirmSave={true}
        contentText={t('faq.deleteText')}
        hidden={isHiddenModal}
        onDismiss={() => {
          setHiddenModal(true);
        }}
        onDelete={() => {
          deleteQuestion();
          setHiddenModal(true);
        }}
      />
      <AddNewQuestion
        hidden={isHiddenAddQuestionModal}
        initialValues={{
          question, answer, id
        }}
        onDismiss={() => {
          setHiddenAddQuestionModal(true);
          onDelete();
        }}
      />
    </div>
  );
};
