import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as serviceWorker from './serviceWorker';
import App from './App';

import './index.scss';

// Initialize axios
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '';
} else {
  axios.defaults.baseURL = '';
}

ReactDOM.render(
  <>
    <App />
    <ToastContainer />
  </>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
