import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { Stack, PrimaryButton } from '@fluentui/react';
import { useAuth } from '@/contexts/auth';
import { mainTheme, alternateTheme } from '@/theme/themes';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { ExpandPanel } from '@/components/base/ExpandPanel';
import { AddNewQuestion } from '@/components/base/AddNewQuestion';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import './index.scss';

export default function EditorFAQ(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { userData } = useAuth();
  const idAdmin = userData?.role === 'Administrator';
  
  const [isHiddenModal, setHiddenModal] = useState(true);
  const [questions, setQuestions] = useState<any>([]);
  
  const { makeRequest: refetch, loading } = useFetch({
    endpoint: '/api/faqs',
    method: FetchMethodsEnum.GET,
    onCompleted: setQuestions,
  });

  const { makeRequest: updateList } = useFetch({
    endpoint: '/api/faqs',
    method: FetchMethodsEnum.PUT,
    onCompleted: setQuestions,
  });
  
  const moveCard = useCallback(
    (dragIndex, hoverIndex, isDrop) => {
      const dragCard = questions[dragIndex];
      const newQuestions = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      if (isDrop) {
        updateList({ data: newQuestions });
      }
      setQuestions(newQuestions);
    },
    [questions],
  );

  return (
    <Stack className='faqEditorPage' style={{ backgroundColor: mainTheme.semanticColors.bodyBackground }}>
      <DndProvider backend={HTML5Backend}>
        {loading ? <SpinnerDefault /> : (
          <>
            <div className="faqEditorHeaderButtonsContainer">
              <div className="partOne" />
              { idAdmin && (
                <div className="partTwo">
                  <PrimaryButton
                    onClick={() => history.push('/view-settings')}
                    className='settingsButtonStyle'
                    text={t('tableContent.settingsButton')}
                  />
                  <PrimaryButton
                    className="settingsButtonStyle"
                    onClick={() => history.push('/landing-admin')}
                    text={t('viewSettingsPage.userManager')} 
                  />
                </div>
              )}
            </div>
            <div className="faqButtonsContainer">
              <h2 
                className='title'
                style={{ color: alternateTheme.palette.themeDark }}
              >
                {t('faq.title')}
              </h2>
              {idAdmin && (
                <PrimaryCallToActionButton
                  className="addButton"
                  text={t('faq.addNewQuestionButton')}
                  onClick={() => setHiddenModal(false)}
                />
              )}
            </div>
            {(questions || []).map((question, index) => (
              <div key={question.id}>
                <ExpandPanel
                  index={index}
                  id={question.id}
                  moveCard={moveCard}
                  onDelete={refetch}
                  {...question}
                />
                <AddNewQuestion
                  hidden={isHiddenModal} 
                  id={question.id}
                  onDismiss={async (need: boolean) => {
                    setHiddenModal(true);
                    if (need) {
                      refetch();
                    }
                  }} 
                />
              </div>
            ))}
          </>
        )}
      </DndProvider>
    </Stack>
  );
}
