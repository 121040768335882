import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { useHistory } from 'react-router-dom';
import { useOverflow } from 'use-overflow';
import { toast } from 'react-toastify';

import { useAuth } from '@/contexts/auth';
import {
  Stack, PrimaryButton,
  ShimmeredDetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IDetailsHeaderProps,
  OverflowSet,
  IOverflowSetItemProps,
  CommandBarButton, TooltipHost,
  ActionButton, TextField
} from '@fluentui/react';

import { ChangeInformationDialog, RolesEnum } from '@/components/base/ChangeInformationDialog';
import { ConfirmDialog } from '@/components/base/ConfirmDialog';

import './index.scss';

const EmailComp = ({ item }) => {
  const ref = React.useRef(null);
  const { refXOverflowing } = useOverflow(ref);

  if (!refXOverflowing) {
    return (
      <span ref={ref} className="fieldItem">{item.email}</span>
    );
  }

  return (
    <TooltipHost
      content={item.email}
      id={`email-tooltip-${item.email}`}
      calloutProps={{ gapSpace: 0 }}
      className="tooltip"
    >
      <span ref={ref} className="fieldItem">{item.email}</span>
    </TooltipHost>
  );
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const onRenderItemStyles = {
  root: { padding: '10px' },
};
const onRenderOverflowButtonStyles = {
  root: { padding: '10px', background: 'transparent' },
  menuIcon: { fontSize: '16px' },
};

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      aria-label={item.name}
      styles={onRenderItemStyles}
      iconProps={{ iconName: item.icon }}
      onClick={item.onClick}
    />
  );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      title="Actions"
      styles={onRenderOverflowButtonStyles}
      menuIconProps={{ iconName: 'CollapseMenu' }}
      menuProps={{ items: overflowItems! }}
    />
  );
};

export default function AdvisorPage(): JSX.Element {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [dsp, setDsp] = useState();
  const [hiddenChangeInformation, setHiddenChangeInformation] = useState(true);
  const [hiddenConfirmation, setHiddenConfirmation] = useState(true);
  const history = useHistory();

  const notifySucces = () => toast.success(t('toasts.deleteDSPSuccess'));
  const notifyError = () => toast.error(t('toasts.deleteDSPError'));

  const updateNotifySucces = () => toast.success(t('toasts.changeAccountStatusSuccess'));
  const updateNotifyError = () => toast.error(t('toasts.changeAccountStatusError'));

  const [fields, setFields] = useState([]);
  const { loading, makeRequest: refetch } = useFetch({
    endpoint: '/api/dspusers/',
    method: FetchMethodsEnum.GET,
    queryParams: {
      take: 1000,
    },
    onCompleted: nextData => {
      if (nextData) {
        setFields(nextData);
      }
    }
  });

  const RolesEnums = {
    Administrator: t('global.roles.administrator'),
    Advisor: t('global.roles.advisor')
  };

  const { makeRequest: updateDSPUser } = useFetch({
    endpoint: '/api/dspusers/',
    method: FetchMethodsEnum.PUT,
    onCompleted: () => {
      refetch();
      updateNotifySucces();
    },
    onError: () => {
      updateNotifyError();
    }
  });

  const { makeRequest: deleteDSPUser } = useFetch({
    endpoint: '/api/dspusers/',
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      setHiddenConfirmation(true);
      notifySucces();
      refetch();
    },
    onError: () => {
      setHiddenConfirmation(true);
      notifyError();
    }
  });

  const [searchterm, setSearchterm] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [isAsc, setIsAsc] = useState(true);
  const handleItemClick = (ev: any, column: any) => {
    if (orderBy === column.key && isAsc) {
      setIsAsc(false);
    }
    if (orderBy === column.key && !isAsc) {
      setIsAsc(true);
      setOrderBy('');
    }
    if (orderBy !== column.key) {
      setIsAsc(true);
      setOrderBy(column.key);
    }
  };

  useEffect(() => {
    refetch({
      queryParams: {
        sortfield: capitalize(orderBy),
        IsAscendingSortOrder: isAsc,
        searchterm,
      }
    });
  }, [isAsc, orderBy, searchterm]);

  const handleMenuItemClicked = (nextDsp) => () => {
    setDsp(nextDsp);
    setHiddenChangeInformation(false);
  };

  const handleMenuItem = (nextDsp) => () => {
    setDsp(nextDsp);
    setHiddenConfirmation(false);
  };

  const columns = [
    {
      key: 'companyName',
      name: t('tableContent.companyName'),
      ariaLabel: t(['tableContent.tooltipCompanyName', '']),
      fieldName: 'companyName',
      isSorted: orderBy === 'companyName',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item?.insuranceOffer?.companyInformation?.companyName}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'amazonDspNumber',
      isSorted: orderBy === 'amazonDspNumber',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      name: t('tableContent.DSPNumber'),
      ariaLabel: t(['tableContent.tooltipDSPNumber', '']),
      fieldName: 'DSPNumber',
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item?.insuranceOffer?.companyInformation?.amazonDspNumber}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'contactFirstName',
      isSorted: orderBy === 'contactFirstName',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      name: t('tableContent.firstName'),
      ariaLabel: t(['tableContent.tooltipFirstName', '']),
      fieldName: 'firstName',
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item?.insuranceOffer?.companyInformation?.contactFirstName}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'contactLastName',
      isSorted: orderBy === 'contactLastName',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      name: t('tableContent.lastName'),
      ariaLabel: t(['tableContent.tooltipLastName', '']),
      fieldName: 'lastName',
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item) => {
        return (
          <span className="fieldItem">{item?.insuranceOffer?.companyInformation?.contactLastName}</span>
        );
      },
      isPadded: true,
    },
    {
      key: 'email',
      isSorted: orderBy === 'email',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      name: t('tableContent.email'),
      ariaLabel: t(['tableContent.tooltipEmail', '']),
      fieldName: 'email',
      minWidth: 170,
      maxWidth: 170,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty emails-cell',
      onRender: (item) => {
        return (
          <EmailComp item={item} />);
      },
      isPadded: true,
    },
    {
      key: 'isActive',
      isSorted: orderBy === 'isActive',
      isSortedDescending: !isAsc,
      onColumnClick: handleItemClick,
      name: t('tableContent.active'),
      ariaLabel: t(['tableContent.tooltipActive', '']),
      fieldName: 'isActive',
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <ActionButton iconProps={item.isActive ? { iconName: 'Accept', style: {
            color: 'green'
          } } : {
            iconName: 'ChromeClose', style: {
              color: 'red'
            }
          }}
          />
        );
      },
      isPadded: true,
    },
    {
      key: 'actions',
      name: t('tableContent.actions'),
      ariaLabel: t(['tableContent.tooltipActions', '']),
      fieldName: 'actions',
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        let overflowItems = [];

        if (item.isActive) {
          overflowItems.push({
            key: 'editData',
            name: t('tableContent.editData'),
            onClick: handleMenuItemClicked(item),
          } as never);
        }

        overflowItems = [...overflowItems, ...[
          {
            key: 'showInsurance',
            name: t('tableContent.showInsurance'),
            onClick: () => {
              history.push(`/overview?${item.id}`);
            },
          },
          item.isActive ? ({
            key: 'deactivateAccount',
            name: t('tableContent.deactiveAccount'),
            onClick: () => {
              updateDSPUser({
                endpoint: `/api/dspusers/${item?.id}`,
                data: {
                  ...item,
                  isActive: false,
                }
              });
            },
          }) : ({
            key: 'activateAccount',
            name: t('tableContent.activateAccount'),
            onClick: () => {
              updateDSPUser({
                endpoint: `/api/dspusers/${item?.id}`,
                data: {
                  ...item,
                  isActive: true,
                }
              });
            },
          }),
          {
            key: 'deleteAccount',
            name: t('tableContent.deleteAccount'),
            onClick: handleMenuItem(item),
          },
        ] as never[]];

        return (
          <OverflowSet
            aria-label="menu-bar"
            role="menubar"
            vertical
            overflowItems={overflowItems}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        );
      },
      isPadded: true,
    }
  ];

  return (
    <Stack className='tableContainer'>
      <div className="advisorTableHeaderContainer">
        <PrimaryButton
          onClick={() => history.push('/overview-damage')}
          className='navigationAdminButtonStyles'
          text={t('tableContent.overviewDamage')}
        />
        <TextField
          iconProps={{ iconName: 'Search' }}
          className='searchInput'
          value={searchterm}
          onChange={(e: any) => setSearchterm(e.target.value)}
        />
      </div>
      <ShimmeredDetailsList
        viewport={{
          width: 1150,
          height: 500,
        }}
        styles={{
          root: {
            minWidth: '100vw',
            alignItems: 'center'
          },
        }}
        items={fields}
        columns={columns}
        selectionMode={SelectionMode.none}
        enableShimmer={loading}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderDetailsHeader={(props, defaultHeader) => defaultHeader!({
          ...props,
          onRenderColumnHeaderTooltip: (tooltipHostProps) => {
            return <TooltipHost
              className="header-tooltip"
              styles={{
                root: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              {...tooltipHostProps}
            />;
          },
        } as IDetailsHeaderProps)}

      />
      {!hiddenConfirmation && (
      <ConfirmDialog
        confirmSave={false}
        contentText={t('tableContent.deleteText')}
        unsavedChanges={false}
        hidden={hiddenConfirmation}
        user={dsp}
        onDismiss={() => {
          setHiddenConfirmation(true);
        }}
        onDelete={async (needUpdate) => {
          const { id } = dsp || { id: '' };
          await deleteDSPUser({
            endpoint: `/api/dspusers/${id}`,
          });
        }}
      />
      )}
      {!hiddenChangeInformation && (
        <ChangeInformationDialog
          isAdmin={userData?.role === RolesEnum.Administrator}
          users={dsp}
          hidden={hiddenChangeInformation}
          onDismiss={(needUpdate) => {
            if (needUpdate) {
              refetch();
            }
            setHiddenChangeInformation(true);
          }}
        />
      )}
    </Stack>
  );
}
