/* eslint-disable react/static-property-placement */
/* eslint-disable max-len */
import React, { Fragment, CSSProperties } from 'react';
import { withTranslation } from 'react-i18next';

import axios from 'axios';

import Item from './Item';

const checkPasswordAsync = (value: string): Promise<{
  data: { isStrong: boolean, score: number, errors: string[], feedback: any }
}> => axios.post('/api/passwordSettings/match', {
  value,
});

export interface PasswordStrengthBarProps {
  className?: string;
  style?: {};
  scoreWordClassName?: string;
  password: string;
  userInputs?: string[];
  barColors?: string[];
  minLength?: number;
  onChangeScore?: (score: number) => void;
  onStrong?: (isStrong: boolean) => void;
  [key: string]: any;
}

interface PasswordStrengthBarState {
  score: number;
  suggestions: string[];
}

const rootStyle: CSSProperties = {
  position: 'relative',
};

const wrapStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0 0',
};

const spaceStyle: CSSProperties = {
  width: 4,
};

class PasswordStrengthBar extends React.Component<PasswordStrengthBarProps, PasswordStrengthBarState> {
  static defaultProps: { className: undefined; style: undefined; scoreWordClassName: undefined; password: string; userInputs: never[]; barColors: string[]; minLength: number; onChangeScore: undefined; };
  constructor(props) {
    super(props);
    this.state = {
      score: 0,
      suggestions: [],
    };
  }

  public componentDidMount(): void {
    this.setScore();
  }

  public componentDidUpdate(prevProps: PasswordStrengthBarProps): void {
    const { password } = this.props;
    if (prevProps.password !== password) {
      this.setScore();
    }
  }

  private setScore = async (): Promise<void> => {
    const { password, minLength, onStrong } = this.props;
    if (password.length >= 0) {
      const { data: { isStrong, score, feedback } } = await checkPasswordAsync(password);
      const { suggestions } = (feedback || {}) as any;
      this.setState({ suggestions, score: score + 1 });
      onStrong!(isStrong);
    } else if (password.length === 0) {
      this.setState({ score: 0, suggestions: [] });
      onStrong!(false);
    }
  };

  public render(): React.ReactNode {
    const {
      className,
      style,
      barColors,
      t
    } = this.props;
    const { score, suggestions } = this.state;

    return (
      <div className={className} style={{ ...rootStyle, ...style }}>
        <div style={wrapStyle}>
          {[1, 2, 3, 4, 5].map((el: number) => {
            return (
              <Fragment key={`password-strength-bar-item-${el}`}>
                {el > 1 && <div style={spaceStyle} />}
                <Item score={score} itemNum={el} barColors={barColors} />
              </Fragment>
            );
          })}
        </div>
        {suggestions?.map(suggestion => (
          <li key={suggestion} style={{ margin: '10px 10px 0 10px' }} className="fileListDefaultStyle">
            {t(`password.${suggestion}`)}
          </li>
        ))}
      </div>
    );
  }
}

PasswordStrengthBar.defaultProps = {
  className: undefined,
  style: undefined,
  scoreWordClassName: undefined,
  password: '',
  userInputs: [],
  barColors: ['#ddd', '#EF4836', '#F68A4D', '#F6E54D', '#8DEF2B', '#089D5F'],
  minLength: 5,
  onChangeScore: undefined,
};

export default withTranslation()(PasswordStrengthBar as any) as any;