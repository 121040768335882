import { StylesConfig } from 'react-select';
import { mainTheme } from '@/theme/themes';

export function useThemedSelectStyle(): StylesConfig {
  return {
    control: (_provided, state) => ({
      color: mainTheme.semanticColors.inputText,
      backgroundColor: state.isDisabled
        ? mainTheme.semanticColors.disabledBackground
        : mainTheme.semanticColors.inputBackground,
      minHeight: 'initial',
      height: '32px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderColor: state.isDisabled
        ? mainTheme.semanticColors.disabledBackground
        : state.isFocused
          ? mainTheme.palette.themePrimary
          : mainTheme.semanticColors.inputBorder,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '2px',
      WebkitBoxAlign: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      cursor: 'text',
      outline: '0 !important',
      boxShadow: state.isFocused
        ? `0 0 0 1px ${mainTheme.palette.themePrimary}`
        : 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '2px',
      borderWidth: 0,
      boxShadow: mainTheme.effects.elevation16,
      marginTop: 1,
      background: mainTheme.semanticColors.menuBackground,
      color: mainTheme.semanticColors.menuItemText,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      minheight: '36px',
      color: state.isFocused
        ? mainTheme.semanticColors.menuItemTextHovered
        : mainTheme.semanticColors.menuItemText,
      backgroundColor: state.isFocused
        ? mainTheme.semanticColors.menuItemBackgroundHovered
        : mainTheme.semanticColors.menuBackground,
    }),
    indicatorSeparator: () => ({
      width: '1px',
      backgroundColor: mainTheme.semanticColors.menuItemBackgroundHovered,
      alignSelf: 'stretch',
      marginTop: mainTheme.spacing.s1,
      marginBottom: mainTheme.spacing.s1,
    }),
    dropdownIndicator: (_provided, state) => ({
      padding: '4px',
      display: 'flex',
      color: state.isDisabled
        ? mainTheme.semanticColors.disabledBodySubtext
        : mainTheme.semanticColors.bodySubtext,
      backgroundColor: state.selectProps.menuIsOpen
        ? mainTheme.semanticColors.menuItemBackgroundHovered
        : 'transparent',
    }),
    clearIndicator: () => ({
      padding: '4px',
      display: 'flex',
      cursor: 'default',
      color: mainTheme.semanticColors.bodySubtext,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: mainTheme.semanticColors.inputText,
    }),
    input: (provided) => ({
      ...provided,
      color: mainTheme.semanticColors.inputText,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? mainTheme.semanticColors.disabledBodySubtext
        : mainTheme.semanticColors.bodySubtext,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: mainTheme.semanticColors.bodySubtext,
    }),
  };
}
