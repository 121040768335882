import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from '@/contexts/auth';

import { mainTheme } from '@/theme/themes';
import LandingPage from '@/components/pages/Landing';
import WizardPage from '@/components/pages/Wizard';
import ContactPage from '@/components/pages/Contact';
import LoginPage from '@/components/pages/Login';
import SetPasswordPage from '@/components/pages/SetPassword';
import LogoutPage from '@/components/pages/Logout';
import OverviewPage from '@/components/pages/Overview';
import OverviewDamagePage from '@/components/pages/OverviewDamageAdvisor';
import OverviewDamageDSP from '@/components/pages/OverviewDamageDSP';
import OverviewDetailsPage from '@/components/pages/OverviewDetails';
import EditorFAQ from '@/components/pages/EditorFAQ';
import FAQPage from '@/components/pages/FAQ';
import AdvisorPage from '@/components/pages/Advisor';
import AdminPage from '@/components/pages/Admin';
import ViewSettingPage from '@/components/pages/ViewSetting';
import ErrorPage from '@/components/pages/Error';
import ChangePasswordPage from '@/components/pages/ChangePassword';
import ForgotPasswordPage from '@/components/pages/ForgotPassword';
import AddVehicle from '@/components/pages/AddVehicle';
import AdditionalInsurance from '@/components/pages/AdditionalInsurance';

const ProtectedRoute = ({ roles, ...otherProps }) => {
  const { userData } = useAuth();
  const role = userData?.role;

  if (role && roles.includes(role)) {
    return <Route {...otherProps} />;
  }
  return userData ? <Route {...otherProps} component={() => <Redirect to="/" />} /> : null;
};

export default function RoutedContent(): JSX.Element {

  return (
    <main
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: mainTheme.palette.neutralLight,
      }}
    >
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path='/wizard' component={WizardPage} />
        <Route path='/login' component={LoginPage} />
        <Route path='/dspusers/confirm' component={LoginPage} />
        <Route path='/users/confirm' component={SetPasswordPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/contact" component={ContactPage} />
        <ProtectedRoute roles={['DSP', 'Advisor']} exact path="/overview" component={OverviewPage} />
        <ProtectedRoute roles={['Advisor']} exact path="/overview-detail/:id" component={OverviewDetailsPage} />
        <ProtectedRoute roles={['DSP']} exact path="/overview-detail" component={OverviewDetailsPage} />
        <ProtectedRoute roles={['Advisor']} exact path="/overview-damage" component={OverviewDamagePage} />
        <ProtectedRoute roles={['DSP']} exact path="/overview-damage-dsp" component={OverviewDamageDSP} />
        <Route exact path="/faq" component={FAQPage} />
        <ProtectedRoute roles={['Administrator']} exact path="/editor" component={EditorFAQ} />
        <ProtectedRoute roles={['Advisor']} exact path="/landing-advisor" component={AdvisorPage} />
        <ProtectedRoute roles={['Administrator']} exact path="/landing-admin" component={AdminPage} />
        <ProtectedRoute roles={['Administrator']} exact path="/view-settings" component={ViewSettingPage}  />
        <Route exact path="/change-password" component={ChangePasswordPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <ProtectedRoute roles={['DSP']} exact path="/add-vehicle" component={AddVehicle} />
        <ProtectedRoute roles={['Advisor']} exact path="/add-vehicle/:id" component={AddVehicle} />
        <ProtectedRoute roles={['DSP', 'Advisor']} exact path="/additional-insurance" component={AdditionalInsurance} />
        <Route path='/*' component={ErrorPage} />
      </Switch>
    </main>
  );
}
