/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { CSSProperties } from 'react';
import { Stack, Checkbox, IStackProps } from '@fluentui/react';
import { mainTheme } from '@/theme/themes';
import { formatCurrency } from '@/utils/formating';
import { PriceByAmount } from '@/components/pages/Wizard/priceByAmount';
import { useTranslation } from 'react-i18next';
import { useCalculation } from '@/contexts/calculation';

interface Types {
  label: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  priceAmountOptions: PriceAmountOptions;
  subHeaderRows?: string[];
}

export interface PriceAmountOptions {
  options: PriceByAmount[];
  optionLabelTemplate: string;
  withoutValue?: boolean;
}

export default function ServiceOption(props: Types) {
  const { t } = useTranslation();

  const serviceItemStack: IStackProps = {
    tokens: {
      childrenGap: 4,
    },
  };

  const amountOptionsStyle: CSSProperties = {
    marginLeft: mainTheme.spacing.m,
  };

  const baseFontStyle: CSSProperties = {
    color: '#888888',
    fontSize: mainTheme.fonts.small.fontSize,
  };

  const labelStyle: CSSProperties = {
    ...baseFontStyle,
    paddingRight: mainTheme.spacing.s2,
  };

  const { mountly } = useCalculation()
  const selectedPrice = mountly ? 'monthlyPrice' : 'annualPrice';

  return (
    <Stack {...serviceItemStack}>
      <Checkbox
        label={props.label}
        checked={props.isChecked}
        onChange={(_: any, checked?: boolean) =>
          props.onChange(checked == null ? false : checked)
        }
      />
      <table style={amountOptionsStyle}>
        <tbody>
          {props.subHeaderRows &&
            props.subHeaderRows.map((text, index) => (
              <tr key={`SubHeader_${index}`}>
                <td style={labelStyle}>{text}</td>
              </tr>
            ))}
          {props.priceAmountOptions &&
            props.priceAmountOptions.options.map((tier, index) => (
              <tr key={index}>
                <td style={labelStyle}>
                  {t([`${props.priceAmountOptions!.optionLabelTemplate}_${index}`, props.priceAmountOptions!.optionLabelTemplate], {
                    amount: tier.amount?.toString(),
                    amountTo: tier.amountTo?.toString(),
                    context: tier.amountTo && tier.amountTo > 0 ? 'to' : '',
                  })
                  }
                  {!tier.onlyLabel && ':'}
                </td>
                {!tier.onlyLabel && (
                  <td style={baseFontStyle}>
                    {formatCurrency(tier.prices[selectedPrice])}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </Stack>
  );
}
