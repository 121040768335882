export default class WindowWidthInfo {
  static readonly SmallBreakpoint: number = 576;
  static readonly LargeBreakpoint: number = 1200;

  readonly isSmall: boolean;
  readonly isMedium: boolean;
  readonly isLarge: boolean;

  constructor(readonly windowWidth: number) {
    if (this.windowWidth < WindowWidthInfo.SmallBreakpoint) {
      this.isSmall = true;
      this.isMedium = false;
      this.isLarge = false;
    } else if (this.windowWidth > WindowWidthInfo.LargeBreakpoint) {
      this.isLarge = true;
      this.isSmall = false;
      this.isMedium = false;
    } else {
      this.isMedium = true;
      this.isSmall = false;
      this.isLarge = false;
    }
  }
}

export const defaultWindowWidth = new WindowWidthInfo(window.innerWidth);
