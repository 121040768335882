import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

export function SpinnerDefault(): JSX.Element {
  return (
    <Spinner
      size={SpinnerSize.large}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }}
    />
  );
}
