import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton } from '@fluentui/react';
import { insuranceCardStyle } from './VehicleInsuranceCard';

interface Types {
  onCreateInsurance: () => void;
}

export default function CreateInsuranceCard(props: Types) {
  const { t } = useTranslation();

  const newInsuranceCardStyle: CSSProperties = {
    // ...insuranceCardStyle,
    minHeight: '100px',
  };

  return (
    <Stack
      style={newInsuranceCardStyle}
      horizontalAlign="center"
      verticalAlign="center"
    >
      <PrimaryButton
        iconProps={{ iconName: 'add' }}
        onClick={props.onCreateInsurance}
        text={t('wizardStepOne.addVehicleInsurance')}
      />
    </Stack>
  );
}
