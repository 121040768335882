import get from 'lodash.get';

export const validateMoney = (value: string): boolean => {
  if (!value) {
    return true;
  }
  return /^([0-9]{1,3}.([0-9]{3}.)*[0-9]{3}|[0-9]+)(,[0-9]{1,2})?$/.test(value);
};

const EMAIL_REG = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const validateEmails = (value: string): boolean => {
  if (!value) {
    return true;
  }

  return Boolean(value.match(EMAIL_REG));
};

export function validateEmail(value: string): boolean {
  return EMAIL_REG.test(value);
}

export function validateNumericOnly(value: string | undefined): boolean {
  if (!value) {
    return true;
  }
  return new RegExp('^\\d+$').test(`${value}`);
}

export const validateTSN = (value:string | undefined): boolean => {
  if (!value) {
    return true;
  }
  return new RegExp('^[A-Z]{3}$').test(`${value.toUpperCase()}`);
};

export const validateLicNum = (value:string | undefined): boolean => {
  if (!value) {
    return true;
  }
  return new RegExp('^[A-Z]{1,3}\\s[A-Z]{1,2}\\s\\d{1,4}[E]?$').test(`${value.toUpperCase()}`);
};

export const validateHSN = (value:string | undefined): boolean => {
  if (!value) {
    return true;
  }
  return new RegExp('^\\d{4}$').test(`${value}`);
};

export const validateFINOrVIN = (value:string | undefined): boolean => {
  if (!value) {
    return true;
  }

  return new RegExp('^[A-HJ-NPR-Z|\\d]{17}$').test(`${value.toUpperCase()}`);
};

export function validateIban(value: string): boolean {
  if (!value) {
    return true;
  }
  return (
    value.startsWith('de') ||
    value.startsWith('DE') ||
    value.startsWith('De') ||
    value.startsWith('dE')) && value.length === 22;
}

export function getRequiredError(
  errors: any,
  name: string,
  errorMessage: string
): string {
  return errors[name] && errors[name].type === 'required' ? errorMessage : '';
}

export function validatePassword(value: string): boolean {
  if (!value) {
    return true;
  }
  return ( value.length > 9 );
}

export function getConfirmPasswordError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {
  const error = get(errors, name);
  if (!error) {
    return '';
  }

  switch (error.type) {
    case 'required':
      return t('validation.getPasswordRequiredError');
    case 'sameAsPassword':
      return t('validation.sameAsPasswordError');
    default:
      return '';
  }
}

export function getNumericOnlyError(
  errors: any,
  name: string,
  errorMessage: string
): string {
  return errors[name] && errors[name].type === 'numericOnly'
    ? errorMessage
    : '';
}

export function getEmailError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {
  const error = get(errors, name);

  if (!error) {
    return '';
  }

  switch (error.type) {
    case 'required':
      return t('validation.emailRequiredError');
    case 'emailFormat':
      return t('validation.emailFormatError');
    default:
      return '';
  }
}

export function getPostalCodeError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {
  if (!errors[name]) {
    return '';
  }

  switch (errors[name].type) {
    case 'required':
      return t('validation.postalRequiredCodeError');
    case 'isPostalCode':
      return t('validation.postalCodeError');
    default:
      return '';
  }
}

export function getIbanError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {
  if (!errors[name]) {
    return '';
  }

  switch (errors[name].type) {
    case 'required':
      return t('validation.getIbanRequiredError');
    case 'iban':
      return t('validation.getIbanError');
    default:
      return '';
  }
}

export function getPasswordError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {
  if (!errors[name]) {
    return '';
  }

  switch (errors[name].type) {
    case 'required':
      return t('validation.getPasswordRequiredError');
    case 'password':
      return t('validation.getPasswordError');
    default:
      return '';
  }
}

export function getNotSamePasswordError(
  errors: any,
  name: string,
  t: (key: string) => string,
): string {

  switch (errors[name]?.type) {
    case 'notSame':
      return t('validation.notSamePassword');
    default:
      return '';
  }
}

export const getError = (errors: any, name: string, t: (key: string) => string, forceType?: string | null ): string => {
  const error =  forceType ? { type: forceType } : get(errors, name);

  switch(error?.type) {
    case 'required':
      return t('validation.required');
    case 'tsn':
      return t('validation.errorValidationTSN');
    case 'hsn':
      return t('validation.errorValidationHSN');
    case 'finOrVin':
      return t('validation.errorValidationFinOrVin');
    case 'licNum':
      return t('validation.errorValidationLicNum');
    case 'duplicate':
      return t('validation.errorDuplicateLicenceNumber');
    case 'money':
      return t('validation.moneyFormat');
    default:
      return '';
  }
};