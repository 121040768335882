import React from 'react';

import { Stack } from '@fluentui/react';
import { ForgotPassword } from '@/components/forms/ForgotPassword';

export default () => {
  return (
    <Stack>
      <ForgotPassword hidden={false} />
    </Stack>
  );
};

