import React, {
  CSSProperties,
  useEffect, useState,
  useCallback, useMemo
} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'office-ui-fabric-react';
import { useDropzone } from 'react-dropzone';

import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import OptionalTextField from '@/components/base/Optional/OptionalTextField';
import OptionalLabel from '@/components/base/Optional/OptionalLabel';
import { dayPickerStrings, onFormatDate, dayPickerStringsGerman } from '@/utils/calendar';
import { useAuth } from '@/contexts/auth';
import { hasErrors } from '@/utils/hasErrors';

import {
  Dialog, PrimaryButton, ActionButton,
  TextField, Spinner, getTheme
} from '@fluentui/react';

import {
  getRequiredError,
  validateEmail,
  getEmailError,
} from '@/utils/validations';

import { checkComplete } from './complete';

import './index.scss';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: getTheme().semanticColors.inputBorder,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  color: getTheme().semanticColors.primaryButtonBackground,
  borderColor: getTheme().semanticColors.primaryButtonBackground,
  borderStyle: 'solid',
};

const rejectStyle = {
  borderColor: '#ff1744'
};


const defaultValues = {
  file: [],
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  damageDescription: '',
  date: new Date(),
  place: '',
  whatWasDamaged: '',
  otherFirstName: '',
  otherLastName: '',
  phoneNumber: '',
  otherEmail: ''
};

export const ReportDamageAdditionalProducts = ({
  hidden,
  onDismiss,
  vehicleId,
  userId,
  dspNumber,
  companyName,
  typeDamage,
  onListChanged,
  defaultId,
}) => {
  const { handleSubmit, control, errors, watch, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const fv = watch();
  
  const notifySucces = () => toast.success(t('toasts.submitReportDamageSuccess'));
  const notifyError = () => toast.error(t('toasts.submitReportDamageError'));

  const { t, i18n } = useTranslation();
  const [fileNames, setFileNames] = useState([] as string[]);
  const [fileLinks, setFileLinks] = useState([] as string[]);

  useEffect(() => {
    reset(defaultValues);
    setFileNames([]);
  }, [hidden]);


  const onDrop = useCallback(acceptedFiles => {
    setValue('file', [ ...fv.file, ...acceptedFiles]);
    setFileNames([...fv.file, ...acceptedFiles].map(file => file.name));
  }, [setValue, fv.file]);

  const { data } = useFetch({
    endpoint: '/api/dspusers/documents/formats',
    method: FetchMethodsEnum.GET,
  });
  
  const acceptFormats = `.${data?.join(', .')}`;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptFormats, onDrop, multiple: true });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]) as CSSProperties;

  const [damageReportId, setDamageReportId] = useState(defaultId);

  const values: any = watch();
  const [r, setR] = useState({});
  const [isSent, setSent] = useState();
  const { makeRequest: refetch, loading: Loader } = useFetch({
    endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports`,
    method: FetchMethodsEnum.GET,
    onCompleted: data => {
      // eslint-disable-next-line no-shadow
      const r = (data || [])?.find(({ id }) => id === damageReportId);
  
      if (!r?.id) {
        setDamageReportId(undefined);
        reset(defaultValues);
        setR({});
      } else {
        setSent(r?.isSent);
        setFileLinks(r?.attachmentUrls);
        setDamageReportId(r?.id);

        // eslint-disable-next-line no-shadow
        const fv = {
          file: '',
          firstName: r?.driverInformation?.firstName || '',
          lastName: r?.driverInformation?.lastName || '',
          email: r?.driverInformation?.email || '',
          telephone: r?.driverInformation?.telephone || '',
          damageDescription: r?.damageDescription || '',
          date: moment(r?.damageInformation?.date).toDate() || moment().toISOString(),
          place: r?.damageInformation?.place || '',
          whatWasDamaged: r?.damageInformation?.whatWasDamaged || '',
          otherFirstName: r?.otherPartyData?.firstName || '',
          otherLastName: r?.otherPartyData?.lastName || '',
          phoneNumber: r?.otherPartyData?.telephone || '',
          otherEmail: r?.otherPartyData?.email || '',
        };
        reset(fv as any);
        setR(fv);
        
      }
      if (onListChanged) {
        onListChanged();
      }
    }
  });

  useEffect(() => {
    setDamageReportId(damageReportId);
  }, [damageReportId]);

  useEffect(() => {
    if (damageReportId) {
      refetch();
    }
  }, [damageReportId]);


  const { makeRequest: saveReportDamage, loading } = useFetch({
    endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports${damageReportId ? `/${damageReportId}` : ''}`,
    method: damageReportId ? FetchMethodsEnum.PUT : FetchMethodsEnum.POST,
    // data: values2data(values),
    onCompleted: (data) => {
      if (onListChanged) {
        onListChanged();
      }
      setDamageReportId(data.id);

    }
  });
  
  const { makeRequest: sendReportDamage, loading: sending } = useFetch({
    endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports/${damageReportId}/sendData`,
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      notifySucces();
      if (onListChanged) {
        onListChanged();
      }

    },
    onError: () => {
      notifyError();
    }
  });

  const { makeRequest: deleteReportDamage, loading: deliting } = useFetch({
    endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports/${damageReportId}`,
    method: FetchMethodsEnum.DELETE,
    onCompleted: () => {
      reset(defaultValues);
      setDamageReportId(undefined);

      if (onListChanged) {
        onListChanged();
      }
    }
  });

  const handleSend = async () => {
    const formData = new FormData();
    if (values.file) {
      values.file.forEach((attachment) => {
        formData.append('attachments', attachment);
      });
    }
  
    formData.append('damageReportDto.damageDescription', values.damageDescription || '');

    formData.append('damageReportDto.driverInformation.firstName', values.firstName || '');
    formData.append('damageReportDto.driverInformation.lastName', values.lastName || '');
    formData.append('damageReportDto.driverInformation.telephone', values.telephone || '');
    formData.append('damageReportDto.driverInformation.email', values.email || '');
    
    formData.append('damageReportDto.damageInformation.date', `${moment(values?.date).format('YYYY-MM-DDT00:00:00.000')}Z`);
    formData.append('damageReportDto.damageInformation.place', values?.place || '');
    formData.append('damageReportDto.damageInformation.whatWasDamaged', values?.whatWasDamaged || '');
    
    formData.append('damageReportDto.otherPartyData.firstName', values?.otherFirstName || '');
    formData.append('damageReportDto.otherPartyData.lastName', values?.otherLastName || '');
    formData.append('damageReportDto.otherPartyData.telephone', values?.phoneNumber || '');
    formData.append('damageReportDto.otherPartyData.email', values?.otherEmail || '');


    const { id } = await saveReportDamage({
      endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports${damageReportId ? `/${damageReportId}` : ''}`,
      formData,
    });

    setDamageReportId(id);

    await sendReportDamage({
      endpoint: `/api/dspusers/${userId}/insurance/${vehicleId}/damageReports/${id}/sendData`,
      formData,
    });

    onDismiss();
  };

  const complete = checkComplete(values);

  const { token } = useAuth();
  const downloadFile = (url) => async () => {
    const fileName = url.split('attachments/')[1];
    const link = document.createElement('a');

    link.setAttribute('download', `${fileName}`);
    link.setAttribute('target', '_blank');
    
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const mime = response.headers['content-type'];
    const blob = new Blob([response.data], { type: mime });
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;
    
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.open(objectUrl, '_blank');
  };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => {
        onDismiss();
        reset(defaultValues);
      }}
      minWidth="550px"
      maxWidth="600px"
      modalProps={{
        isBlocking: true,
        className: 'reportDamageContainer',
        layerProps: { eventBubblingEnabled: true },
      }}
    >
      <form className='reportDamageForm'>
        <div className="rowTitle">{t('reportDamageAdditionalProducts.damageReportTitle')}</div>
        <div className='rowContainer'>
          <TextField
            disabled
            label={t('reportDamageAdditionalProducts.DSPNumber')}
            className='fieldDisabled'
            value={dspNumber}
          />
          <TextField
            disabled
            label={t('reportDamageAdditionalProducts.companyName')}
            className='fieldDisabled'
            value={companyName}
          />
          <TextField
            disabled
            label={t('reportDamageAdditionalProducts.typeDamage')}
            className='fieldDisabled'
            value={typeDamage}
          />
        </div>
        <div className="rowTitle">{t('reportDamageAdditionalProducts.driversInformationTitle')}</div>
        <div className='blockContainer'>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamageAdditionalProducts.firstName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'firstName',
                  t('reportDamageAdditionalProducts.firstNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamageAdditionalProducts.lastName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'lastName',
                  t('reportDamageAdditionalProducts.lastNameErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="email"
            control={control}
            rules={{
              validate: {
                emailFormat: validateEmail,
              },
            }}
            as={
              <OptionalTextField
                disabled={isSent}
                label={t('reportDamageAdditionalProducts.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />

          <Controller
            name="telephone"
            control={control}
            rules={{}}
            as={
              <OptionalTextField
                label={t('reportDamageAdditionalProducts.phoneNumber')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'telephone',
                  t('reportDamageAdditionalProducts.telephoneErrorMessage')
                )}
              />
            }
          />
        </div>

        <div className="rowTitle">{t('reportDamageAdditionalProducts.informationAboutDamadeTitle')}</div>
        <div className='blockContainer'>
          <Controller
            name="date"
            control={control}
            as={
              <DatePicker
                disableAutoFocus
                strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
                placeholder={t('overviewDetail.deregister.placeholder.date')}
                label={t('reportDamageAdditionalProducts.date')}
                formatDate={onFormatDate}
                disabled={isSent}
                maxDate={new Date()}
                onSelectDate={(val) => {
                  setValue('date', val as any);
                }}
              />
            }
          />
          <Controller
            name="place"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamageAdditionalProducts.place')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'place',
                  t('reportDamageAdditionalProducts.placeErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="whatWasDamaged"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamageAdditionalProducts.whatWasDamaged')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'whatWasDamaged',
                  t('reportDamageAdditionalProducts.damagedErrorMessage')
                )}
              />
            }
          />
        </div>
        <div className="rowTitle">{t('reportDamageAdditionalProducts.dataOnTheParty')}</div>
        <div className='blockContainer'>
          <Controller
            name="otherFirstName"
            control={control}
            as={
              <OptionalTextField
                label={t('reportDamageAdditionalProducts.otherFirstName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'firstName',
                  t('reportDamageAdditionalProducts.firstNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="otherLastName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('reportDamageAdditionalProducts.lastName')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'lastName',
                  t('reportDamageAdditionalProducts.lastNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="phoneNumber"
            control={control}
            as={
              <OptionalTextField
                label={t('reportDamageAdditionalProducts.phoneNumber')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'phoneNumber',
                  t('reportDamageAdditionalProducts.phoneNumberErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="otherEmail"
            control={control}
            as={
              <OptionalTextField
                label={t('reportDamageAdditionalProducts.email')}
                disabled={isSent}
                errorMessage={getRequiredError(
                  errors,
                  'email',
                  t('reportDamageAdditionalProducts.emailErrorMessage')
                )}
              />
            }
          />
        </div>
        <div className="rowTitle">{t('reportDamageAdditionalProducts.appendicesAndNotes')}</div>
        <div className='blockContainer'>
          <Controller
            name="damageDescription"
            control={control}
            as={
              <TextField
                multiline
                disabled={isSent}
                label={t('reportDamageAdditionalProducts.notes')}
              />
            }
          />
          <Controller
            name="file"
            control={control}
            as={
              <div>
                <OptionalLabel>{t('reportDamageAdditionalProducts.appendices')}</OptionalLabel>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} disabled={isSent} />  
                  <p>{t('overviewDetail.deregister.placeholder.upload')}</p>
                </div>
                {fileLinks?.length === 0 && fileNames.map(fileName => (
                  <div key={fileName} style={{ display: 'flex', justifyContent: 'space-between', maxHeight: 25 }}>
                    <li key={fileName} className="fileListDefaultStyle">
                      {fileName}
                    </li>
                    <ActionButton
                      onClick={() => {
                        if (fileNames.length > 0) {
                          setFileNames(fileNames.filter((name) => name !== fileName));
                          setValue('file', values.file.filter(({ name }) => name !== fileName));
                        }
                      }}
                      iconProps={{ iconName: 'ChromeClose', styles: {
                        root: {
                          fontSize: '10px',
                          height: 25,
                          cursor: 'poiner'
                        }
                      } }}
                    />
                  </div>
                ))}
                {fileLinks.map((link) => (
                  <li
                    className="fileListStyle"
                    key={link}
                    onClick={downloadFile(`${axios.defaults.baseURL}/api/dspusers/${userId}/insurance/${vehicleId}/${link}`)}
                  >
                    {link.split('/').pop()}
                  </li>
                ))}
              </div>
            }
          />
          <div style={{ color: 'red', fontSize: 14, marginTop: 15 }}>
            {t('reportDamageAdditionalProducts.footerDescription')}
          </div>
        </div>
        <div className='buttonBlock'>
          <div>
            <PrimaryButton
              className='actionButtonStyle'
              text={t('global.cancel')}
              disabled={isSent}
              onClick={() => {
                deleteReportDamage();
                onDismiss();
              }}
            />
          </div>
          <div className="funcionButtonContainer">
            {(Loader || loading || sending || deliting) && (
              <Spinner />
            )}
            <PrimaryButton
              className='actionButtonStyle'
              disabled={!complete || hasErrors(errors) || isSent}
              onClick={handleSend}
              text={t('global.send')}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};
