/* eslint-disable radix */
import React, { CSSProperties, useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Stack, IStackProps, PrimaryButton,
  ChoiceGroup, SpinButton,
  IChoiceGroupOption, Text,
} from '@fluentui/react';

import { mainTheme } from '@/theme/themes';
import { useAuth } from '@/contexts/auth';
import { useWindowWidthContext } from '@/hooks';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { formatCurrency } from '@/utils/formating';
import { useCalculation } from '@/contexts/calculation';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import { sortArrayAsc, sortArrayDesc } from '@/utils/sortArray';
import { AdditionalServicesDetails } from '@/utils/services/AdditionalServices/additionalServicesDetails';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';

import ServiceOption from '@/utils/services/AdditionalServices/serviceOption';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import { InsuranceMasterDataDto } from '../Wizard/insuranceMasterDataDto';
import { createDefaultInsurance } from '../Wizard/InsureVehicles/createDefaultInsurance';

interface Types {
  additionalServices: AdditionalServicesDetails;
  onChange: (details: AdditionalServicesDetails) => void;
  masterData: InsuranceMasterDataDto;
  amountOfVehicles: number;
}

export default function AdditionalInsurance(props: Types) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { authorized, userData } = useAuth();
  const { mountly, paymentMethod } = useCalculation();
  const [masterData, setMasterData] = useState<InsuranceMasterDataDto>();

  const [insurances, setInsurances] = useState<VehicleInsuranceDetails[]>();
  const [additionalServices, setAdditionalServices] = useState<AdditionalServicesDetails>();
  const [amountOfInsuredVehicles, setAmountOfInsuredVehicles] = useState<number>(0);

  const nameId = location.search.replace('?', '');


  const notifySucces = () => toast.success(t('toasts.addAdditionalInsuranceSuccess'));
  const notifyError = () => toast.error(t('toasts.addAdditionalInsuranceError'));

  const { makeRequest: saveIt } = useFetch({
    endpoint: userData?.role === 'DSP' ? `/api/dspusers/${userData?.nameid}/additionalInsurances` : `/api/dspusers/${nameId}/additionalInsurances`,
    method: FetchMethodsEnum.POST,
    data: {

      'businessLiabilityInsurance': additionalServices?.businessLiabilityInsurance?.price ? ({
        'numberOfEmployers': additionalServices?.amountOfEmployees,
        'pricePerEmployer': (additionalServices ? (
          additionalServices.businessLiabilityInsurance.price / additionalServices.amountOfEmployees
        ) : 0) || 0,
        'netTotal': (additionalServices ? (
          Number((additionalServices.businessLiabilityInsurance.price).toFixed(2))
        ) : 0) || 0,
        'netTotalWithTaxes': (additionalServices ? (
          Number((additionalServices.businessLiabilityInsurance.price * 1.19).toFixed(2))
        ) : 0) || 0,
      }) : null,
      'driverLegalProtection': additionalServices?.driverLegalProtection?.price ? ({
        'numberOfVehicles': additionalServices?.amountOfVehicles,
        'pricePerVehicle': (additionalServices ? (
          additionalServices.driverLegalProtection.price / additionalServices.amountOfVehicles
        ) : 0) || 0,
        'deductible': additionalServices?.participationAmount.driver,
        'netTotal': Number((additionalServices?.driverLegalProtection.price).toFixed(2)),
        'netTotalWithTaxes': Number((additionalServices?.driverLegalProtection.price * 1.19).toFixed(2))
      }) : null,
      'groupAccidentInsurance': additionalServices?.groupAccidentInsurance?.price ? ({
        'numberOfEmployers': additionalServices?.amountOfEmployees,
        'pricePerEmployer': (additionalServices ? (
          additionalServices.groupAccidentInsurance.price / additionalServices.amountOfEmployees
        ) : 0) || 0,
        'netTotal': Number((additionalServices?.groupAccidentInsurance.price).toFixed(2)),
        'netTotalWithTaxes': Number((additionalServices?.groupAccidentInsurance.price * 1.19).toFixed(2))
      }) : null,
      'trafficLegalProtection': additionalServices?.trafficLegalProtection?.price ? ({
        'numberOfVehicles': additionalServices?.amountOfVehicles,
        'deductible': additionalServices?.participationAmount.traffic,
        'pricePerVehicle': (additionalServices ? (
          additionalServices.trafficLegalProtection.price / additionalServices.amountOfVehicles
        ) : 0) || 0,
        'netTotal': Number((additionalServices?.trafficLegalProtection.price).toFixed(2)),
        'netTotalWithTaxes': Number((additionalServices?.trafficLegalProtection.price * 1.19).toFixed(2))
      }) : null,
      'trafficLiablilityInsurance': additionalServices?.trafficLiabilityInsurance?.price ? ({
        'numberOfVehicles': additionalServices?.amountOfVehicles,
        'pricePerVehicle': (additionalServices ? (
          additionalServices.trafficLiabilityInsurance.price / additionalServices.amountOfVehicles
        ) : 0) || 0,
        'netTotal': Number((additionalServices?.trafficLiabilityInsurance?.price).toFixed(2)),
        'netTotalWithTaxes': Number((additionalServices?.trafficLiabilityInsurance?.price * 1.19).toFixed(2))
      }) : null,
      'netTotalWithTaxes': additionalServices?.costsOfServices ? (Number((additionalServices?.costsOfServices * 1.19).toFixed(2))) : (0),
      'netTotal': additionalServices?.costsOfServices,
    },
    onCompleted: () => {
      notifySucces();
      if(userData?.role === 'DSP') {
        history.push('/overview');
      } else {
        history.push(`/overview?${nameId}`);
      }
    },
    onError: () => {
      notifyError();
    }
  });

  const [unlocked, setUnlocks] = useState({
    publicLiabilityInsurance: false,
    trafficLegalProtectionInsurance: false,
    accidentInsurance: false,
    trafficLiabilityInsurance: false,
  });

  const { data: allAdditionalInsurance } = useFetch({
    endpoint: userData?.role === 'DSP' ? `/api/dspusers/${userData?.nameid}` : `/api/dspusers/${nameId}`,
    skip: !userData?.nameid || !authorized || !masterData,
    method: FetchMethodsEnum.GET,
    onCompleted: data => {
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        (
          data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance?.numberOfEmployers ||
          data?.insuranceOffer?.additionalProducts?.groupAccidentInsurance?.numberOfEmployers
        ),
        {
          driver: data?.insuranceOffer?.additionalProducts?.driverLegalProtection?.deductible || 0,
          traffic: data?.insuranceOffer?.additionalProducts?.trafficLegalProtection?.deductible || 0,
        },
        data?.insuranceOffer?.vehicleInsurances?.length,
        Boolean(data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance),
        Boolean(data?.insuranceOffer?.additionalProducts?.trafficLegalProtection),
        Boolean(data?.insuranceOffer?.additionalProducts?.driverLegalProtection),
        Boolean(data?.insuranceOffer?.additionalProducts?.groupAccidentInsurance),
        Boolean(data?.insuranceOffer?.additionalProducts?.trafficLiablilityInsurance),
        masterData,
        mountly,
      );
  
      setUnlocks({
        publicLiabilityInsurance: Boolean(data?.insuranceOffer?.additionalProducts?.businessLiabilityInsurance),
        trafficLegalProtectionInsurance: Boolean(data?.insuranceOffer?.additionalProducts?.trafficLegalProtection),
        accidentInsurance: Boolean(data?.insuranceOffer?.additionalProducts?.groupAccidentInsurance),
        trafficLiabilityInsurance: Boolean(data?.insuranceOffer?.additionalProducts?.trafficLiablilityInsurance),
      });
      setAdditionalServices(newAdditionalServices);
    }
  });

  const windowWidth = useWindowWidthContext();

  const employeeAmountInputContainer: CSSProperties = {
    width: '200px',
  };

  const rootStack: IStackProps = {
    verticalAlign: 'center',
  };

  const contentWrapperStack: IStackProps = {
    horizontal: true,
    verticalAlign: 'center',
    style: {
      backgroundColor: mainTheme.palette.neutralLight,
      padding: '40px'
    },
  };

  const servicesGridContainer: CSSProperties = {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
  };

  const serviceItemStyle: CSSProperties = {
    margin: '15px 15px 0 0',
    padding: mainTheme.spacing.m,
    backgroundColor: mainTheme.palette.white,
    boxShadow: mainTheme.effects.elevation8,
    width: '350px'
  };

  const paymentItemStyle: CSSProperties = {
    margin: '0 15px 0 0',
    width: '350px'
  };

  const summaryStack: IStackProps = {
    horizontal: true,
    horizontalAlign: 'center',
    style: {
      flexGrow: 1,
    },
  };

  const costLabelStyle: CSSProperties = {
    paddingRight: mainTheme.spacing.l1,
  };

  const priceStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  if (windowWidth.windowWidth < 1100) {
    contentWrapperStack.horizontal = false;
    contentWrapperStack.horizontalAlign = 'center';

    servicesGridContainer.gridTemplateColumns = 'auto';
    serviceItemStyle.margin = mainTheme.spacing.m;
    paymentItemStyle.margin = mainTheme.spacing.m;

    summaryStack.style = {
      ...summaryStack.style,
      paddingTop: mainTheme.spacing.l2,
    };
  }

  useEffect(() => {
    if (additionalServices) {
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        additionalServices.amountOfEmployees,
        additionalServices.participationAmount,
        additionalServices.amountOfVehicles,
        additionalServices.businessLiabilityInsurance.isUsed,
        additionalServices.trafficLegalProtection.isUsed,
        additionalServices.driverLegalProtection.isUsed,
        additionalServices.groupAccidentInsurance.isUsed,
        additionalServices.trafficLiabilityInsurance.isUsed,
        masterData,
        mountly,
      );
      setAdditionalServices(newAdditionalServices);
    }
  }, [mountly]);

  function onServiceChanged(servicePropertyName: string, checked: boolean) {
    if (additionalServices) {
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        additionalServices.amountOfEmployees,
        additionalServices.participationAmount,
        additionalServices.amountOfVehicles,
        servicePropertyName === 'useBusinessLiabilityInsurance'
          ? checked
          : additionalServices.businessLiabilityInsurance.isUsed,
        servicePropertyName === 'useTrafficLegalProtection'
          ? checked
          : additionalServices.trafficLegalProtection.isUsed,
        servicePropertyName === 'useDriverLegalProtection'
          ? checked
          : additionalServices.driverLegalProtection.isUsed,
        servicePropertyName === 'useGroupAccidentInsurance'
          ? checked
          : additionalServices.groupAccidentInsurance.isUsed,
        servicePropertyName === 'useTrafficLiabilityInsurance'
          ? checked
          : additionalServices.trafficLiabilityInsurance.isUsed,
        masterData,
        mountly,
      );
  
      setAdditionalServices(newAdditionalServices);
    }
  }

  const onValidateEmployeeAmount = (key: string) => (value: string): string => {
    if (additionalServices) {
      const parsedNumber: number = Number.parseInt(value);
      const newAmount =
        Number.isNaN(parsedNumber) || parsedNumber < 1
          ? 1
          : parsedNumber > 75
            ? 75
            : parsedNumber;
  
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        newAmount,
        additionalServices.participationAmount,
        additionalServices.amountOfVehicles,
        additionalServices.businessLiabilityInsurance.isUsed,
        additionalServices.trafficLegalProtection.isUsed,
        additionalServices.driverLegalProtection.isUsed,
        additionalServices.groupAccidentInsurance.isUsed,
        additionalServices.trafficLiabilityInsurance.isUsed,
        masterData,
        mountly,
      );
  
      setAdditionalServices(newAdditionalServices);
      return newAmount.toString();
    }
    return '';
  };

  function onTrafficLegalAmountParticipationChanged(
    option: IChoiceGroupOption
  ): void {
    if (additionalServices) {
      const parsedNewParticipation: number = Number.parseInt(option.key);
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        additionalServices.amountOfEmployees,
        {
          traffic: parsedNewParticipation,
          driver: additionalServices.participationAmount.driver,
        },
        additionalServices.amountOfVehicles,
        additionalServices.businessLiabilityInsurance.isUsed,
        additionalServices.trafficLegalProtection.isUsed,
        additionalServices.driverLegalProtection.isUsed,
        additionalServices.groupAccidentInsurance.isUsed,
        additionalServices.trafficLiabilityInsurance.isUsed,
        masterData,
        mountly,
      );
  
      setAdditionalServices(newAdditionalServices);
    }
  }

  function onDriverLegalProtectionChanged(
    option: IChoiceGroupOption
  ): void {
    if (additionalServices) {
      const parsedNewParticipation: number = Number.parseInt(option.key);
      const newAdditionalServices = new AdditionalServicesDetails(
        v4(),
        additionalServices.amountOfEmployees,
        {
          traffic: additionalServices.participationAmount.traffic,
          driver: parsedNewParticipation,
        },
        additionalServices.amountOfVehicles,
        additionalServices.businessLiabilityInsurance.isUsed,
        additionalServices.trafficLegalProtection.isUsed,
        additionalServices.driverLegalProtection.isUsed,
        additionalServices.groupAccidentInsurance.isUsed,
        additionalServices.trafficLiabilityInsurance.isUsed,
        masterData,
        mountly,
      );
  
      setAdditionalServices(newAdditionalServices);
    }

  }

  const trafficLegalAmountParticipationOptions: IChoiceGroupOption[] =
    (masterData?.trafficLegalProtectionPrices || []).map(
      (priceTier) => {
        const option: IChoiceGroupOption = {
          key: priceTier.amount.toString(),
          text: `${priceTier.amount.toString()} € Selbstbeteiligung`,
        };
        return option;
      }
    );

  const paymentMethods = masterData?.paymentMethods;

  function onInsurancesChanged(newInsurances: VehicleInsuranceDetails[]) {
    setInsurances(newInsurances);

    const newAmountOfVehicles = newInsurances.reduce(
      (prevValue, currentValue) => {
        return prevValue + currentValue.amount;
      },
      0
    );

    if (newAmountOfVehicles !== amountOfInsuredVehicles) {
      setAmountOfInsuredVehicles(newAmountOfVehicles);
      if (additionalServices && masterData) {
        setAdditionalServices(
          new AdditionalServicesDetails(
            v4(),
            additionalServices.amountOfEmployees,
            additionalServices.participationAmount,
            additionalServices.amountOfVehicles,
            additionalServices.businessLiabilityInsurance.isUsed,
            additionalServices.trafficLegalProtection.isUsed,
            additionalServices.driverLegalProtection.isUsed,
            additionalServices.groupAccidentInsurance.isUsed,
            additionalServices.trafficLiabilityInsurance.isUsed,
            masterData,
            mountly,
          )
        );
      }
    }
  }

  useFetch({
    endpoint: '/api/insuranceMasterData',
    method: FetchMethodsEnum.GET,
    onCompleted: (nextMasterData) => {
      const newMasterData: InsuranceMasterDataDto = {
        vehicleTypes: nextMasterData.vehicleTypes,
        insuranceTypes: nextMasterData.insuranceTypes,
        paymentMethods: nextMasterData.paymentMethods.map((data) => {
          return ({
            value: `${data.id}`,
            label: `paymentMethods.${data.id}`,
          });
        }),
        businessLiabilityInsurancePrices: sortArrayAsc(
          nextMasterData.businessLiabilityInsurancePrices,
          'amount'
        ),
        trafficLiabilityInsurancePrices: sortArrayAsc(
          nextMasterData.trafficLiabilityInsurancePrices,
          'amount'
        ),
        trafficLegalProtectionPrices: sortArrayAsc(
          nextMasterData.trafficLegalProtectionPrices,
          'amount'
        ),
        driverLegalProtectionPrices: sortArrayAsc(
          nextMasterData.driverLegalProtectionPrices,
          'amount'
        ),
        groupAccidentInsurancePrices: sortArrayDesc(
          nextMasterData.groupAccidentInsurancePrices,
          'amount'
        ),
        claimRedemptionsPerCar: nextMasterData.claimRedemptionsPerCar,
        vatPercentage: nextMasterData.vatPercentage,
      };
      onInsurancesChanged([createDefaultInsurance(newMasterData)]);
      setAdditionalServices(
        new AdditionalServicesDetails(
          v4(),
          1,
          {
            traffic: newMasterData.trafficLegalProtectionPrices[0].amount,
            driver: newMasterData.driverLegalProtectionPrices[0].amount,
          },
          0,
          false,
          false,
          false,
          false,
          false,
          newMasterData,
          mountly,
        )
      );
      setMasterData(nextMasterData);
    },
  });

  return (
    <Stack {...rootStack}>
      {!additionalServices ? (
        <SpinnerDefault />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '85vh' }}>
          <Stack {...contentWrapperStack}>
            <div style={servicesGridContainer}>

              <Stack style={{
                ...serviceItemStyle,
                ...(unlocked.publicLiabilityInsurance ? {
                  opacity: '0.6',
                  pointerEvents: 'none',
                } : {})
              }}
              >
                <ServiceOption
                  label={t('additionalService.publicLiabilityInsurance.label')}
                  isChecked={additionalServices.businessLiabilityInsurance.isUsed}
                  onChange={(isChecked: boolean) => {
                    onServiceChanged('useBusinessLiabilityInsurance', isChecked);
                  }}
                  priceAmountOptions={{
                    options: (masterData?.businessLiabilityInsurancePrices || []),
                    optionLabelTemplate: 'additionalService.publicLiabilityInsurance.template',
                  }}
                />
                {additionalServices.businessLiabilityInsurance.isUsed && (
                <>
                  <div style={employeeAmountInputContainer}>
                    <SpinButton
                      disabled={(unlocked.publicLiabilityInsurance || unlocked.accidentInsurance)}
                      label={t('additionalService.numberOfEmployees')}
                      value={
                        additionalServices?.amountOfEmployees?.toString()
                      }
                      onChange={() => {}}
                      onValidate={onValidateEmployeeAmount('publicLiabilityInsurance')}
                      onIncrement={() => {
                        onValidateEmployeeAmount('publicLiabilityInsurance')(
                          (
                            (additionalServices?.amountOfEmployees || 0) + 1
                          ).toString()
                        );
                      }
                      }
                      onDecrement={() => {
                        if (!(unlocked.publicLiabilityInsurance || unlocked.accidentInsurance)) {
                          onValidateEmployeeAmount('publicLiabilityInsurance')(
                            (
                              (additionalServices?.amountOfEmployees || 0) - 1
                            ).toString()
                          );
                        }
                      }
                      }
                    />
                  </div>
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.businessLiabilityInsurance.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.businessLiabilityInsurance.price / 100 * additionalServices.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                          {t(`additionalService.annualNetContribution${!mountly ? 'Annual' : 'Monthly'}`)}
                        </td>
                        <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                          {
                            formatCurrency(
                              additionalServices.businessLiabilityInsurance.price + (additionalServices.businessLiabilityInsurance.price / 100 * additionalServices.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
                </>
                )}
              </Stack>

              <Stack style={{
                ...serviceItemStyle,
                ...(unlocked.trafficLegalProtectionInsurance ? {
                  opacity: '0.6',
                  pointerEvents: 'none',
                } : {})
              }}
              >
                <ServiceOption
                  label={t('additionalService.trafficLegalProtection.label')}
                  isChecked={additionalServices.trafficLegalProtection.isUsed}
                  onChange={(isChecked: boolean) => {
                    onServiceChanged('useTrafficLegalProtection', isChecked);
                  }
                }
                  priceAmountOptions={{
                    options: (masterData?.trafficLegalProtectionPrices || []),
                    optionLabelTemplate: 'additionalService.trafficLegalProtection.template',
                  }}
                  subHeaderRows={[
                    t('additionalService.current', {
                      count: additionalServices.amountOfVehicles,
                      name: t('global.vehicle', {
                        count: additionalServices.amountOfVehicles,
                      }),
                    }),
                  ]}
                />
                {additionalServices.trafficLegalProtection.isUsed && (
                <>
                  <ChoiceGroup
                    options={trafficLegalAmountParticipationOptions}
                    selectedKey={additionalServices.participationAmount.traffic.toString() === '0' ? '150' : additionalServices.participationAmount.traffic.toString()}
                    defaultSelectedKey={150}
                    onChange={(_: any, option: IChoiceGroupOption | undefined) =>
                      onTrafficLegalAmountParticipationChanged(option!)
                    }
                  />
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.trafficLegalProtection.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.trafficLegalProtection.price / 100 * additionalServices.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                          {t(`additionalService.annualNetContribution${
                            !mountly ? 'Annual' : 'Monthly'
                          }`)}
                        </td>
                        <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                          {
                            formatCurrency(
                              additionalServices.trafficLegalProtection.price + (additionalServices.trafficLegalProtection.price / 100 * additionalServices.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
                </>
                )}
              </Stack>

              <Stack style={{
                ...serviceItemStyle,
                ...(unlocked.accidentInsurance ? {
                  opacity: '0.6',
                  pointerEvents: 'none',
                } : {})
              }}
              >
                <ServiceOption
                  label={t('additionalService.groupAccidentInsurance.label')}
                  isChecked={additionalServices.groupAccidentInsurance.isUsed}
                  onChange={(isChecked: boolean) =>
                    onServiceChanged('useGroupAccidentInsurance', isChecked)
                }
                  priceAmountOptions={{
                    options: (masterData?.groupAccidentInsurancePrices || []).map(
                      (item, position) => ({
                        onlyLabel: position !== (masterData?.groupAccidentInsurancePrices?.length || 0) - 1,
                        ...item,
                      })),
                    optionLabelTemplate: 'additionalService.groupAccidentInsurance.template',
                  }}
                />
                {additionalServices.groupAccidentInsurance.isUsed && (
                <>
                  <div style={employeeAmountInputContainer}>
                    <SpinButton
                      disabled={(unlocked.publicLiabilityInsurance || unlocked.accidentInsurance)}
                      label={t('additionalService.numberOfEmployees')}
                      value={
                        additionalServices?.amountOfEmployees?.toString()
                      }
                      onValidate={onValidateEmployeeAmount('accidentInsurance')}
                      onIncrement={() => {
                        onValidateEmployeeAmount('accidentInsurance')(
                          (
                            (additionalServices?.amountOfEmployees || 0) + 1
                          ).toString(),
                        );
                      }}
                      onDecrement={() => {
                        if (!(unlocked.publicLiabilityInsurance || unlocked.accidentInsurance)) {
                          onValidateEmployeeAmount('accidentInsurance')(
                            (
                              (additionalServices?.amountOfEmployees || 0) - 1
                            ).toString(),
                          );
                        }
                      }
                      }
                    />
                  </div>
                  <>
                    <table style={{ marginTop: 10 }}>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepOne.totalPremium')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.groupAccidentInsurance.price)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle }}>
                          {t('wizardStepTwo.taxes')}
                        </td>
                        <td style={{ ...priceStyle }}>
                          {formatCurrency(additionalServices.groupAccidentInsurance.price / 100 * additionalServices.vatPercentage)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                          {t(`additionalService.annualNetContribution${!mountly ? 'Annual' : 'Monthly'}`)}
                        </td>
                        <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                          {
                            formatCurrency(
                              additionalServices.groupAccidentInsurance.price + (additionalServices.groupAccidentInsurance.price / 100 * additionalServices.vatPercentage)
                            )
                          }
                        </td>
                      </tr>
                    </table>
                  </>
                </>
                )}
              </Stack>
              <Stack style={{
                ...serviceItemStyle,
                ...(unlocked.trafficLiabilityInsurance ? {
                  opacity: '0.6',
                  pointerEvents: 'none',
                } : {})
              }}
              >
                <ServiceOption
                  label={t('additionalService.trafficLiabilityInsurance.label')}
                  isChecked={additionalServices.trafficLiabilityInsurance.isUsed}
                  onChange={(isChecked: boolean) =>
                    onServiceChanged('useTrafficLiabilityInsurance', isChecked)
                }
                  priceAmountOptions={{
                    options: (masterData?.trafficLiabilityInsurancePrices || []),
                    optionLabelTemplate: 'additionalService.trafficLiabilityInsurance.template',
                  }}
                  subHeaderRows={[
                    t('additionalService.current', {
                      count: additionalServices.amountOfVehicles,
                      name: t('global.vehicle', {
                        count: additionalServices.amountOfVehicles,
                      }),
                    }),
                  ]}
                />
                {additionalServices.trafficLiabilityInsurance.isUsed && (
                <>
                  <table style={{ marginTop: 10 }}>
                    <tr>
                      <td style={{ ...costLabelStyle }}>
                        {t('wizardStepOne.totalPremium')}
                      </td>
                      <td style={{ ...priceStyle }}>
                        {formatCurrency(additionalServices.trafficLiabilityInsurance.price)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ ...costLabelStyle }}>
                        {t('wizardStepTwo.taxes')}
                      </td>
                      <td style={{ ...priceStyle }}>
                        {formatCurrency(additionalServices.trafficLiabilityInsurance.price / 100 * additionalServices.vatPercentage)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                        {t(`additionalService.annualNetContribution${
                          !mountly ? 'Annual' : 'Monthly'
                        }`)}
                      </td>
                      <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                        {
                            formatCurrency(
                              additionalServices.trafficLiabilityInsurance.price + (additionalServices.trafficLiabilityInsurance.price / 100 * additionalServices.vatPercentage)
                            )
                          }
                      </td>
                    </tr>
                  </table>
                </>
                )}
              </Stack>
            </div>
            <Stack {...summaryStack}>
              <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: '16px' }}>
                      <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{t('global.summary')}</Text>
                    </td>
                  </tr>
                  {additionalServices.businessLiabilityInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>
                      {t('additionalService.publicLiabilityInsurance.label')}
                    </td>
                    <td style={priceStyle}>
                      {formatCurrency(
                        additionalServices.businessLiabilityInsurance.price
                      )}
                    </td>
                  </tr>
                  )}
                  {additionalServices.driverLegalProtection.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.driverLegalProtection.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(additionalServices.driverLegalProtection.price)}
                    </td>
                  </tr>
                  )}
                  {additionalServices.trafficLegalProtection.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.trafficLegalProtection.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(additionalServices.trafficLegalProtection.price)}
                    </td>
                  </tr>
                  )}
                  {additionalServices.trafficLiabilityInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.trafficLiabilityInsurance.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(additionalServices.trafficLiabilityInsurance.price)}
                    </td>
                  </tr>
                  )}
                  {additionalServices.groupAccidentInsurance.isUsed && (
                  <tr>
                    <td style={costLabelStyle}>{t('additionalService.groupAccidentInsurance.label')}</td>
                    <td style={priceStyle}>
                      {formatCurrency(additionalServices.groupAccidentInsurance.price)}
                    </td>
                  </tr>
                  )}
                  <tr>
                    <td style={{ borderTop: '1px solid black' }} />
                    <td style={{ borderTop: '1px solid black' }} />
                  </tr>
                  <tr>
                    <td style={{ ...costLabelStyle }}>
                      {t('wizardStepOne.totalPremium')}
                    </td>
                    <td style={{ ...priceStyle }}>
                      {formatCurrency(additionalServices.costsOfServices)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...costLabelStyle }}>
                      {t('wizardStepTwo.taxes')}
                    </td>
                    <td style={{ ...priceStyle }}>
                      {formatCurrency(additionalServices.costsOfServices / 100 * additionalServices.vatPercentage)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTop: '1px solid black' }} />
                    <td style={{ borderTop: '1px solid black' }} />
                  </tr>
                  {!mountly ? (
                    <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>{t('additionalService.grossPremium')}</td>
                      <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                        {formatCurrency(additionalServices.costsOfServices + (additionalServices.costsOfServices / 100 * additionalServices.vatPercentage))}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                        {t('additionalService.grossPremiumMonthly')}
                      </td>
                      <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                        {formatCurrency(additionalServices.costsOfServices + (additionalServices.costsOfServices / 100 * additionalServices.vatPercentage))}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ paddingTop: 20 }}>
                      {t('additionalService.informationText')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 7 }}>
                      {t('additionalService.taxesInformationText')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Stack>
          </Stack>
          <div style={{ display: 'flex', padding: '0 40px', marginTop: 'auto', marginBottom: 20 }}>
            <PrimaryButton
              text={t('global.cancel')}
              style={{ marginTop: 20, marginRight: 'auto' }}
              onClick={() => {userData?.role === 'DSP' ? history.push('/overview') : history.push(`/overview?${nameId}`);}}
            />
            <PrimaryCallToActionButton
              text={t('global.save')}
              style={{ marginLeft: 'auto', marginTop: 20, maxWidth: '120px' }}
              onClick={saveIt}
            />
          </div>
        </div>
      )}
    </Stack>
  );
}
