import React, { CSSProperties, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import NavigationLink from '@/components/base/NavigationLink';
import { Stack, MessageBar, MessageBarType } from '@fluentui/react';
import { mainTheme, alternateTheme } from '@/theme/themes';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';


import { VehicleInsuranceDetails } from '../../../../utils/services/vehicleInsurancedetails';
import VehicleInsuranceCard from './VehicleInsuranceCard';
import CreateInsuranceCard from './CreateInsuranceCard';
import { InsuranceMasterDataDto } from '../insuranceMasterDataDto';
import { createDefaultInsurance } from './createDefaultInsurance';

import './animations.scss'
import { useCalculation } from '@/contexts/calculation';

interface Types {
  insurances: VehicleInsuranceDetails[] | undefined;
  onChange: (insurances: VehicleInsuranceDetails[]) => void;
  maxVehiclesAmount: number;
  disabledPaymentMethod?: boolean;
  reportDamage?: boolean;
  showNewCard?: boolean;
  masterData: InsuranceMasterDataDto | undefined;
  paymentMethodIsLoading?: boolean;
}

export default function InsuredVehicles(props: Types) {
  const { t } = useTranslation();
  const {
    paymentMethod,
  } = useCalculation();

  const containerStyle: CSSProperties = {
    flexGrow: 1
  };

  const stickyErrorStyle: CSSProperties = {
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
  };

  const errorMessageStyle: CSSProperties = {
    paddingLeft: mainTheme.spacing.m,
    paddingRight: mainTheme.spacing.m,
  };

  const elevateTextStyle: CSSProperties = {
    fontWeight: 'bold',
    color: mainTheme.semanticColors.errorText,
  };

  const { mountly } = useCalculation()

  function createInsurance() {
    if (!props.masterData) {
      return;
    }
    const newInsurance = createDefaultInsurance(props.masterData, mountly);
    props.onChange([...props.insurances || [], newInsurance]);
  }

  function onInsuranceChanged(
    oldValue: VehicleInsuranceDetails,
    newValue: VehicleInsuranceDetails | null
  ) {
    const index = props?.insurances?.findIndex(
      (insurance) => insurance.id === oldValue.id
    ) || 0;
    const newInsurances = props?.insurances?.slice() || [];
  
    if (newValue === null) {
      newInsurances.splice(index, 1);
    } else {
      newInsurances[index] = newValue;
    }

    props.onChange(newInsurances);
  }

  const amountOfVehicles: number = props?.insurances?.reduce(
    (prevValue, currentValue) => {
      return prevValue + currentValue.amount;
    },
    0
  ) || 0;

  const paymentMethods = props.masterData?.paymentMethods;

  const vehicleCards = props?.insurances?.map((insurance) => {
    return (
    <CSSTransition
      key={insurance.id}
      timeout={500}
      classNames="vehicle"
    >
      <div>
          <VehicleInsuranceCard
            insurance={insurance}
            reportDamage={props?.reportDamage}
            insuranceMasterData={props.masterData!}
            maxAmount={props.maxVehiclesAmount}
            onChange={onInsuranceChanged}
            vatPercentage={props?.masterData?.vatPercentage || 0}
          />
      </div>
    </CSSTransition>
  )})

  return (
    <Stack style={containerStyle} tokens={{ childrenGap: 16 }}>
      <div style={{ margin: '0 80px', maxWidth: 415 }}>
        <ThemedSelect
          selectDisabled={props?.disabledPaymentMethod}
          label={t('wizardStepOne.paymentMethod')}
          options={paymentMethods}
          value={props.paymentMethodIsLoading ? null : paymentMethod.value}
          onChange={(data: any) => paymentMethod.set(data)}
        />
      </div>
      
      <TransitionGroup className="vehicle-list">
        {vehicleCards}
      </TransitionGroup>

      {!props?.reportDamage && <CreateInsuranceCard onCreateInsurance={createInsurance} />}
      {amountOfVehicles > props.maxVehiclesAmount && (
        <div style={stickyErrorStyle}>
          <MessageBar
            style={errorMessageStyle}
            messageBarType={MessageBarType.error}
          >
            {props?.disabledPaymentMethod && !props?.reportDamage ? (
              <p style={elevateTextStyle}>
                Sie haben {amountOfVehicles} Fahrzeuge erfasst. Über das Portal können 
                maximal {props.maxVehiclesAmount} Fahrzeuge versichert werden. Bitte verringern 
                Sie die Anzahl oder verwenden Sie das Kontaktformular{' '}
                <NavigationLink
                  theme={alternateTheme}
                  text="Kontaktformular"
                  to="/contact"
                  style={{ fontWeight: 'bold' }}
                />
              </p>
            ) : (
              <p style={elevateTextStyle}>
                Sie haben {amountOfVehicles} Fahrzeuge angegeben. Über dieses
                Formular können maximal {props.maxVehiclesAmount} Fahrzeuge
                versichert werden. Bitte verringern Sie die Anzahl oder verwenden
                Sie das{' '}
                <NavigationLink
                  theme={alternateTheme}
                  text="Kontaktformular"
                  to="/contact"
                  style={{ fontWeight: 'bold' }}
                />
              </p>
            )}
          </MessageBar>
        </div>
      )}
    </Stack>
  );
}
