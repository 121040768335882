import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@/hooks';
import { LANG_COOCKIE_PATH } from '@/constants/cookie';

import { Dropdown } from '@fluentui/react';

import './index.scss';

const options = [
  { key: 'en', text: 'EN' },
  { key: 'de', text: 'DE' },
];

export default function DropdownLanguage(): JSX.Element {
  const { i18n } = useTranslation();
  const { isTabletOrMobile } = useMediaQuery();

  const onChange = (event, item): void => {
    i18n.changeLanguage(item.key);
  };

  useEffect(() => {
    if (i18n.language) {
      localStorage.setItem(LANG_COOCKIE_PATH, i18n.language);
    }
  }, [i18n.language]);

  const dropdownStyles = {
    root: {
      ...(isTabletOrMobile ? {
        margin: 16,
      } : {
        margin: '0 10px'
      }),
    },
    dropdown: { 
      ...(isTabletOrMobile ? {
        width: 60,
        margin: '16px 0',
      } : {
        width: 60,
      }),
    },
  };

  return (
    <div className="languageDropdown">
      <Dropdown
        defaultSelectedKey={i18n.language}
        options={options}
        onChange={onChange}
        styles={dropdownStyles}
      />
    </div>
  );
}