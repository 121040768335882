import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'office-ui-fabric-react';
import moment from 'moment';

import {
  Stack, SelectionMode, ShimmeredDetailsList,
  DetailsListLayoutMode,
  PrimaryButton, IOverflowSetItemProps,
  CommandBarButton, TextField,
  ActionButton, MessageBar, Text,
  TooltipHost, IColumn, IDetailsHeaderProps,
  MessageBarType, Callout, Shimmer,
} from '@fluentui/react';
import { useAuth } from '@/contexts/auth';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { formatCurrency } from '@/utils/formating';
import { DeregistrationVehicleDialog } from '@/components/base/DeregisterVehicleDialog';
import { ReportDamage } from '@/components/base/ReportDamage';
import { ConfirmDialog } from '@/components/base/ConfirmDialog';
import { ConfirmationDialog } from '@/components/base/ConfirmationDialog';
import { Warning } from '@/components/base/Warning';
import { ContactView } from '@/components/base/ContactView';
import { dayPickerStrings, onFormatDate, dayPickerStringsGerman } from '@/utils/calendar';
import { getError, validateFINOrVIN, validateHSN, validateLicNum, validateTSN } from '@/utils/validations';
import { hasErrors } from '@/utils/hasErrors';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';

import { ActionSet } from './Actions';
import './index.scss';

import { CLP } from './CLP';

enum ModalFlags {
  HIDDEN = 0,
  REQUESTED = 1,
  APPROVED = 2
}

const onRenderItemStyles = {
  root: { padding: '10px' },
};

const onRenderOverflowButtonStyles = {
  root: { padding: '10px', background: 'transparent' },
  menuIcon: { fontSize: '16px' },
};

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <CommandBarButton
      key={item.key}
      role="menuitem"
      aria-label={item.name}
      styles={onRenderItemStyles}
      iconProps={{ iconName: item.icon }}
      onClick={item.onClick}
    />
  );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      styles={onRenderOverflowButtonStyles}
      menuIconProps={{ iconName: 'CollapseMenu' }}
      menuProps={{ items: overflowItems! }}
    />
  );
};

export default function OverviewDetailsPage(): JSX.Element {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const { id } =  useParams<{ id: string }>();
  const { authorized, userData } = useAuth();

  const [vehicleId, setVehicleId] = useState();
  const [licensePlate, setLicensePlate] = useState();
  const [isReportDamage, setReportDamage] = useState(ModalFlags.HIDDEN);
  const [rdk, setRDK] = useState(Date.now());
  const [hiddenDeregistration, setHiddenDeregistration] = useState(ModalFlags.HIDDEN);
  const [showComplete, setShowComplete] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(0);
  const [isHiddenContactForm, setHiddenContactForm] = useState(true);
  const [insuranceOffer, setInsuranceOffer] = useState({} as any);
  const [redId, setRepId] = useState({} as any);


  const { control, handleSubmit, reset, errors, formState, triggerValidation, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      vehicles: [],
    },
  });

  const { fields } = useFieldArray({ control, name: 'vehicles' });

  const handleContactDetailClicked = (licenseNumber) => {
    setSelectedID(licenseNumber);
    setDefaultSelectedIndex(0);
    setHiddenContactForm(false);
  };

  const handleContactDamageClicked = (licenseNumber) => {
    setSelectedID(licenseNumber);
    setDefaultSelectedIndex(1);
    setHiddenContactForm(false);
  };

  const handleContactDocumentsClicked = (licenseNumber) => {
    setSelectedID(licenseNumber);
    setDefaultSelectedIndex(2);
    setHiddenContactForm(false);
  };

  const [shadowVehs, setShadowVehs] = useState([] as any);
  const { loading, data: vehicleData, makeRequest: refetch } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : id}`,
    skip: !(id || userData?.nameid),
    method: FetchMethodsEnum.GET,
    onCompleted: async (nextData) => {
      let completed = true;
      const formattedData = (nextData?.insuranceOffer?.vehicleInsurances || []);
      const vehicles = [];

      getInsurancesData();
      formattedData.forEach(vehicle => {
        for (let i = 0; i < vehicle.numberOfVehicles; i++) {
          if (!vehicle.vehicles) { completed = false; }
          // eslint-disable-next-line no-shadow
          if ((vehicle.vehicles || []).find(({ completed }) => !completed)) {
            completed = false;
          }
          vehicles.push({
            ...vehicle,
            netTotal: vehicle.netTotal / vehicle.numberOfVehicles,
            ...(vehicle.vehicles ? vehicle.vehicles[i] : {}),
          } as never);
        }
      });

      setInsuranceOffer(nextData?.insuranceOffer);

      const nextFields = (vehicles as any).map((item, position) => ({
        ...item,
        completed: item.completed,
        deregistrationDate: item.deregistrationDate,
        isActive: item.isActive,
        licensePlate: item.licensePlate || '',
        manufacturerKeyCode: item.manufacturerKeyCode || '',
        registrationDate: item.registrationDate,
        typeKeyCode: item.typeKeyCode || '',
        vehicleId: item.vehicleId || '',
        vin: item.vin || '',
        position: position + 1,
        id: position + 1,
      }));

      setShadowVehs(nextFields);

      reset({
        vehicles: nextFields,
      });
      setShowComplete(completed);
    },
  });

  const { loading: patching, makeRequest: patchVehicles, error } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : id}`,
    skip: !id || !authorized,
    method: FetchMethodsEnum.PATCH,
    data: {
      insuranceOffer: {
        ...(vehicleData ? vehicleData.insuranceOffer : {}),
        isCompletedConfirmed: false
      }
    },
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (error) {
      (window as any).error = error;
      triggerValidation();
    }
  }, [error]);

  const hasDup = (licNum, pos) => {
    let dup = true;
    const vals = getValues();

    if (!licNum) return true;

    fields.forEach((_f, ind) => {
      if (ind !== pos && vals[`vehicles[${ind}].licensePlate`] === licNum) {
        dup = false;
      }
    });
    return dup;
  };

  const { makeRequest: sentXML } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : id}/sendXml`,
    method: FetchMethodsEnum.POST,
  });

  const { data: insurancesInitialData, makeRequest: getInsurancesData } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : id}/vehicleInsurances/initialData`,
    method: FetchMethodsEnum.GET
  });

  const { makeRequest: completeVehicleInsurance, loading: compliting } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : id}/completeVehicleInsurance`,
    method: FetchMethodsEnum.POST,
    onCompleted: async () => {
      await patchVehicles({
        data: {
          ...vehicleData,
          insuranceOffer: {
            ...(vehicleData ? vehicleData.insuranceOffer : {}),
            isCompletedConfirmed: true
          },
          isCompleted: true,
        }
      });
      await refetch();
    }
  });

  if (!authorized) { return <Redirect to="/login" />; }

  const handleMenuItemClicked = (nextVehicleId, licPlat, repId, callBack) => async (_event, options) => {
    switch(options.key) {
      case 'reportDamage':
        setVehicleId(nextVehicleId);
        setLicensePlate(licPlat);
        setRepId({ id: repId, callBack: () => callBack() });
        setRDK(Date.now());
        setReportDamage(
          formState.dirty ? ModalFlags.REQUESTED : ModalFlags.APPROVED
        );
        break;
      case 'deregister':
        setVehicleId(nextVehicleId);
        setHiddenDeregistration(
          formState.dirty ? ModalFlags.REQUESTED : ModalFlags.APPROVED
        );
        break;
      default:
        setVehicleId(nextVehicleId);
        setLicensePlate(licPlat);
        setRepId({ id: repId, callBack: () => callBack() });
        setRDK(Date.now());
        setReportDamage(
          formState.dirty ? ModalFlags.REQUESTED : ModalFlags.APPROVED
        );
        break;
    }
  };

  const onSubmit = async (values) => {
    const patchedVehicleData = { ...vehicleData };
    const formattedValues = values.vehicles.map((obj, index) => ({
      ...(shadowVehs[index] as any),
      ...obj,
      registrationDate: obj.registrationDate ? `${moment(obj?.registrationDate).format('YYYY-MM-DDT00:00:00.000')}Z` : null,
    }));

    let position = 0;
    patchedVehicleData.insuranceOffer.vehicleInsurances =
      patchedVehicleData.insuranceOffer.vehicleInsurances.map(vehicleInsurance => {
        const { numberOfVehicles, } = vehicleInsurance;
        const currentVehicles = [];

        for (let i = 0; i < numberOfVehicles; i++) {
          const obj = formattedValues[position] as never;
          if (Object.values(obj).length > 0) {
            currentVehicles.push(obj);
          }
          position += 1;
        }
        return {
          ...vehicleInsurance,
          vehicles: currentVehicles.map((obj: any) => {
            return (
              {
                ...obj,
              });
          }),
        };
      });

    await patchVehicles({
      data: {
        insuranceOffer: {
          ...vehicleData.insuranceOffer,
          isCompletedConfirmed: false,
        }
      }
    });
  };

  const isDisabledFields = Boolean(vehicleData?.isCompleted);
  const columns: IColumn[] = [
    {
      key: 'no',
      name: '№',
      fieldName: 'no',
      minWidth: 10,
      maxWidth: 10,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      className: 'filled',
      onRender: (item) => {
        return <span>{item.position}</span>;
      },
      isPadded: true,
    },
    {
      key: 'type',
      name: t('overviewDetail.type'),
      ariaLabel: t(['overviewDetail.tooltipType', '']),
      fieldName: 'vehicleType',
      minWidth: 25,
      maxWidth: 25,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return <span>{t(`vehicles.${item.vehicleType}`)}</span>;
      },
      isPadded: true,
    },
    {
      key: 'licensePlate',
      name: t('overviewDetail.licenceNumber​'),
      ariaLabel: t(['overviewDetail.tooltipLicenceNumber​', '']),
      fieldName: 'licensePlate',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item, index) => {
        return (
          <CLP licNum={item.licensePlate}>
            {() => {
              return (
                <>
                  <Controller
                    name={`vehicles[${index}].licensePlate`}
                    defaultValue={item.licensePlate}
                    control={control}
                    rules={{
                      validate: {
                        licNum: validateLicNum,
                        duplicate: (val) => {
                          const has = hasDup(val, index);
                          if (!has) return false;
                          if ((window as any).error?.includes(val) && shadowVehs[index as any].licensePlate !== val) {
                            return false;
                          }
                          return true;
                        }
                      }
                    }}
                    as={prop => (
                      <TextField
                        style={{ textAlign: 'center' }}
                        disabled={!item.isActive || isDisabledFields}
                        className={`bordless vehicles${index}-licensePlate`}
                        {...prop}
                        onChange={(e: any, text) => {
                          const ev = {
                            ...e,
                            target: {
                              ...e.target,
                              value: text?.toUpperCase(),
                            },
                          };

                          prop.onChange(ev, text?.toUpperCase());
                          triggerValidation();
                        }}
                      />
                    )
                  }
                  />
                  {getError(errors, `vehicles[${index}].licensePlate`, t) && (
                    <Callout
                      className="warn-callout"
                      target={`.vehicles${index}-licensePlate`}
                      role="alertdialog"
                      gapSpace={0}
                    >
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                      >
                        {/* {getError(errors, `vehicles[${index}].licensePlate`, t, error?.includes(item?.vehicleId) ? 'duplicate' : '')} */}
                        {getError(errors, `vehicles[${index}].licensePlate`, t)}
                      </MessageBar>
                    </Callout>
                  )}
                </>
              );
            }}
          </CLP>
        );
      },
      isPadded: true,
    },
    {
      key: 'hsn',
      name: t('overviewDetail.hsn'),
      ariaLabel: t(['overviewDetail.tooltipHsn', '']),
      fieldName: 'hsn',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item, index) => {
        return (
          <>
            <Controller
              name={`vehicles[${index}].manufacturerKeyCode`}
              defaultValue={item.manufacturerKeyCode}
              control={control}
              rules={{
                validate: {
                  hsn: validateHSN,
                }
              }}
              as={prop => (
                <TextField
                  style={{ textAlign: 'center' }}
                  disabled={!item.isActive || isDisabledFields}
                  className={`bordless vehicles${index}-manufacturerKeyCode`}
                  {...prop}
                  onChange={(e: any, text) => {
                    const ev = {
                      ...e,
                      target: {
                        ...e.target,
                        value: text?.toUpperCase(),
                      },
                    };

                    prop.onChange(ev, text?.toUpperCase());
                  }}
                />
              )}
            />
            {getError(errors, `vehicles[${index}].manufacturerKeyCode`, t) && (
              <Callout
                className="warn-callout"
                target={`.vehicles${index}-manufacturerKeyCode`}
                role="alertdialog"
                gapSpace={0}
              >
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                >
                  {getError(errors, `vehicles[${index}].manufacturerKeyCode`, t)}
                </MessageBar>
              </Callout>
            )}
          </>
        );
      },
      isPadded: true,
    },
    {
      key: 'tsn',
      name: t('overviewDetail.tsn'),
      ariaLabel: t(['overviewDetail.tooltipTsn', '']),
      fieldName: 'tsn',
      minWidth: 40,
      maxWidth: 40,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item, index) => {
        return (
          <>
            <Controller
              name={`vehicles[${index}].typeKeyCode`}
              defaultValue={item.typeKeyCode}
              control={control}
              rules={{
                validate: {
                  tsn: validateTSN,
                }
              }}
              as={(prop) => (
                <TextField
                  {...prop}
                  disabled={!item.isActive || isDisabledFields}
                  className={`bordless vehicles${index}-typeKeyCode`}
                  onChange={(e: any, text) => {
                    const ev = {
                      ...e,
                      target: {
                        ...e.target,
                        value: text?.toUpperCase(),
                      },
                    };

                    prop.onChange(ev, text?.toUpperCase());
                  }}
                />
              )}
            />
            {getError(errors, `vehicles[${index}].typeKeyCode`, t) && (
              <Callout
                className="warn-callout"
                target={`.vehicles${index}-typeKeyCode`}
                role="alertdialog"
                gapSpace={0}
              >
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                >
                  {getError(errors, `vehicles[${index}].typeKeyCode`, t)}
                </MessageBar>
              </Callout>
            )}
          </>
        );
      },
      isPadded: true,
    },
    {
      key: 'vin',
      name: t('overviewDetail.vin'),
      ariaLabel: t(['overviewDetail.tooltipVin', '']),
      fieldName: 'vin',
      minWidth: 110,
      maxWidth: 110,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item, index) => {
        return (
          <>
            <Controller
              name={`vehicles[${index}].vin`}
              defaultValue={item.vin}
              control={control}
              rules={{
                validate: {
                  finOrVin: validateFINOrVIN,
                }
              }}
              as={prop => (
                <TextField
                  style={{ textAlign: 'center' }}
                  disabled={!item.isActive || isDisabledFields}
                  className={`bordless vehicles${index}-vin`}
                  {...prop}
                  onChange={(e: any, text) => {
                    if (!(/[QIO]/gm.test((text || '')?.toUpperCase()))) {
                      const ev = {
                        ...e,
                        target: {
                          ...e.target,
                          value: text?.toUpperCase(),
                        },
                      };

                      prop.onChange(ev, text?.toUpperCase());
                    }
                  }}
                />
              )
              }
            />
            {getError(errors, `vehicles[${index}].vin`, t) && (
            <Callout
              className="warn-callout"
              target={`.vehicles${index}-vin`}
              role="alertdialog"
              gapSpace={0}
            >
              <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                {getError(errors, `vehicles[${index}].vin`, t)}
              </MessageBar>
            </Callout>
            )}
          </>
        );
      },
      isPadded: true,
    },
    {
      key: 'registrationDate',
      name: t('overviewDetail.dateRegistration'),
      ariaLabel: t(['overviewDetail.tooltipDateRegistration', '']),
      fieldName: 'registrationDate',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'empty',
      onRender: (item, index) => {
        return (
          <Controller
            name={`vehicles[${index}].registrationDate`}
            defaultValue={item.registrationDate ? moment(item.registrationDate).toDate() : null}
            control={control}
            as={prop => (
              <DatePicker
                disableAutoFocus
                strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
                disabled={!item.isActive || isDisabledFields}
                style={{ width: 95, alignItems: 'center' }}
                formatDate={onFormatDate}
                maxDate={new Date()}
                className='datePickerStyles'
                value={prop.value && moment(prop.value).toDate()}
                onAfterMenuDismiss={prop.onBlur}
                onSelectDate={(date) => {
                  prop.onChange(moment(date).toISOString());
                }}
              />
            )
          }
          />
        );
      },
      isPadded: true,
    },
    {
      key: 'liabilityInsurance​',
      name: t('overviewDetail.liabilityInsurance​'),
      ariaLabel: t(['overviewDetail.tooltipLiabilityInsurance​', '']),
      fieldName: 'liabilityInsurance​',
      minWidth: 15,
      maxWidth: 15,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span>
            {
              t(item.isLiabilitySelected ? 'global.yes' : 'global.no')
            }
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'fullInsurance',
      name: t('overviewDetail.fullInsurance'),
      ariaLabel: t(['overviewDetail.tooltipFullInsurance', '']),
      fieldName: 'fullInsurance',
      minWidth: 15,
      maxWidth: 15,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span>
            {
              t(item.isComprehensiveCoverSelected ? 'global.yes' : 'global.no')
            }
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'gap',
      name: t('overviewDetail.gap'),
      ariaLabel: t(['overviewDetail.tooltipGap', '']),
      fieldName: 'isGapCoverageSelected',
      minWidth: 15,
      maxWidth: 15,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return <span>{t(item.isGapCoverageSelected ? 'global.yes' : 'global.no')}</span>;
      },
      isPadded: true,
    },
    {
      key: 'premiumAnually',
      name: vehicleData?.insuranceOffer?.paymentMethod === 'monthly'
        ? t('overviewDetail.premiumMonthly')
        : t('overviewDetail.premiumAnually'),
      ariaLabel: vehicleData?.insuranceOffer?.paymentMethod === 'monthly'
        ? t(['overviewDetail.tooltipPremiumMonthly', ''])
        : t(['overviewDetail.tooltipPremiumAnually', '']),
      fieldName: 'premiumAnually',
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item) => {
        return (
          <span>
            {
              vehicleData?.insuranceOffer?.paymentMethod === 'monthly'
                ? formatCurrency(item.netTotal)
                : formatCurrency(item.netTotal)
            }
          </span>
        );
      },
      isPadded: true,
    },

    {
      key: 'isActive',
      name: t('overviewDetail.active'),
      ariaLabel: t(['overviewDetail.tooltipActive', '']),
      fieldName: 'isActive',
      minWidth:  20,
      maxWidth: 20,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      className: 'filled',
      onRender: (item, index) => {
        return (
          <Controller
            name={`vehicles[${index}].isActive`}
            defaultValue={item.isActive}
            control={control}
            as={
              <ActionButton iconProps={item.isActive ? { iconName: 'Accept', style: {
                color: 'green'
              }
              } : {
                iconName: 'ChromeClose', style: {
                  color: 'red'
                }
              }}
              />
            }
          />
        );
      },
      isPadded: true,
    },
    {
      key: 'actions',
      name: t('overviewDetail.actions'),
      ariaLabel: t(['overviewDetail.tooltipActions', '']),
      fieldName: 'actions',
      currentWidth: 10,
      minWidth: 10,
      maxWidth: 10,
      isResizable: false,
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return (
          <ActionSet
            item={item}
            t={t}
            userId={userData?.role === 'DSP' ? userData?.nameid : id}
            handleMenuItemClicked={handleMenuItemClicked}
            onRenderOverflowButton={onRenderOverflowButton}
            handleContactDetailClicked={handleContactDetailClicked}
            handleContactDamageClicked={handleContactDamageClicked}
            handleContactDocumentsClicked={handleContactDocumentsClicked}
            onRenderItem={onRenderItem}
          />
        );
      },
      isPadded: true,
    }
  ];

  return (
    <Stack className='overviewDetailContainer'>
      <Stack className='contentContainer'>
        {userData?.role === 'Advisor' && !loading && (
        <Text className="subTitleOverview">
          {t('overview.companyNameSubTitle')}
          {vehicleData?.insuranceOffer?.companyInformation?.companyName}
        </Text>
          )}
        <div className='headerBlock'>
          {(!showComplete && !loading) && <Warning deffault>{t('overviewDetail.warning')}</Warning>}
          <PrimaryButton
            className="overviewDetailButtonStyle"
            text={t('overviewDetail.overviewButton')}
            onClick={userData?.role === 'DSP'
              ? () => history.push('/overview')
              : () => history.push(`/overview?${vehicleData?.id}`)
                }
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ShimmeredDetailsList
            compact
            viewport={{ width: 1120, height: 500 }}
            enableShimmer={loading || compliting}
            items={fields || []}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderDetailsHeader={(props, defaultHeader) => defaultHeader!({
              ...props,
              onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                if (loading) {
                  return <Shimmer height="100%" />;
                }
                return <TooltipHost
                  styles={{
                    root: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
                  }}
                  {...tooltipHostProps}
                />;
              },
            } as IDetailsHeaderProps)}
          />
          <Stack className="buttonDetailContainer">
            <PrimaryButton
              disabled={insurancesInitialData?.numberOfActiveVehicles >= 25}
              onClick={() => { userData?.role === 'DSP' ? history.push('/add-vehicle') : history.push(`/add-vehicle/${id}`);}}
              style={{ marginBottom: 15 }}
              text={t('overviewDetail.newVehicle')}
            />
            <>
              {error === 'License plate already exist.' && (
              <div style={{ marginLeft: 'auto', marginRight: 10, color: 'red' }}>
                {t('validation.errorLicencenumber')}
              </div>
              )}
              <PrimaryCallToActionButton
                onClick={showComplete && !formState.dirty ? completeVehicleInsurance : undefined}
                style={{ width: 200, marginBottom: 15 }}
                type={showComplete && !formState.dirty ? undefined : 'submit'}
                disabled={!formState.dirty && showComplete ? vehicleData?.isCompleted : hasErrors(errors) || !formState.dirty || vehicleData?.isCompleted}
                text={showComplete && !formState.dirty ? t('overviewDetail.completeButton') : t('overviewDetail.saveButton')}
              />
            </>
          </Stack>
          <Stack className="overviewDetailContainer">
            <PrimaryButton
              disabled={insurancesInitialData?.numberOfActiveVehicles >= 25}
              onClick={() => userData?.role === 'DSP' ? history.push(`/add-vehicle?${'damageReport'}`) : history.push(`/add-vehicle/${id}?${'damageReport'}`)}
              text={t('overviewDetail.newVehicleDamage')}
            />
            {userData?.role === 'Advisor' && (
            <PrimaryButton
              onClick={sentXML}
              type="submit"
              text={t('overviewDetail.xmlButton')}
            />
              )}
          </Stack>
        </form>
      </Stack>
      {(isReportDamage === ModalFlags.REQUESTED || hiddenDeregistration === ModalFlags.REQUESTED) && (
      <ConfirmDialog
        unsavedChanges
        hidden={false}
        onDelete={() => {}}
        onDismiss={async (isOk) => {
          if (isOk) {
            handleSubmit(onSubmit)();
          } else { refetch(); }
          if (isReportDamage === ModalFlags.REQUESTED) {
            setReportDamage(ModalFlags.APPROVED);
          }
          if (hiddenDeregistration === ModalFlags.REQUESTED) {
            setHiddenDeregistration(ModalFlags.APPROVED);
          }
        }}
        contentText={t('overviewDetail.unsavedChangesModal')}
      />
      )}
      {hiddenDeregistration === ModalFlags.APPROVED && (
        <DeregistrationVehicleDialog
          vehicleId={vehicleId}
          userId={userData?.role === 'DSP' ? userData?.nameid : id}
          hidden={false}
          onDismiss={(needUpdate) => {
            if (needUpdate) { refetch(); }
            setHiddenDeregistration(ModalFlags.HIDDEN);
          }}
        />
      )}
      { !loading && !patching && !compliting && !vehicleData?.isCompleted && !vehicleData?.insuranceOffer?.isCompletedConfirmed && (
      <ConfirmationDialog
        hidden={!(showComplete && !formState.dirty && !vehicleData?.insuranceOffer?.isCompletedConfirmed)}
        onDismiss={async (isOk) => {
          if (isOk) {
            await patchVehicles({
              data: {
                insuranceOffer: {
                  ...vehicleData.insuranceOffer,
                  isCompletedConfirmed: true,
                }
              }
            });
          }
        }}
        renderContent={t('overviewDetail.informationText')}
        textButton={t('global.ok')}
      />
      )}
      {isReportDamage === ModalFlags.APPROVED && (
      <ReportDamage
        skipSend={false}
        key={rdk}
        companyName={vehicleData?.insuranceOffer?.companyInformation?.companyName}
        dspNumber={vehicleData?.insuranceOffer?.companyInformation?.amazonDspNumber}
        licenceNumber={licensePlate}
        hidden={false}
        userId={userData?.role === 'DSP' ? userData?.nameid : id}
        vehicleId={vehicleId}
        onDismiss={() => {
          redId?.callBack!();
          setTimeout(() => {
            setReportDamage(ModalFlags.HIDDEN);
          });
        }}
        reportId={redId?.id}
      />
      )}
      { selectedID &&
        <ContactView
          defaultSelectedIndex={defaultSelectedIndex}
          contactID=""
          licenseNumber={selectedID}
          userID={userData?.role === 'DSP' ? userData?.nameid : id}
          hidden={isHiddenContactForm}
          onDismiss={() => setHiddenContactForm(true)}
        />
      }
    </Stack>
  );
}
