import { ITheme } from '@fluentui/react';
import CallToActionButtonProperties from './Properties';

export default function setCallToActionButtonTheme(
  props: CallToActionButtonProperties,
  theme: ITheme
): CallToActionButtonProperties {
  let formattedProps = props;

  if (!props.theme) {
    formattedProps = { ...formattedProps, theme };
  }

  return formattedProps;
}
