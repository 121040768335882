import { VehicleType } from '@/types/VehicleType';
import { InsuranceType } from '@/types/InsuranceType';

export class VehicleInsuranceDetails {
  constructor(
    readonly id: string,
    readonly vehicleType: VehicleType,
    readonly insuranceTypes: InsuranceType[],
    readonly amount: number,
    readonly vatPercentage: number,
    claimRedemptionsPerCar: { [vehicleTypeId: string]: number },
    readonly mounthly: boolean,
  ) {
    const scaleFactor = mounthly ? 12 : 1
  
    const newInsuranceCosts: { [insuranceTypeId: string]: number } = {};
    let newNetAmount = 0;

    insuranceTypes.forEach((type) => {
      const costPerOne = +(type.costsPerCar[vehicleType.id] / scaleFactor).toFixed(2)
      
      const price = Number.parseFloat((costPerOne * amount).toFixed(2))
      newNetAmount += price;
      newInsuranceCosts[type.id] = price;
    });

    this.insuranceCosts = newInsuranceCosts;
    this.netAmount = Number(newNetAmount.toFixed(2));

    this.vatPercentage =  vatPercentage * 100;

    this.taxes = Number(((this.netAmount / 100) * this.vatPercentage).toFixed(2));
    this.claimRedemption = claimRedemptionsPerCar[vehicleType.id] * amount / scaleFactor;
    this.grossAmount = Number(
      (this.netAmount + this.taxes).toFixed(2)
    );
  }

  readonly insuranceCosts: { [insuranceTypeId: string]: number } = {};

  readonly netAmount: number;

  readonly taxes: number;

  readonly claimRedemption: number;

  readonly grossAmount: number;
}
