import React, { CSSProperties, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DropdownLanguage from '@/components/base/DropdownLanguage';
import logo from '@/assets/logo.png';
import aonLogo from '@/assets/aon-logo-white.svg';

import { useWindowWidthContext, useHeaderHeight, useMediaQuery, } from '@/hooks';
import { Stack, Panel, IconButton, ActionButton, getTheme } from '@fluentui/react';
import { Menu } from '@/components/base/Menu';
import { mainTheme } from '@/theme/themes';
import { useAuth } from '@/contexts/auth';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';

export default function Header(): JSX.Element {
  const { t } = useTranslation();
  const windowWidth = useWindowWidthContext();
  const headerHeight = useHeaderHeight();
  const { pathname } = useLocation();
  const { authorized, userData } = useAuth();
  const { isTabletOrMobile } = useMediaQuery();
  const [isOpenPanel, setIsOpenPanel] = useState(false);

  const { data } = useFetch({
    endpoint: `/api/dspusers/${userData?.nameid}`,
    skip: !userData?.nameid || !authorized || (userData?.role !== 'DSP'),
    method: FetchMethodsEnum.GET,
  });

  const handleTogglePanel = useCallback(() => {
    setIsOpenPanel(!isOpenPanel);
  }, [isOpenPanel]);

  const headerStyle: CSSProperties = {
    flexBasis: headerHeight,
    padding: windowWidth.isLarge
      ? `0 ${mainTheme.spacing.l2}`
      : `0 ${mainTheme.spacing.l1}`,
    background: mainTheme.palette.themePrimary,
    color: mainTheme.palette.white,
  };

  const titleStyle: CSSProperties = {
    fontSize: windowWidth.isSmall
      ? mainTheme.fonts.large.fontSize
      : windowWidth.isMedium
        ? mainTheme.fonts.xLargePlus.fontSize
        : mainTheme.fonts.xxLarge.fontSize,
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const amazonLogoStyle: CSSProperties = {
    height: windowWidth.isSmall
      ? '24px'
      : windowWidth.isMedium
        ? '45px'
        : '56px',
    margin: 'auto 0',
  };

  const aonLogoStyle: CSSProperties = {
    height: windowWidth.isSmall
      ? '28px'
      : windowWidth.isMedium
        ? '36px'
        : '56px',
  };

  const getLinkStyle: (path: string) => CSSProperties = (path: string) => {
    const isActive = pathname.includes(path);
    return {
      color: isTabletOrMobile ? getTheme().semanticColors.link : '#A3AAAF',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: isActive ? 'bold' : 400,
      textDecoration: 'none',
      textTransform: 'uppercase',
      textAlign: 'center',
      ...(isTabletOrMobile ? {
        backgroundColor: isActive ? getTheme().semanticColors.linkHovered : 'transparent',
        color: isActive ? 'white' : getTheme().semanticColors.link,
        padding: '8px 24px',
        display: 'flex',
        margin: '8px -24px',
        fontSize: '12px',
      } : {
        margin: '0 10px',
      }),
    };
  };

  const header: string =
    windowWidth.isSmall || windowWidth.isMedium
      ? t('header.shortLogoTitle')
      : t('header.logoTitle');

  return (
    <Stack
      style={headerStyle}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <Link to="/">
        <img src={aonLogo} alt="AON logo" style={aonLogoStyle} />
      </Link>
      <span style={titleStyle}>{header}</span>
      <a
        href="https://logistics.amazon.de/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} alt="Amazon logo" style={amazonLogoStyle} />
      </a>
      {isTabletOrMobile ? (
        <>
          <IconButton
            allowDisabledFocus
            checked={false}
            styles={{
              root: {
                color: 'white',
              },
              icon: {
                fontSize: '24px',
              }
            }}
            onClick={handleTogglePanel}
            iconProps={{ iconName: 'CollapseMenu' }}
          />
          <Panel
            headerText={t('header.shortLogoTitle')}
            isOpen={isOpenPanel}
            onDismiss={handleTogglePanel}
            closeButtonAriaLabel={t('drawer.close')}
            styles={{
              commands: {
                borderBottom: `1px solid ${getTheme().semanticColors.link}`,
                paddingBottom: '8px',
                marginBottom: '8px',
              }
            }}
          >
            {authorized && (
              <>
                {data?.email && (
                  <p style={{ fontSize: '16px' }}>
                    <b>{t('global.user.email')}</b>
                    <span>{data.email}</span>
                  </p>
                )}
                <p style={{ fontSize: '16px' }}>
                  <b>{t('global.user.role')}</b>
                  <span>{userData?.role || data?.role}</span>
                </p>
              </>
            )}
            <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center', width: '100%' }}>
              <b>{t('global.user.language')}</b>
              <DropdownLanguage />
            </div>
            <Link onClick={handleTogglePanel} to="/faq" style={getLinkStyle('/faq')}>
              <ActionButton
                style={{ color: getLinkStyle('/faq')?.color }}
                iconProps={{ iconName: 'FeedbackRequestSolid', style: {
                  color: getLinkStyle('/faq')?.color,
                  fontSize: '24px',
                  marginRight: 10,
                  textTransform: 'uppercase'
                }}}
              >
                {t('header.FAQ')}
              </ActionButton>
            </Link>
            {authorized ? (
              <>
                <Link onClick={handleTogglePanel} to="/change-password" style={getLinkStyle('/change-password')}>
                  <ActionButton
                    style={{ color: getLinkStyle('/change-password')?.color }}
                    iconProps={{
                      iconName: 'Permissions',
                      style: {
                        color: getLinkStyle('/change-password')?.color,
                        fontSize: '24px',
                        marginRight: 10,
                        textTransform: 'uppercase'
                      } }}
                  >
                    {t('header.changePassword')}
                  </ActionButton>
                </Link>
                <Link onClick={handleTogglePanel} to="/logout" style={getLinkStyle('/logout')}>
                  <ActionButton
                    style={{ color: getLinkStyle('/logout')?.color }}
                    iconProps={{
                      iconName: 'Leave',
                      style: {
                        color: getLinkStyle('/logout')?.color,
                        fontSize: '24px',
                        marginRight: 10,
                        textTransform: 'uppercase'
                      } }}
                  >
                    {t('header.logOut')}
                  </ActionButton>
                </Link>
              </>
            ) : (
              <Link onClick={handleTogglePanel} to="/login" style={getLinkStyle('/login')}>
                <ActionButton
                  style={{ color: getLinkStyle('/login')?.color }}
                  iconProps={{
                    iconName: 'Signin',
                    style: {
                      color: getLinkStyle('/login')?.color,
                      fontSize: '24px',
                      marginRight: 10,
                      textTransform: 'uppercase'
                    } }}
                >
                  {t('header.logIn')}
                </ActionButton>
              </Link>
            )}
          </Panel>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            {authorized ? (
              <>
                <Link to="/faq" style={getLinkStyle('/faq')}>{t('header.FAQ')}</Link>
                <Menu />
              </>
            ) : (
              <Link to="/login" style={getLinkStyle('/login')}>{t('header.logIn')}</Link>
            )}
            {!authorized && (
              <>
                <Link to="/faq" style={getLinkStyle('/faq')}>{t('header.FAQ')}</Link>
                <DropdownLanguage />
              </>
            )}
          </div>
        </>
      )}
    </Stack>
  );
}