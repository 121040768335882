import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import {
  DialogFooter,
  DialogContent,
  PrimaryButton,
  Dialog,
  TextField,
} from '@fluentui/react';
import {
  getRequiredError,
  getEmailError,
  validateEmail
} from '@/utils/validations';
import { useAuth } from '@/contexts/auth';


import './index.scss';

export enum RolesEnum {
  Administrator = 'Administrator',
  Advisor = 'Advisor'
}

export const ChangeInformationDialog = ({
  hidden,
  onDismiss,
  isAdmin,
  users,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const idAdmin = userData?.role === 'Administrator';

  const editNotifySucces = () => toast.success(t('toasts.editAccountDetailsSuccess'));
  const editNotifyError = () => toast.error(t('toasts.editAccountDetailsError'));

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      ...{
        companyName: (idAdmin ? users?.companyName : users?.insuranceOffer?.companyInformation?.companyName) ?? '',
        dspNumber: (idAdmin ? users?.dspNumber : users?.insuranceOffer?.companyInformation?.amazonDspNumber) ?? '',
        firstName: (idAdmin ? users?.firstName : users?.insuranceOffer?.companyInformation?.contactFirstName) ?? '',
        lastName: (idAdmin ? users?.lastName : users?.insuranceOffer?.companyInformation?.contactLastName) ?? '',
        email: (idAdmin ? users.email : users?.insuranceOffer?.companyInformation?.email) ?? '',
        role: {
          value: (users.role && users.role === 'Administrator') ? t('global.roles.administrator') : t('global.roles.advisor'),
          label: (users.role && users.role === 'Administrator') ? t('global.roles.administrator') : t('global.roles.advisor'),
        } ?? ''
      }
    }
  });

  useEffect(() => {
    reset({
      companyName: (idAdmin ? users?.companyName : users?.insuranceOffer?.companyInformation?.companyName) ?? '',
      dspNumber: (idAdmin ? users?.dspNumber : users?.insuranceOffer?.companyInformation?.amazonDspNumber) ?? '',
      firstName: (idAdmin ? users?.firstName : users?.insuranceOffer?.companyInformation?.contactFirstName) ?? '',
      lastName: (idAdmin ? users?.lastName : users?.insuranceOffer?.companyInformation?.contactLastName) ?? '',
      email: (idAdmin ? users.email : users?.insuranceOffer?.companyInformation?.email) ?? '',
      role: {
        value: (users.role && users.role === 'Administrator') ? t('global.roles.administrator') : t('global.roles.advisor'),
        label:  (users.role && users.role === 'Administrator') ? t('global.roles.administrator') : t('global.roles.advisor'),
      } ?? ''
    });
  }, [users]);

  const { makeRequest: saveDSP, loading } = useFetch({
    endpoint: `/api/dspusers/${users?.id}`,
    method: FetchMethodsEnum.PUT,
    skip: users?.id,
    onCompleted: () => {
      onDismiss(true);
      editNotifySucces();
    },
    onError: () => {
      onDismiss(true);
      editNotifyError();
    }
  });

  const { makeRequest: saveUsers } = useFetch({
    endpoint: `/api/users/${users?.id}`,
    method: FetchMethodsEnum.PUT,
    skip: users?.id,
    onCompleted: () => {
      onDismiss(true);
      editNotifySucces();
    },
    onError: () => {
      onDismiss(true);
      editNotifyError();
    }
  });

  const onSubmit = async (values) => {
    if(isAdmin) {
      await saveUsers({ data: {
        ...users,
        ...values,
        role: values.role.value === 'Administrator' ? 'Administrator' : 'Advisor'
      } });
    } else {
      const nextUsers = {
        ...users,
        insuranceOffer: {
          ...users.insuranceOffer,
          companyInformation: {
            ...users.insuranceOffer.companyInformation,
            companyName: values.companyName,
            amazonDspNumber: values.dspNumber,
            contactFirstName: values.firstName,
            contactLastName: values.lastName,
            email: values.email
          }
        }
      };
      await saveDSP({ data: {
        ...nextUsers,
        ...values
      } });
    }
  };

  const RolesEnums = {
    Administrator: t('global.roles.administrator'),
    Advisor: t('global.roles.advisor')
  };

  const roles = [
    { label: t('global.roles.advisor'), value: RolesEnums.Advisor },
    { label: t('global.roles.administrator'), value: RolesEnums.Administrator }
  ];

  return (
    <Dialog
      modalProps={{
        layerProps: { eventBubblingEnabled: true },
        onDismiss: () => onDismiss(),
      }}
      hidden={hidden}
      title={t('tableContent.modalTitle')}
      onDismiss={() => onDismiss()}
      minWidth="550px"
      dialogContentProps={{ 
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      <DialogContent>
        <form className='formContainer fields' onSubmit={handleSubmit(onSubmit)}>
          {!isAdmin && (
            <>
              <Controller
                name="companyName"
                rules={{ required: true }}
                control={control}
                as={
                  <TextField
                    label={t('tableContent.companyName')}
                    errorMessage={getRequiredError(
                      errors,
                      'companyName',
                      t('contact.companyNameErrorMessage')
                    )}
                  />}
              />
              <Controller
                name="dspNumber"
                rules={{ required: true }}
                control={control}
                as={
                  <TextField
                    label={t('tableContent.DSPNumber')}
                    errorMessage={getRequiredError(
                      errors,
                      'dspNumber',
                      t('contact.dspNumberErrorMessage')
                    )}
                  />
                }
              />
            </>
          )}
          <Controller
            name="firstName"
            rules={{ required: true }}
            control={control}
            as={
              <TextField
                label={t('tableContent.firstName')}
                errorMessage={getRequiredError(
                  errors,
                  'firstName',
                  t('contact.firstNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="lastName"
            rules={{ required: true }}
            control={control}
            as={
              <TextField
                label={t('tableContent.lastName')}
                errorMessage={getRequiredError(
                  errors,
                  'lastName',
                  t('contact.lastNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="email"
            rules={{
              required: true,
              validate: {
                emailFormat: validateEmail,
              },
            }}
            control={control}
            as={
              <TextField
                label={t('tableContent.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />
          { isAdmin && (
            <Controller
              name="role"
              rules={{ required: true }}
              control={control}
              as={
                <ThemedSelect
                  label={t('tableContent.role')}
                  options={roles}
                  errorMessage={getRequiredError(errors, 'role', 'Role is required')}
                />
              }
            />
          )}
        </form>
      </DialogContent>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        }
      }}
      >
        <PrimaryButton
          disabled={loading}
          className='changeInformationButtonStyles'
          onClick={() => onDismiss()}
          text={t('global.cancel')}
        />
        <PrimaryButton
          disabled={loading || !formState.dirty}
          type="submit"
          className='changeInformationButtonStyles'
          onClick={handleSubmit(onSubmit)}
          text={t('global.save')}
        />
      </DialogFooter>
    </Dialog>
  );
};

ChangeInformationDialog.defaultProps = {
  users: {}
};
