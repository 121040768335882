import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState } from 'react';
  
import { Spinner } from 'office-ui-fabric-react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  DialogFooter, DialogContent,
  PrimaryButton, Dialog, Label, getTheme
} from '@fluentui/react';
  
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
  
type ValuesType = {
  file: null | any[];
};
  
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: getTheme().semanticColors.inputBorder,
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
  
const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  color: getTheme().semanticColors.primaryButtonBackground,
  borderColor: getTheme().semanticColors.primaryButtonBackground,
  borderStyle: 'solid',
};
  
const rejectStyle = {
  borderColor: '#ff1744'
};
  
export const UploadFileDialog = ({
  hidden,
  onDismiss,
  userId,
  uploadFile
}) => {
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    defaultValues: {
      file: null,
    } as unknown as ValuesType,
  });
  const { t } = useTranslation();
  const { file } = watch();
  const [fileNames, setFileNames] = useState([] as string[]);

  useEffect(() => {
    reset({
      file: null,
    });
    setFileNames([]);
  }, [hidden]);
  
  const onDrop = useCallback(acceptedFiles => {
    setValue('file', acceptedFiles[0]);
    setFileNames([...acceptedFiles].map(
      // eslint-disable-next-line no-shadow
      file => file.name
    ));
  }, [setValue]);

  const { data } = useFetch({
    endpoint: '/api/dspusers/documents/formats',
    method: FetchMethodsEnum.GET,
  });
  
  const acceptFormats = `.${data?.join(', .')}`;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptFormats, onDrop,  multiple: false });
  
  const notifySucces = () => toast.success(t('toasts.uploadingSEPASuccess'));
  const notifyError = () => toast.error(t('toasts.uploadingSEPAError'));

  const uploadFileNotifySucces = () => toast.success(t('toasts.uploadingFileSuccess'));
  const uploadFileNotifyError = () => toast.error(t('toasts.uploadingFileError'));

  const [loading, setLoading] = useState(false);
  const { makeRequest: send } = useFetch({
    endpoint: `/api/dspusers/${userId}/signedSepa`,
    skip: userId,
    method: FetchMethodsEnum.POST,
    onLoadingStart: () => setLoading(true),
    onCompleted: () => {
      onDismiss(true);
      setLoading(false);
      notifySucces();
    },
    onError: () => {
      notifyError();
    }
  });
  
  const { makeRequest: sendFile } = useFetch({
    endpoint: `/api/dspusers/${userId}/documents`,
    skip: userId,
    method: FetchMethodsEnum.POST,
    onLoadingStart: () => setLoading(true),
    onCompleted: () => {
      onDismiss(true);
      setLoading(false);
      uploadFileNotifySucces();
    },
    onError: () => {
      uploadFileNotifyError();
    }
  });
  
  const onSubmit = async (values) => {
    const formData = new FormData();

    if(uploadFile) {
      formData.append('document', values.file);
      await sendFile({ formData });
    } else {
      formData.append('sepaDocument', values.file);
      await send({ formData });
    }
  };
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]) as CSSProperties;
  
  return (
    <Dialog
      modalProps={{
        layerProps: { eventBubblingEnabled: true },
      }}
      hidden={hidden}
      title={uploadFile ? t('overview.uploadFileDialogTitle') : t('overview.uploadSEPAFileDialogTitle')}
      onDismiss={onDismiss}
      minWidth="550px"
      dialogContentProps={{ 
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      <DialogContent>
        {!loading ? (
          <form style={{ marginTop: '-30px' }} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="file"
              control={control}
              as={
                <div>
                  {uploadFile ? <Label>{t('overview.uploadFileDialogField')}</Label> : <Label>{t('overview.uploadSEPAFileDialogField')}</Label>}
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />  
                    <p>{t('overviewDetail.deregister.placeholder.upload')}</p>
                  </div>
                  {fileNames.map(fileName => (
                    <li style={{ fontStyle: 'oblique', opacity: '0.7', marginTop: 7 }} key={fileName}>{fileName}</li>
                  ))}
                </div>
              }
            />
          </form>
        ) : (
          <Spinner />
        )}
      </DialogContent>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        } 
      }}
      >
        <PrimaryButton disabled={loading} style={{ margin: 10 }} onClick={onDismiss} text={t('global.cancel')} />
        <PrimaryButton
          disabled={loading || !file}
          type="submit"
          style={{ margin: 10 }}
          onClick={handleSubmit(onSubmit)}
          text={t('global.send')}
        />
      </DialogFooter>
    </Dialog>
  );
};
  