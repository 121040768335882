import React, { useState, CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { mainTheme } from '@/theme/themes';
import { useAuth } from '@/contexts/auth';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';
import { PrimaryButton, Label, Stack } from '@fluentui/react';
import { SpinnerDefault } from '@/components/base/SpinnerDefault';
import { InsuranceMasterDataDto } from '@/components/pages/Wizard/insuranceMasterDataDto';
import { AddVehicleReportDamage } from '@/components/base/AddVehicleReportDamage';
import { ReportDamage } from '@/components/base/ReportDamage';
import { useCalculation } from '@/contexts/calculation';

import { createDefaultInsurance } from '../Wizard/InsureVehicles/createDefaultInsurance';
import InsuredVehicles from '../Wizard/InsureVehicles/InsuredVehicles';

import './index.scss';

export default function AddVehicle(): JSX.Element {
  const { mountly, paymentMethod, reset } = useCalculation();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [insurances, setInsurances] = useState<VehicleInsuranceDetails[]>();
  const [masterData, setMasterData] = useState<InsuranceMasterDataDto>();
  const [isReportDamage, setReportDamage] = useState(false);
  const [isHiddenModal, setHiddenModal] = useState(true);
  const [isHiddenReportDamageModal, setHiddenReportDamageModal] = useState(true);
  const [amountOfInsuredVehicles, setAmountOfInsuredVehicles] = useState(0);
  const { userData } = useAuth();


  const notifySucces = () => toast.success(t('toasts.addNewVechicleSuccess'));
  const notifyError = () => toast.error(t('toasts.addNewVechicleError'));

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    setInsurances(insurances?.map(insurance => {
      return new VehicleInsuranceDetails(
        insurance.id,
        insurance.vehicleType,
        insurance.insuranceTypes,
        insurance.amount,
        masterData?.vatPercentage as any,
        masterData?.claimRedemptionsPerCar as any,
        mountly,
      );
    }));
  }, [mountly, JSON.stringify(insurances)]);


  const { id: userId } = useParams() as any;

  const nameId = location.pathname.replace('/add-vehicle/', '');

  const rootStyle: CSSProperties = {
    margin: mainTheme.spacing.m,
    flexGrow: 1,
  };

  const containerStyle: CSSProperties = {
    flexGrow: 1,
    padding: 20
  };

  const headerLabelStyle: CSSProperties = {
    fontWeight: 'bold',
    fontSize: mainTheme.fonts.large.fontSize,
  };

  useEffect(() => {
    if(location.search === '?damageReport'){
      setReportDamage(true);
    } else {
      setReportDamage(false);
    }
  });

  const onInsurancesChanged = (newInsurances: VehicleInsuranceDetails[]) => {
    setInsurances(newInsurances);

    setAmountOfInsuredVehicles(newInsurances.reduce(
      (prevValue, currentValue) => {
        return prevValue + currentValue.amount;
      },
      0
    ));
  };

  const { loading } = useFetch({
    endpoint: '/api/insuranceMasterData',
    method: FetchMethodsEnum.GET,
    onCompleted: data => {
      const newMasterData: InsuranceMasterDataDto = {
        vehicleTypes: data?.vehicleTypes,
        insuranceTypes: data?.insuranceTypes,
        paymentMethods: data?.paymentMethods.map((item) => {
          return ({
            value: `${item.id}`,
            label: `paymentMethods.${item.id}`,
          });
        }),
        businessLiabilityInsurancePrices: [],
        trafficLegalProtectionPrices: [],
        driverLegalProtectionPrices: [],
        groupAccidentInsurancePrices: [],
        trafficLiabilityInsurancePrices: [],
        claimRedemptionsPerCar: data?.claimRedemptionsPerCar,
        vatPercentage: data?.vatPercentage,
      };
      getInsurancesData();
      setMasterData(newMasterData);
      onInsurancesChanged([createDefaultInsurance(newMasterData)]);
    }
  });

  const { loading: pmIsLoading } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : userId}`,
    method: FetchMethodsEnum.GET,
    skip: !masterData,
    onCompleted: (data) => {
      const {
        insuranceOffer: {
          paymentMethod: pm,
        }
      } = data;
      const method = pm === 'annual' ? (
        masterData?.paymentMethods.find(({ value }) => value === 'annualMethod')
      ) : (
        masterData?.paymentMethods.find(({ value }) => value === 'monthlyMethod')
      );
    
      paymentMethod.set(method as any);
    },
  });

  const baseData = insurances?.map(insurance => ({
    'vehicleType': insurance.vehicleType.id,
    'numberOfVehicles': insurance.amount,
    'isComprehensiveCoverSelected': insurance.insuranceTypes.some(
      (usedType) => usedType.id === 'comprehensiveCover',
    ),
    'isLiabilitySelected': true,
    'isGapCoverageSelected': insurance.insuranceTypes.some(
      (usedType) => usedType.id === 'gapCoverage',
    ),
    'thirdPartyLiability': insurance.insuranceCosts['thirdPartyLiability'],
    'comprehensiveCover': insurance.insuranceCosts['comprehensiveCover'],
    'gapCoverage': insurance.insuranceCosts['gapCoverage'],
    'netTotal': insurance.grossAmount, 
    'vat': insurance.vatPercentage / 100,
    'vehicles': null
  }));

  const { data, makeRequest: addVehicleInsurances, error } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : nameId}/vehicleInsurances`,
    method: FetchMethodsEnum.POST,
    data: baseData,
    onCompleted: () => {
      isReportDamage ? setHiddenReportDamageModal(false) : userData?.role === 'DSP' ?  history.push('/overview-detail') : history.push(`/overview-detail/${nameId}`);
      !isReportDamage && notifySucces();
    },
    onError: () => {
      !isReportDamage && notifyError();
    }
  });

  const { data: insurancesInitialData, makeRequest: getInsurancesData } = useFetch({
    endpoint: `/api/dspusers/${userData?.role === 'DSP' ? userData?.nameid : nameId}/vehicleInsurances/initialData`,
    method: FetchMethodsEnum.GET,
  });

  const maxVehiclesAmount = 25 - insurancesInitialData?.numberOfActiveVehicles;

  return (
    <Stack style={rootStyle} className='addVehicle'>
      {loading || pmIsLoading ? (
        <SpinnerDefault />
      ) : (
        <>
          <Stack style={containerStyle}>
            <Stack style={{ marginBottom: mainTheme.spacing.m }}>
              <Label style={headerLabelStyle}>
                {t('overviewDetail.addVehicleTitle')}
              </Label>
            </Stack>
            <InsuredVehicles
              disabledPaymentMethod={true}
              reportDamage={isReportDamage}
              insurances={insurances}
              onChange={onInsurancesChanged}
              maxVehiclesAmount={maxVehiclesAmount}
              masterData={masterData}
            />
          </Stack>
          <div style={{ display: 'flex' }}>
            <PrimaryButton
              text={t('global.cancel')}
              style={{ marginTop: 20, marginRight: 'auto' }}
              onClick={() => {userData?.role === 'DSP' ? history.push('/overview-detail') : history.push(`/overview-detail/${nameId}`);}}
            />
            <PrimaryCallToActionButton
              text={isReportDamage ? t('global.continue') : t('global.save')}
              iconProps={{ iconName: isReportDamage ? 'ChromeBackMirrored' : '' }}
              disabled={!amountOfInsuredVehicles}
              style={{ marginLeft: 'auto', marginTop: 20, maxWidth: '120px' }}
              onClick={() => { isReportDamage ? setHiddenModal(false) : addVehicleInsurances();}}
            />
          </div>
          <AddVehicleReportDamage
            hidden={isHiddenModal}
            onDismiss={() => {
              getInsurancesData();
              setHiddenModal(true);
            }}
            addVehicleInsurances={addVehicleInsurances}
            baseData={baseData}
            error={error}
          />
          <ReportDamage
            companyName={insurancesInitialData?.companyName}
            dspNumber={insurancesInitialData?.amazonDspNumber}
            licenceNumber={data && data[0] && data[0].vehicles && data[0].vehicles[0] && data[0].vehicles[0].licensePlate}
            hidden={isHiddenReportDamageModal}
            userId={userData?.role === 'DSP' ? userData?.nameid : id}
            vehicleId={data && data[0] && data[0].vehicles && data[0].vehicles[0] && data[0].vehicles[0].vehicleId}
            onDismiss={() => {
              userData?.role === 'DSP' ? history.push('/overview-detail') : history.push(`/overview-detail/${nameId}`);
              setHiddenReportDamageModal(true);
            }}
            reportId={null}
            skipSend 
          />
        </>
      )}
    </Stack>
  );
}
