import { sortArrayDesc } from '@/utils/sortArray';

export interface PriceByAmount {
  amount: number;
  amountTo: number;
  onlyLabel?: boolean;
  prices: {
    annualPrice: number;
    monthlyPrice: number;
  };
}

export function getPriceByAmount(
  tierList: PriceByAmount[],
  amount: number,
  mountly: boolean,
) : number {
  const sortedList = sortArrayDesc(tierList, 'amount');

  for (let i = 0; i < sortedList.length; ++i) {
    if (sortedList[i].amount <= amount) {
      return sortedList[i].prices[mountly ? 'monthlyPrice' : 'annualPrice'];
    }
  }

  return sortedList[sortedList.length - 1].prices[mountly ? 'monthlyPrice' : 'annualPrice'];
}
