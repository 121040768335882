import React from 'react';

import {
  DialogFooter,
  DialogContent,
  PrimaryButton,
  Dialog
} from '@fluentui/react';

export const ConfirmationDialog = ({
  hidden,
  onDismiss,
  renderContent,
  textButton
}) => {
  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="600px"
      styles={{
        root: {
          boxShadow: '0 0 10px rgba(0,0,0,0.3)'
        }
      }}
      dialogContentProps={{
        styles: {
          inner: {
            padding: 0,
          },
        }
      }}
    >
      <DialogContent>
        {renderContent}
      </DialogContent>
      <DialogFooter styles={{
        actionsRight: {
          margin: 0,
        }
      }}
      >
        <PrimaryButton style={{ margin: 10 }} onClick={() => onDismiss(true)} text={textButton} />
      </DialogFooter>
    </Dialog>
  );
};
