import React, { useEffect, useState, CSSProperties } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@/hooks';
import parse from 'html-react-parser';
import axios from 'axios';

import { Checkbox, Stack, TextField } from '@fluentui/react';
import {
  getRequiredError,
  getEmailError,
  validateEmail,
  validateNumericOnly,
  getNumericOnlyError,
  validateIban,
  getIbanError,
  getConfirmPasswordError,
  getPasswordError
} from '@/utils/validations';
import PasswordStrengthBar from '@/components/base/PasswordStrengthBar';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import useDebounce from '@/components/pages/OverviewDamageAdvisor/useDebaunce';
import { ClientDetails } from '@/components/pages/Wizard/SubmitInsurances/ClientDetails';

import './index.scss';
import { useAuth } from '@/contexts/auth';

interface Types {
  clientDetails: ClientDetails;
  onChange: (details: ClientDetails) => void;
  renderSummary: any;
}

function getPostalCodeError(errors: any, t: (key: string) => string,): string {
  const reqError: string = getRequiredError(
    errors,
    'zipCode',
    t('contact.postalRequiredCodeError')
  );
  if (reqError) {
    return reqError;
  }

  const numOnlyError: string = getNumericOnlyError(
    errors,
    'zipCode',
    t('validation.postalCodeError')
  );
  return numOnlyError || '';
}

const inputStyle: CSSProperties = {
  width: 192,
};

const inputMobileStyle: CSSProperties = {
  maxWidth: '50%',
  width: '100%',
};

export default function ClientDetailsForm(props: Types) {
  const { clientDetails, onChange, renderSummary } = props;
  const { t, i18n } = useTranslation();
  const { isMobile } = useMediaQuery();
  const { setIsStrong } = useAuth();

  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);

  const { control, watch, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyName: clientDetails?.companyName,
      amazonDspNumber: clientDetails?.amazonDspNumber,
      phoneNumber: clientDetails?.phoneNumber,
      firstName: clientDetails?.firstName,
      lastName: clientDetails?.lastName,
      email: clientDetails?.email,
      password: clientDetails?.password,
      confirmPassword: clientDetails?.confirmPassword,
      street: clientDetails?.street,
      zipCode: clientDetails?.zipCode,
      city: clientDetails?.city,
      paymentMethod: { label: clientDetails?.paymentMethod?.label || t('contact.invoicePaymentMethod'), value: clientDetails?.paymentMethod?.value || 'false'},
      iban: clientDetails?.iban,
      agreed: c1,
      confirm: c2,
    },
  });

  const {
    companyName,
    amazonDspNumber,
    phoneNumber,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    street,
    zipCode,
    city,
    paymentMethod,
    iban,
  } = watch();

  useEffect(() => {
    onChange(
      new ClientDetails(
        companyName,
        amazonDspNumber,
        phoneNumber,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        street,
        zipCode,
        city,
        paymentMethod,
        iban,
        c1,
        c2,
      )
    );
  }, [
    companyName,
    amazonDspNumber,
    phoneNumber,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    street,
    zipCode,
    city,
    paymentMethod,
    iban,
    c1,
    c2
  ]);

  const validateSameAsPassword = (value: string | undefined) => {
    const pass = getValues('password');
    return !value || value === pass;
  };

  const isPassword = watch('password');
  const debouncedPassword = useDebounce(isPassword, 200);

  const paymentsMethod = [
    { label: t('contact.invoicePaymentMethod'), value: 'false' },
    { label: t('contact.sepaPaymentMethod'), value: 'true' }
  ];

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Stack tokens={{ childrenGap: 4 }} style={{ margin: '0 auto', maxWidth: '100%' }}>
          <Controller
            name="companyName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.companyName')}
                errorMessage={getRequiredError(
                  errors,
                  'companyName',
                  t('contact.companyNameErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="amazonDspNumber"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.amazonDspNumber')}
                errorMessage={getRequiredError(
                  errors,
                  'amazonDspNumber',
                  t('contact.amazonDspNumberErrorMessage')
                )}
              />
            }
          />
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.phoneNumber')}
                errorMessage={getRequiredError(
                  errors,
                  'phoneNumber',
                  t('contact.phoneNumberErrorMessage')
                )}
              />
            }
          />
          <Stack horizontal tokens={{ childrenGap: 12, maxWidth: '100%' }}>
            <Controller
              name="firstName"
              control={control}
              style={isMobile ? inputMobileStyle : inputStyle}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('contact.firstName')}
                  errorMessage={getRequiredError(
                    errors,
                    'amazonDspNumber',
                    t('contact.firstNameErrorMessage')
                  )}
                />
              }
            />
            <Controller
              name="lastName"
              control={control}
              style={isMobile ? inputMobileStyle : inputStyle}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('contact.lastName')}
                  errorMessage={getRequiredError(
                    errors,
                    'lastName',
                    t('contact.lastNameErrorMessage')
                  )}
                />
              }
            />
          </Stack>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                emailFormat: validateEmail,
              },
            }}
            as={
              <TextField
                label={t('contact.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
            }}
            as={
              <TextField
                type="password"
                canRevealPassword={true}
                label={t('global.password')}
                errorMessage={getPasswordError(
                  errors,
                  'password',
                  t
                )}
              />
            }
          />
          <PasswordStrengthBar onStrong={setIsStrong} password={debouncedPassword} />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: true,
              validate: {
                sameAsPassword: validateSameAsPassword,
              }
            }}
            as={
              <TextField
                type="password"
                canRevealPassword={true}
                label={t('login.confirmPassword')}
                errorMessage={getConfirmPasswordError(
                  errors,
                  'confirmPassword',
                  t,
                )}
              />
            }
          />

          <Controller
            name="street"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.street')}
                errorMessage={getRequiredError(
                  errors,
                  'street',
                  t('contact.streetErrorMessage')
                )}
              />
            }
          />

          <Stack horizontal tokens={{ childrenGap: 12, maxWidth: '100%' }}>
            <Controller
              name="zipCode"
              control={control}
              style={isMobile ? inputMobileStyle : inputStyle}
              rules={{
                required: true,
                validate: {
                  numericOnly: validateNumericOnly,
                },
              }}
              as={
                <TextField
                  label={t('contact.zipCode')}
                  errorMessage={getPostalCodeError(errors, t)}
                />
              }
            />
            <Controller
              name="city"
              control={control}
              style={isMobile ? inputMobileStyle : inputStyle}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('contact.city')}
                  errorMessage={getRequiredError(
                    errors,
                    'location',
                    t('contact.cityErrorMessage')
                  )}
                />
              }
            />
          </Stack>
        </Stack>
        <Stack style={{ margin: '0 auto',  alignItems: 'center', maxWidth: '100%' }}>
          {renderSummary()}
          <div style={{ maxWidth: 400, width: '100%', marginTop: 'auto' }}>
            <Controller
              name="paymentMethod"
              rules={{ required: true }}
              control={control}
              as={
                <ThemedSelect
                  label={t('contact.paymentMethod')}
                  options={paymentsMethod}
                />
              }
            />
            { (getValues() as any)?.paymentMethod?.value === 'true' && (
            <Controller
              name="iban"
              control={control}
              rules={{
                required: true,
                validate: {
                  iban: validateIban,
                },
              }}
              as={
                <TextField
                  className='upper'
                  label={t('contact.iban')}
                  errorMessage={getIbanError(errors, 'iban', t)}
                />
              }
            />
          )}
            <Checkbox
              onChange={(e, c) => setC1(!!c)}
              styles={{ root: { margin: '15px 0' } }}
              onRenderLabel={() => (
                <span className="links">
                  {
                    parse(
                      t('wizardStepThree.pdfCheckbox', {
                        link1: `${axios.defaults.baseURL}/api/dspusers/generalTermsOfBusiness?lang=${i18n.language}`,
                        link2: `${axios.defaults.baseURL}/api/dspusers/dataProtectionNotice?lang=${i18n.language}`,
                        link3: `${axios.defaults.baseURL}/api/dspusers/initialInformation?lang=${i18n.language}`,
                      }))
                    }
                </span>
              ) as any}
            />
            <Checkbox
              onChange={(e, c) => setC2(!!c)}
              label={t('wizardStepThree.confirmCheckbox')}
            />
          </div>
        </Stack>
      </div>
    </form>
  );
}
