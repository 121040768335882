import React, { CSSProperties, useRef, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack, IStackProps,
  Checkbox,Label,
  PrimaryButton,
  Callout, DefaultButton,
  Text, Icon
} from '@fluentui/react';
import { mainTheme } from '@/theme/themes';
import { VehicleType } from '@/types/VehicleType';
import { InsuranceType } from '@/types/InsuranceType';
import { useWindowWidthContext } from '@/hooks';
import { useMediaQuery } from '@/hooks';
import ThemedSelect from '@/components/base/OfficeUI/ThemedSelect';
import {
  buildSelectOption,
  SelectOption,
} from '@/components/pages/Wizard/InsureVehicles/SelectOption';
import { formatCurrency } from '@/utils/formating';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';

import { InsuranceMasterDataDto } from '../insuranceMasterDataDto';
import { useCalculation } from '@/contexts/calculation';

interface Types {
  insurance: VehicleInsuranceDetails;
  insuranceMasterData: InsuranceMasterDataDto;
  reportDamage?: boolean;
  maxAmount: number;
  vatPercentage: number;
  onChange: (
    oldValue: VehicleInsuranceDetails,
    newValue: VehicleInsuranceDetails | null
  ) => void;
}

export const insuranceCardStyle: CSSProperties = {
  backgroundColor: mainTheme.palette.white,
  boxShadow: mainTheme.effects.elevation8,
  padding: `${mainTheme.spacing.l1} ${mainTheme.spacing.l2}`,
  marginTop: 20
};

export default function VehicleInsuranceCard(props: Types) {
  const { t } = useTranslation();
  const windowWidth = useWindowWidthContext();
  const deleteButtonRef = useRef(null);
  const { paymentMethod, mountly } = useCalculation()
  const { isMobile } = useMediaQuery();
  const [isDeleteCalloutVisible, setIsDeleteCalloutVisible] = useState<boolean>(
    false
  );

  const insuranceDetailsStack: IStackProps = {
    horizontal: true,
    horizontalAlign: 'baseline',
  };

  const collectDataStyle: CSSProperties = {
   width: '40%',
  };

  const containerStyle: CSSProperties  = {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px 0'
  };

  const infoStyle: CSSProperties = {
    width: '40%'
  };

  const insuranceSummaryStyle: CSSProperties = {
    flexGrow: 1
  };

  const costSummaryStyle: CSSProperties = {};

  const costLabelStyle: CSSProperties = {
    paddingRight: mainTheme.spacing.l1,
  };

  const priceStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  if (windowWidth.windowWidth < 700) {
    insuranceDetailsStack.horizontal = false;
    insuranceDetailsStack.horizontalAlign = 'center';

    infoStyle.marginTop = mainTheme.spacing.m;
    infoStyle.marginLeft = 0;
    infoStyle.alignItems = 'center';

    costSummaryStyle.marginTop = mainTheme.spacing.m;
  }

  const vehicleTypeOptions = props.insuranceMasterData.vehicleTypes.map(
    (type) => buildSelectOption(`vehicles.${type.id}`, type)
  );

  const amountOptions: SelectOption<number>[] = [];
  for (let i = 1; i <= props.maxAmount; ++i) {
    amountOptions.push(buildSelectOption(i.toString(), i));
  }

  function onVehicleTypeChanged(newValue: SelectOption<VehicleType>): void {
    const newInsurance: VehicleInsuranceDetails = new VehicleInsuranceDetails(
      props.insurance.id,
      newValue.data,
      props.insurance.insuranceTypes,
      props.insurance.amount,
      props.insuranceMasterData.vatPercentage,
      props.insuranceMasterData.claimRedemptionsPerCar,
      mountly,
    );
    props.onChange(props.insurance, newInsurance);
  }

  function onAmountChanged(newValue: SelectOption<number>): void {
    const newInsurance: VehicleInsuranceDetails = new VehicleInsuranceDetails(
      props.insurance.id,
      props.insurance.vehicleType,
      props.insurance.insuranceTypes,
      newValue.data,
      props.insuranceMasterData.vatPercentage,
      props.insuranceMasterData.claimRedemptionsPerCar,
      mountly,
    );
    props.onChange(props.insurance, newInsurance);
  }

  function onInsuranceTypeChanged(
    insuranceValue: InsuranceType,
    isUsed: boolean
  ): void {
    const isInsuranceUsed: boolean = props.insurance.insuranceTypes.some(
      (type) => type.id === insuranceValue.id
    );

    const newInsuranceTypes =
      !isUsed && isInsuranceUsed
        ? props.insurance.insuranceTypes.filter(
          (type) => type.id !== insuranceValue.id
        )
        : props.insurance.insuranceTypes.slice();

    if (isUsed && !isInsuranceUsed) {
      newInsuranceTypes.push(insuranceValue);
    }

    const newInsurance: VehicleInsuranceDetails = new VehicleInsuranceDetails(
      props.insurance.id,
      props.insurance.vehicleType,
      newInsuranceTypes,
      props.insurance.amount,
      props.insuranceMasterData.vatPercentage,
      props.insuranceMasterData.claimRedemptionsPerCar,
      mountly,
    );
    props.onChange(props.insurance, newInsurance);
  }

  const amountScaleFactor = mountly ? 12 : 1;

  return (
    <Stack style={insuranceCardStyle}>
      {!props?.reportDamage && (
        <div ref={deleteButtonRef} style={isMobile ?  { marginLeft: 'auto' } : { marginLeft: 'auto', marginRight: 50 }}>
          <Icon
            style={{ fontSize: 22, fontWeight: 700, cursor: 'pointer' }}
            iconName="trash"
            onClick={() => setIsDeleteCalloutVisible(true)} />
        </div>
      )}
      <Stack {...insuranceDetailsStack} style={containerStyle}>
        <Stack style={isMobile ? { width: '95%' } : collectDataStyle} tokens={{ childrenGap: 8 }}>
          <ThemedSelect
            label={t('wizardStepOne.typeVehicle')}
            options={vehicleTypeOptions}
            value={vehicleTypeOptions.find(
              (option) => option.data.id === props.insurance.vehicleType.id
            )}
            onChange={
              (value) =>
                onVehicleTypeChanged(value as SelectOption<VehicleType>)
            }
          />
          <ThemedSelect
            label={t('wizardStepOne.numberVehicle')}
            options={amountOptions}
            selectDisabled={props?.reportDamage}
            value={amountOptions.find(
              (option) => option.data === props.insurance.amount
            )}
            onChange={(value: any) => onAmountChanged(value)}
          />
          <Label>{t('wizardStepOne.scopeInsurance')}</Label>
          {props.insuranceMasterData.insuranceTypes.map((type) => (
            <Checkbox
              key={type.id}
              label={t(`insurance.${type.id}`)}
              disabled={type.isMandatory}
              checked={props.insurance.insuranceTypes.some(
                (usedType) => usedType.id === type.id
              )}
              onChange={(_?: any, checked?: boolean) =>
                onInsuranceTypeChanged(type, checked == null ? false : checked)
              }
            />
          ))}
        </Stack>

        <Stack style={isMobile ? { width: '95%' } : infoStyle} tokens={{ childrenGap: 24 }}>
          <Stack style={insuranceSummaryStyle}>
            <div style={{ fontWeight: 'bold' }}>{t('wizardStepOne.insuranceSummaryBlock.title')}</div>
            <table>
              <tbody>
                {props.insuranceMasterData.insuranceTypes.map((insuranceType) => {
                  return (
                    <tr key={insuranceType.id}>
                      <td style={costLabelStyle}>{t(`wizardStepOne.insuranceSummaryBlock.${insuranceType.id}`)}</td>
                      <td style={priceStyle}>
                        {formatCurrency(
                          insuranceType.costsPerCar[props.insurance.vehicleType.id] / amountScaleFactor
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Stack>

          <Stack style={costSummaryStyle}>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td style={{ paddingBottom: '16px' }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{t('global.summary')}</Text>
                  </td>
                </tr>
                {props.insurance.insuranceTypes.map((type) => {
                  return (
                    <tr key={type.id}>
                      <td style={costLabelStyle}>{t(`insurance.${type.id}`)}</td>
                      <td style={priceStyle}>
                        {formatCurrency(
                          props.insurance.insuranceCosts[type.id]
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ borderTop: '1px solid black' }} />
                  <td style={{ borderTop: '1px solid black' }} />
                </tr>
                <tr>
                  <td style={costLabelStyle}>{t('wizardStepOne.totalPremium')}</td>
                  <td style={priceStyle}>
                    {formatCurrency(props.insurance.netAmount )}
                  </td>
                </tr>
                <tr>
                  <td style={costLabelStyle}>
                    {t('wizardStepOne.taxes', {
                      vatPercentage: props.vatPercentage * 100,
                    })}
                  </td>
                  <td style={priceStyle}>
                    {formatCurrency(props.insurance.taxes )}
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }} />
                  <td style={{ borderTop: '1px solid black' }} />
                </tr>
                <tr>
                    <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
                      {t('wizardStepOne.grossPremiumMonthly')}
                    </td>
                    <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                      {formatCurrency(props.insurance.grossAmount)}
                    </td>
                  </tr>
                 <tr>
                  <td style={{ paddingTop: 15, fontSize: 14 }}>
                    {t('additionalService.taxesInformationText')}
                  </td>
                </tr>
              </tbody>
            </table>

            {!props?.reportDamage && (
            <Stack
              style={{ marginTop: mainTheme.spacing.m }}
              horizontalAlign="end"
            >
            </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      {isDeleteCalloutVisible && (
        <Callout
          target={deleteButtonRef}
          onDismiss={() => setIsDeleteCalloutVisible(false)}
        >
          <Stack style={{ padding: mainTheme.spacing.m }}>
            <p>{t('wizardStepOne.questionInsurance')}</p>

            <Stack
              horizontal
              tokens={{ childrenGap: 12 }}
              style={{
                marginTop: mainTheme.spacing.m,
                justifyContent: 'flex-end',
              }}
            >
              <DefaultButton
                text={t('global.cancel')}
                onClick={() => setIsDeleteCalloutVisible(false)}
              />
              <PrimaryButton
                text={t('global.remove')}
                onClick={() => {
                  setIsDeleteCalloutVisible(false);
                  props.onChange(props.insurance, null);
                }}
              />
            </Stack>
          </Stack>
        </Callout>
      )}
    </Stack>
  );
}
