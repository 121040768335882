import React from 'react';
import { Fabric, initializeIcons, loadTheme, Stack } from '@fluentui/react';
import { BrowserRouter } from 'react-router-dom';

import { mainTheme } from '@/theme/themes';
import AppShell from '@/components/base/AppShell';
import { CalculationProvider } from '@/contexts/calculation';

import '@/translations';
import { AuthProvider } from '@/contexts/auth';

initializeIcons('./icons/');
loadTheme(mainTheme);

export default () => {
  return (
    <AuthProvider>
      <CalculationProvider>
        <Fabric>
          <BrowserRouter>
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="stretch"
              style={{
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: mainTheme.palette.neutralLight,
              }}
            >
              <AppShell />
            </Stack>
          </BrowserRouter>
        </Fabric>
      </CalculationProvider>
    </AuthProvider>
  );
};
