import { v4 as uuidV4 } from 'uuid';
import { InsuranceMasterDataDto } from '../insuranceMasterDataDto';
import { VehicleInsuranceDetails } from '../../../../utils/services/vehicleInsurancedetails';

export function createDefaultInsurance(
  masterData: InsuranceMasterDataDto,
  mountly: boolean = true,
): VehicleInsuranceDetails {
  const newInsurance: VehicleInsuranceDetails = new VehicleInsuranceDetails(
    uuidV4(),
    masterData.vehicleTypes[0],
    masterData.insuranceTypes.filter((type) => true),
    1,
    masterData.vatPercentage,
    masterData.claimRedemptionsPerCar,
    mountly,
  );
  return newInsurance;
}
