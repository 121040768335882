import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, Stack, Text, IOverflowSetItemProps, CommandBarButton } from '@fluentui/react';

import { ReportDamageAdditionalProducts } from '@/components/base/ReportDamageAdditionalProducts';
import { ContactView } from '@/components/base/ContactView';

import { ActionSet } from './Actions';

import './index.scss';

type CardOptions = {
  key: string;
  value: string | number;
};

type CardProps = {
  title: string;
  actionLabel: string;
  onAction: () => void;
  options: CardOptions[];
  showButton?: boolean;
  hide?: boolean;
  id?: string;
  userId?: string;
  data?: any;
  type?: any;
};

const onRenderItemStyles = {
  root: { padding: '10px' },
};

const onRenderOverflowButtonStyles = {
  root: { padding: '10px', background: 'transparent' },
  menuIcon: { fontSize: '16px' },
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      styles={onRenderOverflowButtonStyles}
      menuIconProps={{ iconName: 'CollapseMenu' }}
      menuProps={{ items: overflowItems! }}
    />
  );
};

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <CommandBarButton
      key={item.key}
      role="menuitem"
      aria-label={item.name}
      styles={onRenderItemStyles}
      iconProps={{ iconName: item.icon }}
      onClick={item.onClick}
    />
  );
};

export const Card = (props: CardProps) => {
  const { title, options, actionLabel, onAction, hide } = props;
  const [selectedID, setSelectedID] = useState();
  const [isHiddenModal, setHiddenModal] = useState(true);
  const [isHiddenContactForm, setHiddenContactForm] = useState(true);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(0);
  const [key, setKey] = useState(Date.now());
  const { t } = useTranslation();

  if (hide) {
    return null;
  }

  const handleMenuItemClicked = (id) => {
    setSelectedID(id);
    setHiddenModal(false);
  };

  const handleContactDetailClicked = (id) => {
    setSelectedID(id);
    setDefaultSelectedIndex(0);
    setHiddenContactForm(false);
  };

  const handleContactDamageClicked = (id) => {
    setSelectedID(id);
    setDefaultSelectedIndex(0);
    setHiddenContactForm(false);
  };

  const handleContactDocumentsClicked = (id) => {
    setSelectedID(id);
    setDefaultSelectedIndex(1);
    setHiddenContactForm(false);
  };

  return (
    <>
      <Stack className="cardContainerStyle" onClick={() => setHiddenContactForm(false)}>
        <Text className="textStyle">{title}</Text>
        <table style={{ width: '100%' }}>
          <tbody>
            {options.map(option => (
              <tr key={option.key}>
                <td>{option.key}</td>
                { typeof(option.value) === 'number' ? (
                  <td style={{ textAlign: 'right', paddingRight: 22 }}>{option.value}</td>
                ) : (
                  <td style={{ textAlign: 'right', paddingRight: 10 }}>{option.value}</td>
                )
                }
              </tr>
            ))}
          </tbody>
        </table>
        {props?.showButton && <PrimaryButton
          className="buttonStyle"
          text={actionLabel}
          onClick={onAction}
        />}
        {!props?.showButton &&
          <ActionSet
            className="actionSet"
            t={t}
            id={props?.id}
            userId={props?.userId}
            key={key}
            handleMenuItemClicked={handleMenuItemClicked}
            handleContactDetailClicked={handleContactDetailClicked}
            handleContactDamageClicked={handleContactDamageClicked}
            handleContactDocumentsClicked={handleContactDocumentsClicked}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />}
        {!isHiddenModal && (
          <ReportDamageAdditionalProducts
            hidden={false}
            dspNumber={props?.data?.insuranceOffer?.companyInformation?.amazonDspNumber}
            companyName={props?.data?.insuranceOffer?.companyInformation?.companyName}
            typeDamage={title}
            userId={props?.userId}
            vehicleId={props.id}
            defaultId={selectedID}
            onListChanged={() => {
              setKey(Date.now());
            }}
            onDismiss={() => {
              setHiddenModal(true);
            }}
          />
        )}
      </Stack>
      {!props?.showButton && selectedID && (
        <ContactView
          defaultSelectedIndex={defaultSelectedIndex}
          contactID={selectedID}
          categoryType={false}
          additionalName={props?.type}
          userID={props?.userId}
          hidden={isHiddenContactForm}
          onDismiss={() => setHiddenContactForm(true)}
        />
      )}
    </>
  );
};
