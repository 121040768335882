import React from 'react';
import { mainTheme } from '@/theme/themes';

export interface ThemedSelectErrorProperties {
  errorMessage: string | JSX.Element;
  marginTop?: string | number;
}

export default function ThemedSelectError(
  props: ThemedSelectErrorProperties
): JSX.Element {
  if (typeof props.errorMessage === 'string') {
    return (
      <p
        className="ms-TextField-errorMessage"
        style={{
          marginTop: props.marginTop || mainTheme.spacing.s2,
          color: mainTheme.semanticColors.errorText,
        }}
      >
        <span>{props.errorMessage}</span>
      </p>
    );
  }

  return (
    <div style={{ marginTop: props.marginTop || mainTheme.spacing.s2 }}>
      {props.errorMessage}
    </div>
  );
}
