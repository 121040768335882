import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';


import { useAuth } from '@/contexts/auth';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';


export default () => {
  const { logout, authorized } = useAuth();
  const location = useLocation();
  
  const urlParams = new URLSearchParams(location?.search);

  const { makeRequest: setLogout } = useFetch({
    endpoint: '/api/users/logout',
    tokenURL: urlParams.get('token'),
    method: FetchMethodsEnum.POST,
  });

  useEffect(() => {
    if(authorized) {
      setLogout();
      logout();
    }
  }, [logout, authorized]);

  if (authorized) {
    return null;
  }

  return (
    <Redirect to="/" />
  );
};
