import React, { useState, useCallback, useEffect } from 'react';
import { useOverflow } from 'use-overflow';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/auth';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import { onFormatDate } from '@/utils/calendar';
import { formatCurrency } from '@/utils/formating';

import {
  Dialog, Pivot, PivotItem,
  Icon, TooltipHost, CommandButton,
  Stack, Spinner
} from '@fluentui/react';

import './index.scss';

const LabelTooltip = ({ label }) => {
  const ref = React.useRef(null);
  const { refXOverflowing } = useOverflow(ref);

  if (!refXOverflowing) {
    return (
      <span ref={ref} className="pdfName">{label}</span>
    );
  }

  return (
    <TooltipHost
      key={label}
      id={label}
      content={label}
      calloutProps={{ gapSpace: 0 }}
      className="tooltip"
    >
      <span ref={ref} className="pdfName">{label}</span>
    </TooltipHost>
  );
};

export function ContactView({
  hidden,
  onDismiss,
  userID,
  contactID,
  defaultSelectedIndex,
  additionalName = null,
  licenseNumber = null,
  categoryType = true
}) {
  const { t } = useTranslation();
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({});

  const { data, loading: loader } = useFetch({
    endpoint: userID ? `/api/amsContracts/users/${userID}/damages/${contactID}` : `/api/amsContracts/damages/${contactID}`,
    method: FetchMethodsEnum.GET,
    onLoadingStart: () => setLoading(true),
    onCompleted: () => {
      setLoading(false);
    }
  });

  const { data: overviewData } = useFetch({
    endpoint: `/api/amsContracts/users/${userID}/insurance/${licenseNumber || additionalName}`, 
    method: FetchMethodsEnum.GET,
    onLoadingStart: () => setLoading(true),
    onCompleted: () => {
      setLoading(false);
    }
  });

  const downloadPDF = (name) => async () => {
    const link = document.createElement('a');

    link.setAttribute('download', `${name.split('/').pop()}`);
    link.setAttribute('target', '_blank');

    const response = await axios.get(`/api/${name}`, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const blob = new Blob([response.data]);
    const objectUrl = window.URL.createObjectURL(blob);

    link.href = objectUrl;

    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const togglePanel = useCallback((id) => () => {
    setOpen({
      ...open,
      [id]: !open[id],
    });
  }, [open]);

  const [inited, setInited] = useState(false);
  useEffect(() => {
    const first = ((overviewData || data)?.amsDamages || [])[0];
    if (first?.damageId && !open[first?.damageId] && !inited) {
      togglePanel(first.damageId);
    }
  }, [overviewData, data?.amsDamages]);

  return (
    <Dialog
      modalProps={{
        isBlocking: true,
        className: 'contactViewContainer',
        layerProps: { eventBubblingEnabled: true },
      }}
      hidden={hidden}
      onDismiss={onDismiss}
      minWidth="550px"
    >
      {loader ? <Spinner /> : (
        <div style={{ height: (overviewData || data) ? 500 : 150 }}>
          {(overviewData || data) ? (
            <div className="rowTitleContactView">
              {(overviewData || data)?.amsContractDetails?.contractNumber || t('contactView.notValue')}
            </div>
          ) : (
            <div className="rowTitleContactView">
              Kein Vertrag vorhanden
            </div>
          )}
          {(overviewData || data) ? (
            <>
              <div className="headerContent">
                <div className="columnContainer">
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.risk')}</div>
                    <div>{(overviewData || data)?.amsContractDetails?.risk}</div>
                  </div>
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.start')}</div>
                    <div>{onFormatDate((overviewData || data)?.amsContractDetails?.startDate)}</div>
                  </div>
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.procedure')}</div>
                    <div>{onFormatDate((overviewData || data)?.amsContractDetails?.endDate)}</div>
                  </div>
                </div>
                <div className="verticalLine" />
                <div className="columnContainer">
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.paymentMethod')}</div>
                    <div>{(overviewData || data)?.amsContractDetails?.paymentMethod}</div>
                  </div>
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.bonus')}</div>
                    <div>{formatCurrency((overviewData || data)?.amsContractDetails?.paymentByMethod)}</div>
                  </div>
                  <div className="itemContainer">
                    <div className="strongItem">{t('contactView.annualPremium')}</div>
                    <div>{formatCurrency((overviewData || data)?.amsContractDetails?.annualPayment)}</div>
                  </div>
                </div>
              </div>
              <Pivot defaultSelectedIndex={defaultSelectedIndex} className="pivot">
                {categoryType && (
                  <PivotItem className="pivotDetailItem" headerText={t('contactView.details')}>
                    <div className="smallTitle">{t('contactView.carInsurance')}</div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.hsn')}</div>
                      {(overviewData || data)?.amsContractDetails?.manufacturerKeyCode}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.tsn')}</div>
                      {(overviewData || data)?.amsContractDetails?.typeKeyCode}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.wkz')}</div>
                      {(overviewData || data)?.amsContractDetails?.riskCode}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.firstRegistration')}</div>
                      {onFormatDate((overviewData || data)?.amsContractDetails?.registrationDate)} 
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.chassisNumber')}</div>
                      {(overviewData || data)?.amsContractDetails?.vin}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.liabilityInsurance')}</div>
                      {(overviewData || data)?.amsContractDetails?.isLiabilitySelected}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.fullInsurance')}</div>
                      {(overviewData || data)?.amsContractDetails?.isComprehensiveCoverSelected}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.partialCover')}</div>
                      {(overviewData || data)?.amsContractDetails?.isGapCoverageSelected}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.deductibleVK')}</div>
                      {formatCurrency((overviewData || data)?.amsContractDetails?.thirdPartyLiability)}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.deductibleTK')}</div>
                      {formatCurrency((overviewData || data)?.amsContractDetails?.comprehensiveCover)}
                    </div>
                    <div className="itemContainer">
                      <div className="strongItem">{t('contactView.deductibleOnlyTK')}</div>
                      {formatCurrency((overviewData || data)?.amsContractDetails?.gapCoverage)}
                    </div>
                  </PivotItem>
                )}
                <PivotItem className="pivotItem" headerText={t('contactView.damage')}>
                  {((overviewData || data)?.amsDamages || []).map((damage) => {
                    return (
                      <div key={damage.damageId}>
                        <Stack className="expand-panel" style={{ marginBottom: 10 }}>
                          <CommandButton
                            checked
                            iconProps={{
                              iconName: open[damage.damageId] ? 'ChevronUpSmall' : 'ChevronDownSmall', styles: {
                                root: {
                                  fontSize: '12px',
                                }
                              }
                            }}
                            text={damage?.damageNumberByInsuranceAgent}
                            onClick={togglePanel(damage.damageId)} 
                          />
                          {!open[damage.damageId] && (
                            <Stack className='damageContent'>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.event')}</div>
                                {(overviewData || data)?.amsDamages.damageNumber}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.dayDamage')}</div>
                                {onFormatDate(damage?.damageDate)}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.levelDamage')}</div>
                                {formatCurrency(damage?.amountOfDamage)}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.claimsStatus')}</div>
                                {damage?.status}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.lossNumberBroker')}</div>
                                {damage?.damageNumberByInsuranceAgent}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.finalDay')}</div>
                                {onFormatDate(damage?.endDate)}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.paymentEUR')}</div>
                                {formatCurrency(damage?.paymentInEuro)}
                              </div>
                              <div className="itemContainer">
                                <div className="strongItem">{t('contactView.assignment')}</div>
                                {damage?.damageClassification}
                              </div>
                            </Stack>
                          )}
                        </Stack>
                      </div>
                    );
                  })
                }
                </PivotItem>
                <PivotItem className="pivotItem" headerText={t('contactView.documents')}>
                  {(overviewData || data)?.amsDocumentUrls?.map((item) => {
                    return (
                      <div className="pdfItem" style={{ padding: '10px' }} key={item}>
                        <div className="pdfItemContent">
                          <Icon iconName="TextDocument" style={{ color: '#00a8e1', fontSize: 16 }} />
                          <LabelTooltip label={item.split('/').pop()} />
                        </div>
                        <div>
                          <Icon iconName="download" className="pdfDownload" style={{ fontSize: 16, fontWeight: 500 }} onClick={downloadPDF(item)} />
                        </div>
                      </div>
                    );
                  })}
                </PivotItem>
              </Pivot>
            </>
          ) : (
            <div>Kein Vertrag vorhanden</div>
          )}
        </div>
      )}
    </Dialog>
  );
}
