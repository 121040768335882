import React, { Dispatch, SetStateAction } from 'react';

import Summary from '@/components/base/Summary';
import { Stack, IStackProps } from '@fluentui/react';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';
import { AdditionalServicesDetails } from '@/utils/services/AdditionalServices/additionalServicesDetails';

import ClientDetailsForm from '@/components/forms/ChangeDetail';
import { InsuranceMasterDataDto } from '../insuranceMasterDataDto';
import { ClientDetails } from './ClientDetails';

interface Types {
  masterData: InsuranceMasterDataDto;
  insurances: VehicleInsuranceDetails[];
  services: AdditionalServicesDetails;
  clientDetails: ClientDetails;
  onChange: (newClientDetails: ClientDetails) => void;
  vatPercentage: number;
}

export default function SubmitInsurances(props: Types) {
  const containerStack: IStackProps = {
    horizontal: true,
  };

  return (
    <Stack {...containerStack} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <ClientDetailsForm
        clientDetails={props.clientDetails}
        onChange={props.onChange}
        renderSummary={() => {
          return (
            <Summary
              services={props.services}
              insurances={props.insurances}
              vatPercentage={props.vatPercentage}
            />
          )
        }}
      />
    </Stack>
  );
}
