import React from 'react';
import { useTranslation } from 'react-i18next';

import { Label, ILabelProps, Stack } from '@fluentui/react';
import { mainTheme } from '@/theme/themes';

export default function OptionalLabel(props: ILabelProps) {
  const { t } = useTranslation();

  return (
    <>
      {props.children && (
        <Stack horizontal>
          <Label>{props.children}</Label>
          <Label
            style={{
              marginLeft: 4,
              color: mainTheme.palette.neutralTertiary,
              fontWeight: 500,
            }}
          >
            {' '}
            - {t('global.optional')} 
          </Label>
        </Stack>
      )}
    </>
  );
}
