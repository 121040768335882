export interface SelectOption<T> {
  label: string;
  value: string;
  data: T;
}

export function buildSelectOption<T>(label: string, data: T): SelectOption<T> {
  return {
    label,
    value: label,
    data,
  };
}
