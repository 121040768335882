import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'office-ui-fabric-react';
import moment from 'moment';

import {
  Dialog, TextField, MessageBarType,
  PrimaryButton, MessageBar
} from '@fluentui/react';
import {
  getError, validateFINOrVIN,
  validateHSN, validateLicNum, validateTSN,
} from '@/utils/validations';
import { dayPickerStrings, onFormatDate, dayPickerStringsGerman } from '@/utils/calendar';
import PrimaryCallToActionButton from '@/components/base/CallToActionButton/Primary';
import { hasErrors } from '@/utils/hasErrors';

import './index.scss';

const defaultValues = {
  licensePlate: '',
  registrationDate:  null,
  vin: '',
  manufacturerKeyCode: '',
  typeKeyCode: '',
} as any;

export const AddVehicleReportDamage = ({
  hidden,
  onDismiss,
  addVehicleInsurances,
  baseData,
  error
}) => {
  const { t, i18n } = useTranslation();

  const { handleSubmit, control, errors, formState, setValue, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { registrationDate, vin } = watch();

  const onSubmit = async (values) => {
    const tVals = {};
    Object.keys(values).forEach(key => {
      tVals[key] = typeof values[key] === 'string' ? values[key]?.toUpperCase() : values[key];
    });
    addVehicleInsurances({ data: baseData.map(obj => ({
      ...obj,
      'vehicles': [{
        ...tVals,
        'registrationDate': moment(values.registrationDate).toDate(),
        'isActive': true,
      }],
    })) });
  };

  const handleVinChange = useCallback(prop => (e: any, text) => {
    if (!(/[QIO]/gm.test((text || '')?.toUpperCase()))) {
      prop.onChange(text?.toUpperCase());
    }
  }, [vin]);

  const renderVinField = useCallback(prop => (
    <TextField
      {...prop}
      className="upper"
      label={t('overviewDetail.vin')}
      disabled={false}
      errorMessage={getError(errors, 'vin', t)}
      onChange={handleVinChange(prop)}
    />
  ), []);

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => {
        onDismiss();
        reset(defaultValues);
      }}
      minWidth="550px"
      maxWidth="900px"
      styles={{
        root: {
          padding: 0
        }
      }}
      modalProps={{
        isBlocking: true,
        className: 'addVehicleReportDamage',
        layerProps: { eventBubblingEnabled: true },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="titleNewVehicleModal">{t('overviewDetail.addVehicleModalTitle')}</div>
        {error && typeof(error) === 'object' && (
          <div style={{ marginTop: 25 }}>
            <MessageBar
              className="emailErrorMessage"
              messageBarType={MessageBarType.error}
              isMultiline={false}
            >
              {t('validation.errorLicencenumber')}
            </MessageBar>
          </div>
        )}
        <>
          <Controller
            name="licensePlate"
            control={control}
            className="newVehicleField licensePlate"
            rules={{
              required: true,
              validate: {
                licNum: validateLicNum,
              }
            }}
            as={
              <TextField
                className="upper"
                label={t('overviewDetail.licenceNumber')}
                errorMessage={getError(errors, 'licensePlate', t)}
              />}
          />
        </>
        <>
          <Controller
            name="manufacturerKeyCode"
            className="newVehicleFiuppereld manufacturerKeyCode"
            control={control}
            rules={{
              required: true,
              validate: {
                hsn: validateHSN,
              }
            }}
            as={(
              <TextField
                className="upper"
                label={t('overviewDetail.hsn')}
                disabled={false}
                errorMessage={getError(errors, 'manufacturerKeyCode', t)}
              />
            )}
          />
        </>
        <>
          <Controller
            name="typeKeyCode"
            className="newVehicleField typeKeyCode"
            control={control}
            rules={{
              required: true,
              validate: {
                tsn: validateTSN,
              }
            }}
            as={(
              <TextField
                className="upper"
                label={t('overviewDetail.tsn')}
                errorMessage={getError(errors, 'typeKeyCode', t)}
                disabled={false}
              />
            )}              
          />
        </>
        <>
          <Controller
            name="vin"
            className="newVehicleField vin"
            control={control}
            rules={{
              required: true,
              validate: {
                finOrVin: validateFINOrVIN, 
              }
            }}
            as={renderVinField}
          />
        </>
        <Controller
          name="registrationDate"
          className="newVehicleField"
          control={control}
          as={
            <DatePicker
              label={t('overviewDetail.dateRegistration')}
              strings={i18n.language === 'en' ? dayPickerStrings : dayPickerStringsGerman}
              formatDate={onFormatDate}
              maxDate={new Date()} 
              onSelectDate={(date) => {
                setValue('registrationDate', date as Date);
              }}
            />
          }
        />
        <div className="newVehicleButtons">
          <PrimaryButton
            className='actionButtonStyle'
            text={t('global.cancel')}
            onClick={() => { onDismiss(); }}
          />
          <PrimaryCallToActionButton
            className='actionButtonStyle'
            style={{ maxWidth: '120px' }}
            disabled={hasErrors(errors) || !formState.isValid || !registrationDate}
            type="submit"
            text={t('global.save')}
          />
        </div>
      </form>
    </Dialog>
  );
};
