import WindowWidthInfo, {
  defaultWindowWidth,
} from '@/utils/services/windowWidthInfo';
import { useLayoutEffect, useState } from 'react';

export function useWindowWidth(): WindowWidthInfo {
  const [windowWidth, setWindowWidth] = useState(defaultWindowWidth);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowWidth(new WindowWidthInfo(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowWidth]);

  return windowWidth;
}
