import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { alternateTheme } from '@/theme/themes';
import ExternalLink from '@/components/base/ExternalLink';

export const footerHeight = 36;

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: alternateTheme.spacing.m }}
      style={{
        flexBasis: footerHeight,
        backgroundColor: alternateTheme.palette.neutralPrimary,
        color: alternateTheme.palette.white,
        padding: '0 10px'
      }}
    >
      <ExternalLink
        theme={alternateTheme}
        text={t('footer.impressum')}
        href="https://www.aon.com/germany/ueber-aon/impressum.jsp"
      />
      <ExternalLink
        theme={alternateTheme}
        text={t('footer.privacyPolicy')}
        href="https://www.aon.com/germany/ueber-aon/datenschutz.jsp"
      />
      <p style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        &copy; 2020 by{' '}
        <ExternalLink
          style={{ marginLeft: 10 }}
          theme={alternateTheme}
          text={t('footer.synnotechAG')}
          href="https://www.synnotech.de/"
        />
      </p>
    </Stack>
  );
}
