import React, { useCallback } from 'react';
import { Checkbox } from '@fluentui/react';

export type Props = {
  checked?: boolean
  onChange?: (value?: boolean) => void,
  label?: string,
  disabled?: boolean
};
  
export const CheckboxControl: React.FC<Props> = ({ checked, onChange, label, disabled }) => {
  const handleChange = useCallback(
    (e, innerChecked?: boolean) => {
      if (onChange) onChange(innerChecked);
    },
    [onChange]
  );
  return <Checkbox
    disabled={disabled}
    label={label}
    checked={checked}
    onChange={handleChange} 
  />;
};
