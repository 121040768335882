/* eslint-disable radix */
import React, { CSSProperties, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { mainTheme } from '@/theme/themes';
import { SubmitStatus } from '@/types/SubmitStatusType';
import { FetchMethodsEnum, useFetch } from '@/hooks/useFetch';
import OptionalTextField from '@/components/base/Optional/OptionalTextField';
import { ConfirmationDialog } from '@/components/base/ConfirmationDialog';
import {
  Stack,
  Label,
  PrimaryButton,
  Spinner,
  TextField,
  Checkbox,
} from '@fluentui/react';
import {
  getRequiredError,
  validateEmail,
  validateNumericOnly,
  getNumericOnlyError,
  getEmailError,
} from '@/utils/validations';

export default function ContactPage() {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(
    SubmitStatus.None
  );

  const { makeRequest: sendContact } = useFetch({
    endpoint: '/api/contact',
    method: FetchMethodsEnum.POST,
  });

  const history = useHistory();
  const [hiddenConfirmation, setHiddenConfirmation] = useState(true);

  useEffect(() => {
    if (submitStatus === SubmitStatus.Submitted) {
      setHiddenConfirmation(false);
    }
  }, [submitStatus]);

  const handleCloseConfirmation = () => {
    history.push('/');
  };

  const { t } = useTranslation();

  const { handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      amazonDspNumber: '',
      contactFirstName: '',
      contactLastName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      notes: '',
      amountOfVehicles: '',
      amountOfEmployees: '',
      withCompanyLiability: false,
      withGroupAccidentInsurance: false,
      withTrafficLegalProtection: false,
      withDriverLegalProtection: false,
    },
  });

  const contactStyle: CSSProperties = {
    background: mainTheme.palette.white,
    paddingTop: mainTheme.spacing.l2,
    paddingBottom: mainTheme.spacing.l2,
    paddingLeft: mainTheme.spacing.m,
    paddingRight: mainTheme.spacing.m,
    flexGrow: 1,
  };

  const makeContactStyle: CSSProperties = {
    fontSize: mainTheme.fonts.xLarge.fontSize,
    fontWeight: 500,
    textAlign: 'center',
  };

  const sectionSeparatorStyle: CSSProperties = {
    marginTop: mainTheme.spacing.l2,
  };

  async function onSubmitRequestRecall(data: any): Promise<void> {
    if (submitStatus !== SubmitStatus.None) {
      return;
    }
    setSubmitStatus(SubmitStatus.IsSubmitting);
    await sendContact({
      data: {
        companyName: data.companyName,
        amazonDspNumber: data.amazonDspNumber,
        contactFirstName: data.contactFirstName,
        contactLastName: data.contactLastName,
        telephone: data.phoneNumber,
        email: data.email,
        comment: data.notes,
        numberOfVehicles: Number.parseInt(data.amountOfVehicles),
        numberOfEmployees: Number.parseInt(data.amountOfEmployees),
        isLegalProtectionSelected: data.withCompanyLiability,
        isBusinessLiabilitySelected: data.withGroupAccidentInsurance,
        isGroupAccidentSelected: data.withTrafficLegalProtection,
        isTrafficLiabillitySelected: data.withDriverLegalProtection
      }
    });

    setSubmitStatus(SubmitStatus.Submitted);

  }

  return (
    <Stack style={contactStyle}>
      <Stack horizontalAlign="center" verticalAlign="center">
        <Label style={makeContactStyle}>{t('contact.titlePage')}</Label>
      </Stack>

      <form
        onSubmit={handleSubmit(onSubmitRequestRecall)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: mainTheme.spacing.m,
        }}
      >
        <Stack style={{ width: '320px' }} tokens={{ childrenGap: 4 }}>
          <Controller
            name="companyName"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.companyName')}
                errorMessage={getRequiredError(
                  errors,
                  'companyName',
                  t('contact.companyNameErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="amazonDspNumber"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.amazonDspNumber')}
                errorMessage={getRequiredError(
                  errors,
                  'amazonDspNumber',
                  t('contact.amazonDspNumberErrorMessage')
                )}
              />
            }
          />

          <Stack horizontal tokens={{ childrenGap: 12 }}>
            <Controller
              name="contactFirstName"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('contact.firstName')}
                  errorMessage={getRequiredError(
                    errors,
                    'contactFirstName',
                    t('contact.firstNameErrorMessage')
                  )}
                />
              }
            />

            <Controller
              name="contactLastName"
              control={control}
              rules={{ required: true }}
              as={
                <TextField
                  label={t('contact.lastName')}
                  errorMessage={getRequiredError(
                    errors,
                    'contactLastName',
                    t('contact.lastNameErrorMessage')
                  )}
                />
              }
            />
          </Stack>

          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                emailFormat: validateEmail,
              },
            }}
            as={
              <TextField
                label={t('contact.email')}
                errorMessage={getEmailError(errors, 'email', t)}
              />
            }
          />

          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            as={
              <TextField
                label={t('contact.phoneNumber')}
                errorMessage={getRequiredError(
                  errors,
                  'phoneNumber',
                  t('contact.phoneNumberErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="notes"
            control={control}
            as={
              <div style={sectionSeparatorStyle}>
                <OptionalTextField label={t('contact.notes')} multiline />
              </div>
            }
          />

          <Controller
            name="amountOfVehicles"
            control={control}
            rules={{
              validate: {
                numericOnly: validateNumericOnly,
              },
            }}
            as={
              <OptionalTextField
                label={t('contact.amountOfVehicles')}
                errorMessage={getNumericOnlyError(
                  errors,
                  'amountOfVehicles',
                  t('contact.amountOfVehiclesErrorMessage')
                )}
              />
            }
          />

          <Controller
            name="amountOfEmployees"
            control={control}
            rules={{
              validate: {
                numericOnly: validateNumericOnly,
              },
            }}
            as={
              <OptionalTextField
                label={t('contact.amountOfEmployees')}
                errorMessage={getNumericOnlyError(
                  errors,
                  'amountOfEmployees',
                  t('contact.amountOfEmployeesErrorMessage')
                )}
              />
            }
          />

          <Label style={sectionSeparatorStyle}>{t('contact.subTitlePage')}</Label>

          <Stack tokens={{ childrenGap: 12 }}>
            <Controller
              name="withCompanyLiability"
              control={control}
              onChange={([_, checked]) => {
                return checked;
              }}
              as={<Checkbox label={t('contact.withCompanyLiability')} />}
            />

            <Controller
              name="withGroupAccidentInsurance"
              control={control}
              onChange={([_, checked]) => {
                return checked;
              }}
              as={<Checkbox label={t('contact.withGroupAccidentInsurance')} />}
            />

            <Controller
              name="withTrafficLegalProtection"
              control={control}
              onChange={([_, checked]) => {
                return checked;
              }}
              as={<Checkbox label={t('contact.withTrafficLegalProtection')} />}
            />

            <Controller
              name="withDriverLegalProtection"
              control={control}
              onChange={([_, checked]) => {
                return checked;
              }}
              as={<Checkbox label={t('contact.withDriverLegalProtection')} />}
            />
          </Stack>

          <Stack style={{ marginTop: mainTheme.spacing.m }}>
            {submitStatus !== SubmitStatus.Submitted && (
              <>
                <PrimaryButton
                  type="submit"
                  text={t('contact.submitButtonLabel')}
                  disabled={submitStatus === SubmitStatus.IsSubmitting}
                />

                {submitStatus === SubmitStatus.IsSubmitting && (
                  <Spinner
                    style={{ marginTop: mainTheme.spacing.m }}
                    label={t('contact.spinerLabel')}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </form>
      {!hiddenConfirmation && (
        <ConfirmationDialog
          hidden={hiddenConfirmation}
          onDismiss={handleCloseConfirmation}
          renderContent={t('contact.confirmationText')}
          textButton={t('contact.closeButton')}
        />
      )}
    </Stack>
  );
}
