/* eslint-disable max-len */
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, IStackProps, Text } from '@fluentui/react';

import { formatCurrency } from '@/utils/formating';
import { mainTheme } from '@/theme/themes';
import { useWindowWidthContext, useMediaQuery } from '@/hooks';
import { VehicleInsuranceDetails } from '@/utils/services/vehicleInsurancedetails';
import { AdditionalServicesDetails } from '@/utils/services/AdditionalServices/additionalServicesDetails';
import { useCalculation } from '@/contexts/calculation';

interface Types {
  services: AdditionalServicesDetails;
  insurances: VehicleInsuranceDetails[];
  vatPercentage: number;
}

export default function Summary(props: Types) {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const { mountly } = useCalculation();

  const amountScaleFactor = mountly ? 12 : 1;

  const windowWidth = useWindowWidthContext();

  const insuranceDetailsStack: IStackProps = {
    horizontal: true,
    horizontalAlign: 'baseline',
  };

  const infoStyle: CSSProperties = {
    width: '40%'
  };

  const costSummaryStyle: CSSProperties = {};

  const costLabelStyle: CSSProperties = {
    paddingRight: mainTheme.spacing.l1,
  };

  const priceStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end'
  };

  if (windowWidth.windowWidth < 700) {
    insuranceDetailsStack.horizontal = false;
    insuranceDetailsStack.horizontalAlign = 'center';

    infoStyle.marginTop = mainTheme.spacing.m;
    infoStyle.marginLeft = 0;
    infoStyle.alignItems = 'center';

    costSummaryStyle.marginTop = mainTheme.spacing.m;
  }

  const insuranceCosts = props.insurances.map(insurance => insurance.insuranceTypes.map((type) => ({
    id: type.id,
    cost: +(+(insurance.insuranceCosts[type.id].toFixed(2))).toFixed(2),
  })));

  let totalInsuranceCosts: any = {};

  for (let i = 0; i < insuranceCosts.length; i++) {
    for (let j = 0; j < insuranceCosts[i].length; j++) {
      const insuranceCost: any = insuranceCosts[i][j];
      if (totalInsuranceCosts[insuranceCost.id]) {
        totalInsuranceCosts[insuranceCost.id] += Number(+insuranceCost.cost.toFixed(2));
      } else {
        totalInsuranceCosts[insuranceCost.id] = Number(+insuranceCost.cost.toFixed(2));
      }
    }
  }

  totalInsuranceCosts = Object.keys(totalInsuranceCosts).map(key => ({
    id: key,
    cost: totalInsuranceCosts[key],
  }));



  

  const insurancesTotal: number = props.insurances.reduce(
    (acc: any, cr: any) => acc + Number.parseFloat(cr.grossAmount),
    0,
  );

  const netTotal: number = props.insurances.reduce(
    (acc: any, cr: any) => acc + Number.parseFloat(cr.netAmount),
    0,
  );

  return (
    <Stack style={{ margin: 'auto 0', paddingTop: '24px', display: 'flex', flex: 1, minWidth: isMobile ? '250px' : '350px' }}>
      <table style={{ borderCollapse: 'collapse', fontSize: '16px', margin: 'auto 0' }}>
        <tbody>
          <tr>
            <td style={{ paddingBottom: '16px' }}>
              <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                {t(!mountly ?
                  'wizardStepThree.summaryAnnual' : 'wizardStepThree.summaryMonthl'
                )}
              </Text>
            </td>
          </tr>
          {totalInsuranceCosts.map((type) => {
            return (
              <tr key={type.id}>
                <td style={costLabelStyle}>{t(`insurance.${type.id}`)}</td>
                <td style={priceStyle}>
                  {formatCurrency(
                    type.cost
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td style={{ borderTop: '1px solid black' }} />
            <td style={{ borderTop: '1px solid black' }} />
          </tr>
          <tr>
            <td style={costLabelStyle}>{t('wizardStepThree.totalPremium')}</td>
            <td style={priceStyle}>
              {formatCurrency(
                (netTotal)
              )}
            </td>
          </tr>
          <tr>
            <td style={costLabelStyle}>
              {t('wizardStepThree.taxes')}
            </td>
            <td style={priceStyle}>
              {formatCurrency((netTotal * props.vatPercentage) )}
            </td>
          </tr>
          <tr>
            <td style={{ borderTop: '1px solid black' }} />
            <td style={{ borderTop: '1px solid black' }} />
          </tr>
          <tr>
            <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>{t('wizardStepThree.totalMotorVehicle')}</td>
            <td style={{ ...priceStyle, fontWeight: 'bold' }}>
              {formatCurrency(((props.insurances.reduce(
                (acc: any, cr: any) => acc + Number.parseFloat(cr.grossAmount),
                0,
              ) as unknown as number))
              )}
            </td>
          </tr>
          <tr>
            <td style={{ borderTop: '1px solid black' }} />
            <td style={{ borderTop: '1px solid black' }} />
          </tr>

          {props.services.businessLiabilityInsurance.isUsed && (
          <tr>
            <td style={costLabelStyle}>
              {t('wizardStepThree.publicLiabilityInsurance')}
            </td>
            <td style={priceStyle}>
              {formatCurrency(
                props.services.businessLiabilityInsurance.price
              )}
            </td>
          </tr>
          )}
          {/* {props.services.driverLegalProtection.isUsed && (
          <tr>
            <td style={costLabelStyle}>{t('wizardStepThree.driverLegalProtection')}</td>
            <td style={priceStyle}>
              {formatCurrency(props.services.driverLegalProtection.price)}
            </td>
          </tr>
          )} */}
          {props.services.trafficLegalProtection.isUsed && (
          <tr>
            <td style={costLabelStyle}>{t('wizardStepThree.trafficLegalProtection')}</td>
            <td style={priceStyle}>
              {formatCurrency(props.services.trafficLegalProtection.price)}
            </td>
          </tr>
          )}
          {props.services.trafficLiabilityInsurance.isUsed && (
          <tr>
            <td style={costLabelStyle}>{t('wizardStepThree.trafficLiabilityInsurance')}</td>
            <td style={priceStyle}>
              {formatCurrency(props.services.trafficLiabilityInsurance.price)}
            </td>
          </tr>
          )}
          {props.services.groupAccidentInsurance.isUsed && (
          <tr>
            <td style={costLabelStyle}>{t('wizardStepThree.groupAccidentInsurance')}</td>
            <td style={priceStyle}>
              {formatCurrency(props.services.groupAccidentInsurance.price)}
            </td>
          </tr>
          )}
          <tr>
            <td style={{ borderTop: '1px solid black' }} />
            <td style={{ borderTop: '1px solid black' }} />
          </tr>
          {!props.services.costsOfServices ? null : (
            <>
              <tr>
                <td style={{ ...costLabelStyle }}>{t('wizardStepThree.totalPremiumInsurance')}</td>
                <td style={{ ...priceStyle }}>
                  {formatCurrency(props.services.costsOfServices)}
                </td>
              </tr>
              <tr>
                <td style={{ ...costLabelStyle }}>{t('wizardStepThree.taxes')}</td>
                <td style={{ ...priceStyle }}>
                  {formatCurrency(props.services.costsOfServices / 100 * props.services.vatPercentage)}
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: '1px solid black' }} />
                <td style={{ borderTop: '1px solid black' }} />
              </tr>
              <tr>
                <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>{t('wizardStepThree.additionalTotal')}</td>
                <td style={{ ...priceStyle, fontWeight: 'bold' }}>
                  {formatCurrency((props.services.costsOfServices + (props.services.costsOfServices / 100 * props.services.vatPercentage)))}
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: '1px solid black' }} />
                <td style={{ borderTop: '1px solid black' }} />
              </tr>
            </>
          )}
          <tr>
            <td style={{ ...costLabelStyle, fontWeight: 'bold' }}>
              {t('wizardStepThree.total')}
            </td>
            <td style={{ ...priceStyle, fontWeight: 'bold' }}>
              {formatCurrency(((props.services.costsOfServices + (props.services.costsOfServices / 100 * props.services.vatPercentage )) + (insurancesTotal )) )}
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: 15, fontSize: 14 }}>
              {t('additionalService.taxesInformationText')}
            </td>
          </tr>
        </tbody>
      </table>
    </Stack>
  );
}
