import React from 'react';

import { Stack } from '@fluentui/react';
import { LoginForm } from '@/components/forms/LogIn';

export default () => {
  return (
    <Stack>
      <LoginForm hidden={false} />
    </Stack>
  );
};

