import React from 'react';
import { mainTheme } from '@/theme/themes';
import CallToActionButtonProps from './Properties';
import setCallToActionButtonTheme from './Theme';
import CallToActionButton from '.';

export default function SecondaryCallToActionButton(
  props: CallToActionButtonProps
): JSX.Element {
  const formattedProps = setCallToActionButtonTheme(props, mainTheme);
  return <CallToActionButton {...formattedProps} />;
}
