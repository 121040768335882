import React from 'react';
import { alternateTheme } from '@/theme/themes';
import CallToActionButtonProperties from './Properties';
import setCallToActionButtonTheme from './Theme';
import CallToActionButton from './index';

export default function PrimaryCallToActionButton(
  props: CallToActionButtonProperties
): JSX.Element {
  const formattedProps = setCallToActionButtonTheme(props, alternateTheme);
  return <CallToActionButton {...formattedProps} />;
}
