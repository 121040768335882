import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  getConfirmPasswordError,
  getPasswordError
} from '@/utils/validations';
import { useFetch, FetchMethodsEnum } from '@/hooks/useFetch';
import PasswordStrengthBar from '@/components/base/PasswordStrengthBar';
import { ConfirmationDialog } from '@/components/base/ConfirmationDialog';
import { useAuth } from '@/contexts/auth';

import { Stack, TextField, PrimaryButton, DialogType, Dialog } from '@fluentui/react';
import useDebounce from '@/components/pages/OverviewDamageAdvisor/useDebaunce';
import { hasErrors } from '@/utils/hasErrors';

type Props = {
  hidden: boolean;
};

export const ChangePasswordForm = ({ hidden }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loginData } = useAuth();
  const [hiddenModal, setHiddenModal] = useState(false);
  const [isStrong, setIsStrong] = useState(false);

  const { control, errors, handleSubmit, getValues, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
  });
  const password = watch('password');
  const debouncedPassword = useDebounce(password, 200);

  const { makeRequest: changePassword } = useFetch({
    endpoint: '/api/users/changePassword',
    method: FetchMethodsEnum.POST,
    onCompleted: () => {
      setHiddenModal(true);
    }
  });

  const onSubmit = async (values) => {
    await changePassword({ data: {
      ...values,
      id: loginData?.id,
      email: loginData?.email
    } });
  };

  const validateSameAsPassword = (value: string | undefined) => {
    const pass = getValues('password');
    return !value || value === pass;
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: t('login.changePassword'),
  };

  const handleClose = () => {
    history.push('/');
  };

  return (
    <>
      {!hiddenModal && (
        <Dialog
          hidden={hidden}
          minWidth="400px"
          dialogContentProps={dialogContentProps}
          modalProps={{ isBlocking: true }}
          onDismiss={handleClose}
        >
          <form onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit(onSubmit)
          }}>
            <Stack tokens={{ childrenGap: 4 }}>
              <Controller
                name="oldPassword"
                control={control}
                rules={{ required: true }}
                as={
                  <TextField
                    canRevealPassword={true}
                    type="password"
                    label={t('login.oldPassword')}
                  />
                }
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                }}
                as={(
                  <TextField
                    canRevealPassword={true}
                    type="password"
                    label={t('global.password')}
                    errorMessage={getPasswordError(
                      errors,
                      'password',
                      t
                    )}
                  />
                )}
              />
              <PasswordStrengthBar onStrong={setIsStrong} password={debouncedPassword} />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    sameAsPassword: validateSameAsPassword,
                  }
                }}
                as={
                  <TextField
                    canRevealPassword={true}
                    type="password"
                    errorMessage={getConfirmPasswordError(
                      errors, 'confirmPassword', t )}
                    label={t('login.confirmPassword')}
                  />
                }
              />
              <PrimaryButton
                disabled={!isStrong || hasErrors(errors) || !formState.dirty || !getValues().confirmPassword}
                className='marginContainer'
                onClick={handleSubmit(onSubmit)}
                text={t('login.changePasswordButton')}
                type="submit"
              />
            </Stack>
          </form>
        </Dialog>
      )}
      {hiddenModal && (
        <ConfirmationDialog
          hidden={!hiddenModal}
          onDismiss={() => {history.push('/');}}
          renderContent={t('login.changePasswordText')}
          textButton={t('global.ok')}
        />
      )}
    </>
  );
};
