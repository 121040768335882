import { InsuranceMasterDataDto } from '@/components/pages/Wizard/insuranceMasterDataDto';
import { getPriceByAmount } from '@/components/pages/Wizard/priceByAmount';

export class AdditionalServicesDetails {
  constructor(
    readonly id: string,
    readonly amountOfEmployees: number = 1,
    readonly participationAmount: {
      traffic: number;
      driver: number;
    } = { traffic: 1, driver: 1 },
    readonly amountOfVehicles: number = 1,
    useBusinessLiabilityInsurance: boolean,
    useTrafficLegalProtection: boolean,
    useDriverLegalProtection: boolean,
    useGroupAccidentInsurance: boolean,
    useTrafficLiabilityInsurance: boolean,
    masterData: InsuranceMasterDataDto | any,
    mountly: boolean,
  ) {
    const businessLiabilityPrice: number = useBusinessLiabilityInsurance
      ? getPriceByAmount(
        masterData.businessLiabilityInsurancePrices,
        amountOfEmployees || 1,
        mountly,
      ) * amountOfEmployees || 1
      : 0;
    
    const trafficLegalProtectionPrice: number = useTrafficLegalProtection
      ? getPriceByAmount(
        masterData.trafficLegalProtectionPrices,
        participationAmount.traffic,
        mountly,
      ) * amountOfVehicles
      : 0;

    const driverLegalProtectionPrice: number = useDriverLegalProtection
      ? getPriceByAmount(
        masterData.driverLegalProtectionPrices,
        participationAmount.driver,
        mountly,
      ) * amountOfVehicles
      : 0;

    const groupAccidentInsurancePrice: number = useGroupAccidentInsurance
      ? getPriceByAmount(
        masterData.groupAccidentInsurancePrices,
        amountOfEmployees || 1,
        mountly,
      ) * amountOfEmployees || 1
      : 0;
    const trafficLiabilityInsurancePrice: number = useTrafficLiabilityInsurance
      ? getPriceByAmount(
        masterData.trafficLiabilityInsurancePrices,
        1,
        mountly,
      ) * amountOfVehicles
      : 0;

    this.businessLiabilityInsurance = {
      isUsed: useBusinessLiabilityInsurance,
      price: businessLiabilityPrice,
    };

    this.trafficLegalProtection = {
      isUsed: useTrafficLegalProtection,
      price: trafficLegalProtectionPrice,
    };

    this.driverLegalProtection = {
      isUsed: useDriverLegalProtection,
      price: driverLegalProtectionPrice,
    };

    this.groupAccidentInsurance = {
      isUsed: useGroupAccidentInsurance,
      price: groupAccidentInsurancePrice,
    };

    this.trafficLiabilityInsurance = {
      isUsed: useTrafficLiabilityInsurance,
      price: trafficLiabilityInsurancePrice,
    };

    this.costsOfServicesWithoutTaxes =
      businessLiabilityPrice +
      trafficLegalProtectionPrice +
      trafficLiabilityInsurancePrice +
      driverLegalProtectionPrice +
      groupAccidentInsurancePrice;

    this.vatPercentage =  masterData.vatPercentage * 100;
    this.taxes = this.costsOfServicesWithoutTaxes * (this.vatPercentage / 100);

    this.costsOfServices = this.costsOfServicesWithoutTaxes;
  }

  readonly businessLiabilityInsurance: ServiceData;

  readonly trafficLegalProtection: ServiceData;

  readonly driverLegalProtection: ServiceData;

  readonly groupAccidentInsurance: ServiceData;

  readonly trafficLiabilityInsurance: ServiceData;

  readonly costsOfServicesWithoutTaxes: number;

  readonly taxes: number;

  readonly vatPercentage: number;

  readonly costsOfServices: number;
}

export interface ServiceData {
  isUsed: boolean;
  price: number;
}
